import { createAsyncThunk } from "@reduxjs/toolkit";
import { GRID_SETTINGS_URL } from "utils/urls";
import { getRequest, postRequest } from "utils/axiosClient";
import { ERROR_MESSAGES, STATUS_CODE } from "appConstants";
import AppError from "utils/AppError";
import {
  getAllGridSettingsPayload,
  SaveGridSettingsPayload,
} from "types/gridSettings";

const typePrefix = {
  SAVE: "saveGridSetting",
  GET_ALL: "getGridSettings",
  GET_BY_ID: "getGridSettingById",
  EDIT: "editGridSetting",
};

export const getAllGridSettings = createAsyncThunk(
  typePrefix.GET_ALL,
  async (payload: getAllGridSettingsPayload) => {
    try {
      const response = await getRequest<any>(GRID_SETTINGS_URL, {
        params: payload,
      });

      return response.data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAllGridSetings,
        error.response.data?.message
      );
    }
  }
);

export const saveGridSetting = createAsyncThunk(
  typePrefix.SAVE,
  async (payload: SaveGridSettingsPayload) => {
    try {
      const response = await postRequest<any>(GRID_SETTINGS_URL, payload);

      if (response.status === STATUS_CODE.CREATED_SUCCESS) {
        return response?.data;
      }
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.saveGridSetings,
        error.response.data?.message
      );
    }
  }
);
