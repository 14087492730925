import { lazy } from "react";
import FORM_FIELDS from "./formFields";
import { InventoryStatusType } from "types";

export enum SUMMARY_CARD_MODE {
  CREATE = "create",
  DUPLICATE = "duplicate",
}

export enum ASSET_IMAGE_SELECTION_MODE {
  CREATE = "create",
  SUMMARY = "summary",
}

export const ASSET_STOCK_NUMBER_LENGTH = 6;

export enum ASSET_UNIT_TYPE {
  ATV = "ATV",
  AUTO = "AUTO",
  BOAT = "BOAT",
  CT_EXP = "CT/EXP",
  FW = "FW",
  M_CYCLE = "M CYCLE",
  MH = "MH",
  TH = "TH",
  TRK_CAMP = "TRK CAMP",
  TT = "TT",
  UT = "UT",
}

export enum ASSET_TYPE {
  MOTORIZED = "Motorized",
  TOWABLE = "Towable",
  FIXED_ASSET = "Fixed Asset",
}

export enum DispatchStatus {
  New = 1,
  Cancel = 2,
  Completed = 3,
}

export enum PurchasingViewMode {
  PURCHASE_ORDER = "Orders",
  TEMPLATES = "Templates",
}

export const ASSET_DEFAULT_LOCATION = "CORP";

export enum CREATE_ASSET_TAB_IDS {
  UNIT_INFO_PRICING = "unitInfo&Pricing",
  OTHER_PRICING = "otherPricing",
  METADATA_DESCRIPTION = "metadata&Description",
  LOCATION = "location",
  ADD_IMAGES = "addImages",
  MANAGE_IMAGES = "manageImages",
}

export enum TITLE_STATUS_NAME {
  NEW = "New",
  USED = "Used",
}

export enum UNIT_STATUS_NAME {
  BUY_BACK = "BUY BACK",
  DAMAGED = "DAMAGED",
  FACTORY_REVIEW = "FACTORY REVIEW",
  FLEET_PENDING = "FLEET PENDING",
  IN_SERVICE = "IN SERVICE",
  ORDERED = "ORDERED",
  PRE_PDI = "PRE PDI",
  PURCHASED = "PURCHASED",
  QC_NEEDED = "QC NEEDED",
  READY_FOR_SALE = "READY FOR SALE",
  RESTOCK = "RESTOCK",
  RV_SHOW_BACKUP = "RV SHOW BACKUP",
  RV_SHOW_UNIT = "RV SHOW UNIT",
  RVASAP = "RVASAP",
  SALE_PENDING = "SALE PENDING",
  SHIPPED = "SHIPPED",
  SHOWROOM = "SHOWROOM",
  SOLD = "SOLD",
  STORETOSTORE = "STORETOSTORE",
  TEMPLATE = "TEMPLATE",
  TRADE_IN = "TRADE IN",
  TRANSFER = "TRANSFER",
  WHOLESALE_USED = "WHOLESALE - USED",
  EAST_STOCK = "EAST STOCK",
}

export const inventoryStatus: InventoryStatusType = {
  1: {
    label: "Quote",
    icon: lazy(() => import("@mui/icons-material/Flag")),
    color: "grey[50]",
    bgOpacity: 0.21,
  },
  2: {
    label: "Pending",
    icon: lazy(() => import("@mui/icons-material/Flag")),
    color: "appModules[400]",
  },
  3: {
    label: "Rescheduled",
    icon: lazy(() => import("@mui/icons-material/Update")),
    color: "supportingColor[50]",
  },
  4: {
    label: "Cancelled",
    icon: lazy(() => import("@mui/icons-material/Cancel")),
    color: "error[400]",
  },
  5: {
    label: "Delivery Scheduled",
    icon: lazy(() => import("@mui/icons-material/Flag")),
    color: "success[400]",
    bgColor: "success['main']",
  },
  6: {
    label: "Approved/Pending",
    icon: lazy(() => import("@mui/icons-material/Flag")),
    color: "supportingColor[100]",
    bgOpacity: 0.21,
  },
  7: {
    label: "Complete",
    icon: lazy(() => import("@mui/icons-material/CheckCircle")),
    color: "success[400]",
    bgColor: "success['main']",
  },
  8: {
    label: "Purchased",
    icon: lazy(() => import("@mui/icons-material/Flag")),
    color: "supportingColor[50]",
  },
  9: {
    label: "Ordered",
    icon: lazy(() => import("@mui/icons-material/Flag")),
    color: "supportingColor[200]",
  },
  10: {
    label: "Shipped",
    icon: lazy(() => import("@mui/icons-material/LocalShipping")),
    color: "supportingColor[300]",
  },
  11: {
    label: "Ready",
    icon: lazy(() => import("@mui/icons-material/CheckCircle")),
    color: "supportingColor[600]",
    bgColor: "supportingColor[500]",
  },
  12: {
    label: "Internet",
    icon: lazy(() => import("@mui/icons-material/WebRounded")),
    color: "supportingColor[700]",
  },
  13: {
    label: "Sale Pending",
    icon: lazy(() => import("@mui/icons-material/MonetizationOn")),
    color: "supportingColor[100]",
    bgOpacity: 0.21,
  },
  14: {
    label: "Sold",
    icon: lazy(() => import("@mui/icons-material/MonetizationOn")),
    color: "success[400]",
    bgColor: "success['main']",
  },
  15: {
    label: "Wholesale",
    icon: lazy(() => import("@mui/icons-material/MonetizationOn")),
    color: "success[200]",
  },
  16: {
    label: "In Service",
    icon: lazy(() => import("@mui/icons-material/BuildCircle")),
    color: "supportingColor[50]",
  },
  17: {
    label: "QC Required",
    icon: lazy(() => import("@mui/icons-material/Pageview")),
    color: "supportingColor[800]",
  },
  18: {
    label: "Showroom",
    icon: lazy(() => import("@mui/icons-material/LocationOn")),
    color: "supportingColor[900]",
  },
  19: {
    label: "Restock",
    icon: lazy(() => import("@mui/icons-material/Refresh")),
    color: "supportingColor[A100]",
  },
  20: {
    label: "Transfer",
    icon: lazy(() => import("@mui/icons-material/SwapHorizontalCircle")),
    color: "supportingColor[A200]",
  },
  21: {
    label: "Damage",
    icon: lazy(() => import("@mui/icons-material/BrokenImage")),
    color: "supportingColor[A400]",
  },
  22: {
    label: "Pre-PDI",
    icon: lazy(() => import("@mui/icons-material/FactCheck")),
    color: "supportingColor[A700]",
  },
  23: {
    label: "Factory Review",
    icon: lazy(() => import("@mui/icons-material/RvHookup")),
    color: "supportingColor[800]",
  },
  24: {
    label: "Location",
    icon: lazy(() => import("@mui/icons-material/LocationOn")),
    color: "supportingColorAlt[100]",
    bgOpacity: 0.2,
  },
  25: {
    label: "Trade In",
    icon: lazy(() => import("@mui/icons-material/LocalShipping")),
    color: "supportingColor[300]",
  },
  26: {
    label: "Show Unit",
    icon: lazy(() => import("@mui/icons-material/Star")),
    color: "supportingColor[600]",
  },
  27: {
    label: "Fleet Pending",
    icon: lazy(() => import("@mui/icons-material/Pending")),
    color: "supportingColor[700]",
  },
  28: {
    label: "Template",
    icon: lazy(() => import("@mui/icons-material/ModelTraining")),
    color: "supportingColorAlt[200]",
  },
  29: {
    label: "Pre-Sold",
    icon: lazy(() => import("@mui/icons-material/CarRental")),
    color: "supportingColorAlt[300]",
  },
  30: {
    label: "Store-to-Store",
    icon: lazy(() => import("@mui/icons-material/MultipleStop")),
    color: "supportingColor[400]",
    bgOpacity: 0.2,
  },
  31: {
    label: "Alert Issued",
    icon: lazy(() => import("@mui/icons-material/NotificationImportant")),
    color: "error[400]",
    bgColor: "grey[A400]",
  },

  32: {
    label: "Open",
    icon: lazy(() => import("@mui/icons-material/CheckCircle")),
    color: "success[400]",
    bgColor: "success['main']",
  },
  33: {
    label: "Cancelled",
    icon: lazy(() => import("@mui/icons-material/Cancel")),
    color: "grey[50]",
  },
  34: {
    label: "RVASAP",
    icon: lazy(() => import("@mui/icons-material/BuildCircle")),
    color: "supportingColor[600]",
  },
  35: {
    label: "Factory Installed",
    icon: lazy(() => import("@mui/icons-material/Settings")),
    color: "supportingColor[300]",
  },
  36: {
    label: "Dealer Installed",
    icon: lazy(() => import("@mui/icons-material/Stars")),
    color: "supportingColor[700]",
  },
};
