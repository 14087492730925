import React, { MutableRefObject } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import {
  boxShadow,
  cancelBtnStyle,
  GRID_TYPE,
  gutterSpace,
  pxToRem,
} from "appConstants";
import { Button, Loader, RadioGroupWrapper } from "components";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useSavedGridSetttingsDialog from "./useSavedGridSetttingsDialog";

type SavedGridSettingsDialogPropType = {
  gridApiRef: MutableRefObject<GridApiPremium | null>;
  gridType: GRID_TYPE;
  onClose: () => void;
  onSelection: (e: any) => void;
};

const SavedGridSettingsDialog: React.FC<SavedGridSettingsDialogPropType> = ({
  gridType,
  gridApiRef,
  onClose,
  onSelection,
}) => {
  const {
    isLoading,
    selectedSetting,
    savedGridSettings,
    handleChange,
    handleSelect,
  } = useSavedGridSetttingsDialog(gridType, onSelection);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box>
      <DialogTitle>
        <Box sx={{ flex: 1 }}>
          <ContentCopyIcon sx={{ mr: pxToRem(14.8), mb: pxToRem(-3) }} />
          Choose Grid Configuration
          <Typography variant="body1" lineHeight={pxToRem(24)} fontWeight={500}>
            You are selecting another data grid configuration
          </Typography>
          <Typography variant="body2" mt={pxToRem(12)} pb={pxToRem(9)}>
            Please Choose from Saved Grid Configurations below.
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ maxHeight: pxToRem(202.5) }}>
        <RadioGroupWrapper
          name="gridSetting"
          options={savedGridSettings}
          value={selectedSetting}
          onChange={handleChange}
          formControlLabelProps={{ labelPlacement: "start" }}
        />
      </DialogContent>

      <DialogActions sx={{ pt: "2.31rem !important" }}>
        <Grid container spacing={gutterSpace}>
          <Grid item xs={6}>
            <Button onClick={onClose} combinedSx={cancelBtnStyle}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button disabled={!selectedSetting} onClick={handleSelect}>
              Select
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Box>
  );
};

export default SavedGridSettingsDialog;
