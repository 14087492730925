import { createSlice } from "@reduxjs/toolkit";

import {
  getUserInfo,
  login,
  loginAndGetRefreshToken,
  loginWithGoogle,
  loginWithMicrosoft,
} from "./auth.action";
import { AUTH } from "./auth.model";
import {
  authFulfilled,
  authRejected,
  getUserInfoFulfilled,
  getUserInfoRejected,
  refreshAuthFulfilled,
} from "./auth.reducers";

const initialState: AUTH = {
  isLoading: false,
  error: "",
  token: "",
  rememberMe: false,
  refreshToken: "",
  userInfo: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    setRememberMe: (state, action) => {
      state.rememberMe = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => (
    builder.addCase(login.pending, (state, action) => {
      state.isLoading = true;
    }),
    builder.addCase(login.fulfilled, (state, action) => {
      authFulfilled(state, action);
    }),
    builder.addCase(login.rejected, (state, action) => {
      authRejected(state, action);
    }),
    builder.addCase(loginWithGoogle.pending, (state, action) => {
      state.isLoading = true;
      state.error = "";
    }),
    builder.addCase(loginWithGoogle.fulfilled, (state, action) => {
      authFulfilled(state, action);
    }),
    builder.addCase(loginWithGoogle.rejected, (state, action) => {
      authRejected(state, action);
    }),
    builder.addCase(loginWithMicrosoft.pending, (state, action) => {
      state.isLoading = true;
      state.error = "";
    }),
    builder.addCase(loginWithMicrosoft.fulfilled, (state, action) => {
      authFulfilled(state, action);
    }),
    builder.addCase(loginWithMicrosoft.rejected, (state, action) => {
      authRejected(state, action);
    }),
    builder.addCase(loginAndGetRefreshToken.pending, (state, action) => {
      state.isLoading = true;
      state.error = "";
    }),
    builder.addCase(loginAndGetRefreshToken.fulfilled, (state, action) => {
      refreshAuthFulfilled(state, action);
    }),
    builder.addCase(loginAndGetRefreshToken.rejected, (state, action) => {
      authRejected(state, action);
    }),
    builder.addCase(getUserInfo.pending, (state, action) => {
      state.isLoading = true;
      state.error = "";
    }),
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      getUserInfoFulfilled(state, action);
    }),
    builder.addCase(getUserInfo.rejected, (state, action) => {
      getUserInfoRejected(state, action);
    })
  ),
});

export const { setRememberMe, reset, setError } = authSlice.actions;
