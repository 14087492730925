export enum PERMISSION {
  ACCESS_READ = "AccessRead",
  USER_CREATE = "UserCreate",
  USER_READ = "UserRead",
  USER_UPDATE = "UserUpdate",
  USER_DELETE = "UserDelete",
  USER_SELF_READ = "UserSelfRead",
  USER_SELF_UPDATE = "UserSelfUpdate",
  ROLES_CREATE = "RolesCreate",
  ROLES_READ = "RolesRead",
  ROLES_UPDATE = "RolesUpdate",
  ROLES_DELETE = "RolesDelete",
  ROLES_ASSIGN = "RolesAssign",
  ROLES_REMOVE_ASSIGNMENT = "RolesRemoveAssignment",
  ROLES_READ_PERMISSIONS = "RolesReadPermissions",
  PERMISSIONS_ASSIGN_ROLES = "PermissionsAssignRoles",
  PERMISSIONS_REMOVE_ROLE_ASSIGNMENT = "PermissionsRemoveRoleAssignment",
  PERMISSIONS_ASSIGN_USER = "PermissionsAssignUser",
  PERMISSIONS_REMOVE_USER_ASSIGNMENT = "PermissionsRemoveUserAssignment",
  REPORTING_READ = "ReportingRead",
  REPORTING_ADMIN_READ = "ReportingAdminRead",
  REPORTING_MANAGEMENT_READ = "ReportingManagementRead",
  REPORTING_SELF_READ = "ReportingSelfRead",
  CRM_ADMIN = "CrmAdmin",
  CRM_EMAIL_TEMPLATE_CONTROL = "CrmEmailTemplateControl",
  CRM_MANAGER = "CrmManager",
  CRM_SUPER_ADMIN = "CrmSuperAdmin",
  CRM_USER = "CrmUser",
  TENANT_CREATE = "TenantCreate",
  TENANT_READ = "TenantRead",
  TENANT_UPDATE = "TenantUpdate",
  TENANT_DELETE = "TenantDelete",
  TENANT_MOVE = "TenantMove",
  INVENTORY_ACCESS_READ = "InventoryAccessRead",
  UNIT_CREATE = "UnitCreate",
  UNIT_READ = "UnitRead",
  UNIT_UPDATE = "UnitUpdate",
  UNIT_DELETE = "UnitDelete",
  UNIT_USED_CREATE = "UnitUsedCreate",
  UNIT_ACCOUNTING_CREATE = "UnitAccountingCreate",
  UNIT_ACCOUNTING_READ = "UnitAccountingRead",
  UNIT_ACCOUNTING_UPDATE = "UnitAccountingUpdate",
  UNIT_ACCOUNTING_DELETE = "UnitAccountingDelete",
  UNIT_COST_READ = "UnitCostRead",
  UNIT_PAC_COST_READ = "UnitPacCostRead",
  UNIT_PRICING_CREATE = "UnitPricingCreate",
  UNIT_PRICING_UPDATE = "UnitPricingUpdate",
  UNIT_TEMPLATES_CREATE = "UnitTemplatesCreate",
  UNIT_TEMPLATES_READ = "UnitTemplatesRead",
  UNIT_TEMPLATES_UPDATE = "UnitTemplatesUpdate",
  UNIT_TEMPLATES_DELETE = "UnitTemplatesDelete",
  ACCESSORIES_CREATE = "AccessoriesCreate",
  ACCESSORIES_UPDATE = "AccessoriesUpdate",
  ACCESSORIES_DELETE = "AccessoriesDelete",
  ASSET_TYPE_CREATE = "AssetTypeCreate",
  ASSET_TYPE_UPDATE = "AssetTypeUpdate",
  ASSET_TYPE_DELETE = "AssetTypeDelete",
  BODY_STYLE_CREATE = "BodyStyleCreate",
  BODY_STYLE_UPDATE = "BodyStyleUpdate",
  BODY_STYLE_DELETE = "BodyStyleDelete",
  DIVISION_CREATE = "DivisionCreate",
  DIVISION_UPDATE = "DivisionUpdate",
  DIVISION_DELETE = "DivisionDelete",
  FLOOR_LAYOUT_CREATE = "FloorLayoutCreate",
  FLOOR_LAYOUT_UPDATE = "FloorLayoutUpdate",
  FLOOR_LAYOUT_DELETE = "FloorLayoutDelete",
  MANUFACTURER_CREATE = "ManufacturerCreate",
  MANUFACTURER_UPDATE = "ManufacturerUpdate",
  MANUFACTURER_DELETE = "ManufacturerDelete",
  PURCHASE_TYPE_CREATE = "PurchaseTypeCreate",
  PURCHASE_TYPE_UPDATE = "PurchaseTypeUpdate",
  PURCHASE_TYPE_DELETE = "PurchaseTypeDelete",
  SUB_FLOOR_PLAN_CREATE = "SubFloorPlanCreate",
  SUB_FLOOR_PLAN_UPDATE = "SubFloorPlanUpdate",
  SUB_FLOOR_PLAN_DELETE = "SubFloorPlanDelete",
  UNIT_BRAND_CREATE = "UnitBrandCreate",
  UNIT_BRAND_UPDATE = "UnitBrandUpdate",
  UNIT_BRAND_DELETE = "UnitBrandDelete",
  UNIT_MODEL_CREATE = "UnitModelCreate",
  UNIT_MODEL_UPDATE = "UnitModelUpdate",
  UNIT_MODEL_DELETE = "UnitModelDelete",
  UNIT_SERIES_CREATE = "UnitSeriesCreate",
  UNIT_SERIES_UPDATE = "UnitSeriesUpdate",
  UNIT_SERIES_DELETE = "UnitSeriesDelete",
  UNIT_STATUS_CREATE = "UnitStatusCreate",
  UNIT_STATUS_UPDATE = "UnitStatusUpdate",
  UNIT_STATUS_DELETE = "UnitStatusDelete",
  UNIT_TYPE_CREATE = "UnitTypeCreate",
  UNIT_TYPE_UPDATE = "UnitTypeUpdate",
  UNIT_TYPE_DELETE = "UnitTypeDelete",
  PURCHASE_ORDER_STATUSES_CREATE = "PurchaseOrderStatusesCreate",
  PURCHASE_ORDER_STATUSES_UPDATE = "PurchaseOrderStatusesUpdate",
  PURCHASE_ORDER_STATUSES_DELETE = "PurchaseOrderStatusesDelete",
  ORDERING_CREATE = "OrderingCreate",
  ORDERING_UPDATE = "OrderingUpdate",
  ORDERING_READ = "OrderingRead",
  ORDERING_DELETE = "OrderingDelete",
  INVENTORY_REPORTING_READ = "InventoryReportingRead",
  REPORTING_ACCOUNTING_READ = "ReportingAccountingRead",
  INVENTORY_REPORTING_MANAGEMENT_READ = "InventoryReportingManagementRead",
  REPORTING_FINANCE_READ = "ReportingFinanceRead",
  REPORTING_SALES_OTHER_READ = "ReportingSalesOtherRead",
  FINANCE_ACCESS_READ = "FinanceAccessRead",
  FINANCE_QUOTE_CREATE = "FinanceQuoteCreate",
  FINANCE_QUOTE_READ = "FinanceQuoteRead",
  FINANCE_QUOTE_UPDATE = "FinanceQuoteUpdate",
  FINANCE_QUOTE_VOID = "FinanceQuoteVoid",
  FINANCE_DEAL_CREATE = "FinanceDealCreate",
  FINANCE_DEAL_READ = "FinanceDealRead",
  FINANCE_DEAL_UPDATE = "FinanceDealUpdate",
  FINANCE_DEAL_VOID = "FinanceDealVoid",
  FINANCE_HISTORY_READ = "FinanceHistoryRead",
  FORMS_SYNC_EXECUTE = "FormsSyncExecute",
  FORMS_SYNC_READ = "FormsSyncRead",
}
