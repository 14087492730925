import { TextFieldProps, TextField, InputProps, Tooltip } from "@mui/material";
import React, { memo, useEffect, useMemo, useState } from "react";
import { textFieldStyle } from "appConstants/styles";
import { usePropagateRef } from "./usePropagateRef";
import {
  NumberFormatValues,
  NumericFormat,
  NumericFormatProps,
} from "react-number-format";
import styled from "@emotion/styled";
import { CURRENCY_SIGN } from "appConstants";

export type PerformantTextFieldProps = {
  name: string;
  disablePerformance?: boolean; // INFO: IF true, it will use the traditional method for disabling performance
  loading?: boolean;
  min?: number;
  max?: number;
  error?: boolean;
  touched?: boolean;
  value?: any;
  onChange?: any;
  helperText?: React.ReactNode;
  InputProps?: InputProps;
  isAmountType?: boolean;
  sx?: object;
  tooltip?: string;
  numericFormatProps?: Partial<NumericFormatProps>;
} & Omit<TextFieldProps, "name">;
/**
 * This is kind of hacky solution, but it mostly works. Your mileage may vary
 */
const PerformantTextField: React.FC<PerformantTextFieldProps> = memo(
  (props) => {
    const { value, touched, error, isAmountType = false, sx, tooltip } = props;

    /**
     * For performance reasons (possible due to CSS in JS issues), heavy views
     * affect re-renders (Formik changes state in every re-render), bringing keyboard
     * input to its knees. To control this, we create a setState that handles the field's inner
     * (otherwise you wouldn't be able to type) and then propagate the change to Formik onBlur and
     * onFocus.
     */
    const [fieldValue, setFieldValue] = useState<string | number>(value);
    const { disablePerformance, loading, onChange, ...otherProps } = props;

    const field = { error, value, touched };

    const inputRef = React.useRef<HTMLInputElement>(null);

    const StyledTextField = useMemo(
      () =>
        styled(TextField)(({ theme }) => ({
          ...(textFieldStyle as any),
          ...sx,
        })),
      [sx, textFieldStyle]
    );

    usePropagateRef({
      setFieldValue,
      name: props.name,
      value: value,
    });
    /**
     * Using this useEffect guarantees us that pre-filled forms
     * such as passwords work.
     */
    useEffect(() => {
      setInitialValue();
    }, [value]);

    const setInitialValue = () => {
      if (touched) {
        return;
      }

      if (value !== fieldValue) {
        setFieldValue(value);
      }
    };

    /**
     * This is the onChange handler for the TextField
     * It will set the value of the field in the state
     */
    const onChangeHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue(evt.target.value);
    };

    const handleValueChange = (values: NumberFormatValues) => {
      setFieldValue(values.floatValue || "");
    };

    // INFO: This is to propagate the change to Formik onBlur and onFocus
    const onBlur = () => {
      if (fieldValue !== value) {
        window.setTimeout(() => {
          onChange({
            target: {
              name: props.name,
              value:
                props.type === "number"
                  ? parseInt(fieldValue as string, 10)
                  : fieldValue,
            },
          });
        }, 0);
      }
    };

    // INFO: Will set depending on the performance props
    const performanceProps = disablePerformance
      ? {
          ...field,
          onChange,
          value: loading ? "Loading..." : fieldValue,
        }
      : {
          ...field,
          value: loading ? "Loading..." : fieldValue,
          ...(isAmountType
            ? { onValueChange: handleValueChange }
            : { onChange: onChangeHandler }),
          onBlur,
          onFocus: onBlur,
        };

    return (
      <Tooltip title={tooltip || ""} arrow disableInteractive>
        {isAmountType ? (
          <NumericFormat
            customInput={StyledTextField}
            thousandSeparator
            spellCheck
            valueIsNumericString
            prefix={CURRENCY_SIGN.DOLLAR}
            variant="filled"
            inputRef={inputRef}
            fixedDecimalScale={2}
            decimalScale={2}
            {...(otherProps as any)}
            {...performanceProps}
          />
        ) : (
          <TextField
            variant="filled"
            inputRef={inputRef}
            {...otherProps}
            {...performanceProps}
            sx={{ ...textFieldStyle, ...sx }}
          />
        )}
      </Tooltip>
    );
  }
);

export default PerformantTextField;
