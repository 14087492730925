import styled from "@emotion/styled";
import {
  Box,
  Button,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  StackProps,
  Typography,
} from "@mui/material";
import TopIndicator from "components/TopIndicator/TopIndicator";
import useAppHeader from "./useAppHeader";

import { MenuRounded } from "@mui/icons-material";
import {
  appMainHeaderStyle,
  pxToRem,
  rightSideBarStyle,
  themeColorHex,
  topIndicatorBar,
} from "appConstants/styles";
import { MENU_ITEMS } from "appConstants/routes";
import React from "react";
import AvatarWrapper from "components/Avatar";
import LogoutIcon from "components/LogoutIcon";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AppLogo from "images/fun-town-rv.svg";
import { Link } from "react-router-dom";
import { MENU_ITEMS_TYPE, MenuItemChildLink } from "types";
import PersonPinCircleRoundedIcon from "@mui/icons-material/PersonPinCircleRounded";
import {
  StyledBlinkingBadge,
  Button as ButtonWrappper,
  DialogBox,
} from "components";
import { SelectCurrentDealershipDialog } from "./components";
import HandshakeIcon from "@mui/icons-material/Handshake";

const AppHeaderWrapper = styled(Stack)<StackProps>();

const AppHeader = () => {
  const {
    activeMenuItem,
    moduleColor,
    moduleSubColor,
    headerMenuOpen,
    anchorEl,
    leftContent,
    rightContent,
    cornerContent,
    NavSubLinks,
    currentUser,
    profileImage,
    currentDealership,
    crmBaseURL,
    isOpen,
    toggleModal,
    checkPermission,
    handleHeaderMenuClick,
    handleHeaderMenuClose,
    handleLogout,
  } = useAppHeader();

  const renderModuleMenuItem = (item: MENU_ITEMS_TYPE, hasAccess: boolean) => {
    const isActive = activeMenuItem?.link === item?.link;

    return (
      <MenuItem
        key={item.index}
        onClick={handleHeaderMenuClose}
        className={`mainMenuItem ${isActive ? "active" : ""}`}
        disabled={!hasAccess}
        sx={{
          ".MuiSvgIcon-root": {
            color: item?.moduleColor,
          },

          "&:hover, &.active": {
            backgroundColor: item?.moduleColor,
          },
        }}
      >
        {item?.moduleIcon && React.createElement(item?.moduleIcon)}
        <Typography>{item?.label}</Typography>
      </MenuItem>
    );
  };

  const renderSubMenuItem = (
    linkItem: MenuItemChildLink,
    index: number,
    hasAccess: boolean
  ) => (
    <ListItem
      key={index}
      sx={{
        borderRadius: pxToRem(4),
        height: pxToRem(31),
        whiteSpace: "nowrap",
        px: "0 !important",
      }}
    >
      <ListItemButton
        disabled={!hasAccess}
        sx={{
          backgroundColor: linkItem.isActive ? moduleColor : "transparent",
          color: linkItem.isActive ? "grey.A400" : "grey.50",
          height: pxToRem(31),
          borderRadius: pxToRem(4),
          transition: "none",
          "&:hover": {
            backgroundColor: linkItem.isActive ? moduleColor : moduleSubColor,
          },
        }}
      >
        <ListItemText primary={linkItem.label} />
      </ListItemButton>
    </ListItem>
  );

  const renderCurrentDealershipButton = () => (
    <>
      {" "}
      <ButtonWrappper
        combinedSx={appMainHeaderStyle.currentLocationButtonSytle}
        onClick={toggleModal}
      >
        <Stack position="relative">
          <StyledBlinkingBadge
            badgeContent=""
            sx={appMainHeaderStyle.currentLocationButtonSytle.blinkStyle}
          />
          <PersonPinCircleRoundedIcon />
        </Stack>
        <Typography
          sx={appMainHeaderStyle.currentLocationButtonSytle.locationNameStyle}
        >
          {currentDealership?.pc || "N/A"}
        </Typography>
      </ButtonWrappper>{" "}
      <DialogBox open={isOpen} onClose={toggleModal} size="md">
        <SelectCurrentDealershipDialog onCancel={toggleModal} />
      </DialogBox>
    </>
  );

  return (
    <>
      <TopIndicator
        backgroundColor={moduleColor}
        sx={{ mb: topIndicatorBar.spaceBottom }}
      />
      <AppHeaderWrapper
        flexDirection={"row"}
        sx={appMainHeaderStyle}
        pr={pxToRem(25)}
        mb={pxToRem(18)}
      >
        {/* Main Navigation [Start] */}
        <Box>
          {/* Menu button */}
          <Button
            aria-controls={headerMenuOpen ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={headerMenuOpen ? "true" : undefined}
            onClick={handleHeaderMenuClick}
            sx={{
              ...appMainHeaderStyle.btnMainMenuStyle,
              "&, &:hover": {
                backgroundColor: moduleColor,
              },
            }}
          >
            {!headerMenuOpen && <MenuRounded />}
            {headerMenuOpen && <CloseRoundedIcon />}
          </Button>
          {/* Menu List */}
          <Menu
            id="appMainNenu"
            anchorEl={anchorEl}
            open={headerMenuOpen}
            onClose={handleHeaderMenuClose}
            MenuListProps={{
              "aria-labelledby": "appMainNenu",
            }}
            sx={appMainHeaderStyle.mainMenuDropdownStyle}
          >
            {MENU_ITEMS?.map((item, index) => {
              if (item?.showItem === false) return null;
              const hasAccess =
                checkPermission(item.permission) || !item.permission;

              return hasAccess && !item.disabled ? (
                <Link
                  to={item?.link}
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  {renderModuleMenuItem(item, true)}
                </Link>
              ) : (
                renderModuleMenuItem(item, false)
              );
            })}

            {!!crmBaseURL && (
              <MenuItem
                className="otherMenuItem"
                component="a"
                href={crmBaseURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <HandshakeIcon /> Back to CRM
              </MenuItem>
            )}

            <MenuItem className="otherMenuItem" onClick={handleLogout}>
              <LogoutIcon /> Logout
            </MenuItem>

            <Divider />

            <Link
              to={"/"} //TODO: Current logged-in user profile link will be attached here
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <MenuItem className="otherMenuItem userTile">
                <Stack direction={"row"} gap={pxToRem(8)} alignItems={"center"}>
                  <Box>
                    <AvatarWrapper
                      userName={currentUser?.fullName || ""}
                      src={profileImage}
                    />
                  </Box>
                  <Box>
                    <Typography className="userName">
                      {currentUser?.fullName}
                    </Typography>
                    <Typography className="userEmail">
                      {currentUser?.employeeInbox}
                    </Typography>
                  </Box>
                </Stack>
              </MenuItem>
            </Link>
          </Menu>
        </Box>
        {/* Main Navigation [END] */}
        {/* Center Block [Start] */}
        <Box
          display="flex"
          sx={{
            direction: "row",
            alignItems: "center",
          }}
        >
          {/* TODO: */}
          {/* {renderCurrentDealershipButton()} */}
          <Link
            to="/"
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <Box
              sx={{
                backgroundImage: `url(${AppLogo})`,
                backgroundSize: "cover",
                height: pxToRem(33),
                width: pxToRem(113),
                mr: pxToRem(13),
              }}
            />
          </Link>
        </Box>
        <Box flex={1}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            height={"100%"}
            borderBottom={`${pxToRem(1)} solid`}
            borderColor="greyVariantAlt.800"
          >
            {leftContent ? (
              <Box>{leftContent}</Box>
            ) : (
              <Stack direction={"row"} gap={pxToRem(7)}>
                {NavSubLinks.map((linkItem, index) => {
                  const hasAccess =
                    checkPermission(linkItem.permission) ||
                    !linkItem.permission;

                  return hasAccess ? (
                    <Link
                      to={linkItem.link}
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                      }}
                    >
                      {renderSubMenuItem(linkItem, index, hasAccess)}
                    </Link>
                  ) : (
                    renderSubMenuItem(linkItem, index, false)
                  );
                })}
              </Stack>
            )}
            {rightContent && <Box>{rightContent}</Box>}
          </Stack>
        </Box>
        {/* Center Block [END] */}
        {/* Corner Block [Start] */}
        {cornerContent && <Box sx={rightSideBarStyle}>{cornerContent}</Box>}
        {/* Corner Block [END] */}
      </AppHeaderWrapper>
    </>
  );
};

export default AppHeader;
