import {
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from "@mui/material";
import { MenuObjectType } from "types";

type MenuPropType = {
  menuData: MenuObjectType;
};

const ContextMenu = (props: MenuPropType) => {
  const { menuData } = props;

  const handleMenuItemClick = (key: number) => {
    const menuItem = menuData ? menuData[key] : null;
    if (menuItem && menuItem.handleClick) {
      menuItem.handleClick();
    }
  };

  return (
    <MenuList>
      {Object.keys(menuData).map((key) => {
        const menuItem = menuData ? menuData[parseInt(key, 10)] : null;
        if (menuItem && menuItem.divider) {
          return <Divider key={key} />;
        }
        return (
          <MenuItem
            key={key}
            onClick={() => {
              handleMenuItemClick(parseInt(key, 10));
            }}
            disabled={menuItem.isDisabled}
          >
            {menuItem?.icon && <ListItemIcon>{menuItem.icon}</ListItemIcon>}
            {menuItem?.label && <ListItemText>{menuItem.label}</ListItemText>}
            {menuItem?.component}
          </MenuItem>
        );
      })}
    </MenuList>
  );
};

export default ContextMenu;
