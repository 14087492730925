import { useEffect, useState, useCallback } from "react";
import { TextField, Autocomplete, Chip, TextFieldProps } from "@mui/material";
import { pxToRem, textFieldStyle } from "appConstants";
import debounce from "lodash.debounce";

type TagsInputPropType<T extends object | string> = {
  availableTags?: T[];
  addedTags?: string[] | string;
  multiple?: boolean;
  freeSolo?: boolean;
  label?: string;
  textFieldProps?: TextFieldProps;
  mode?: "number" | "string" | "both";
  onChange: (tags: string | object | string[] | T[]) => void;
  fetchOptions?: (input: string) => Promise<T[]>;
  getOptionLabel?: (option: T) => string;
  getOptionId?: (option: T) => number;
};

const TagsInput = <T extends object | string>({
  availableTags = [],
  addedTags,
  multiple = false,
  freeSolo = false,
  label = "Tags",
  textFieldProps = {},
  mode = "both",
  onChange,
  fetchOptions,
  getOptionLabel,
  getOptionId,
}: TagsInputPropType<T>) => {
  const [selectedTags, setSelectedTags] = useState<
    string | object | string[] | T[]
  >([]);
  const [options, setOptions] = useState<T[]>(availableTags);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [backspacePressed, setBackspacePressed] = useState(false);

  const isValidInput = (input: string) => {
    if (mode === "number") return /^\d+$/.test(input);
    if (mode === "string") return /^[^\d]+$/.test(input);
    return true;
  };

  const defaultGetOptionLabel = (option: T) =>
    typeof option === "string"
      ? option
      : getOptionLabel
      ? getOptionLabel(option)
      : String(option);
  const defaultGetOptionId = (option: T) =>
    typeof option === "string" ? option : getOptionId!(option);

  const { sx: textFieldSx, ...textFieldPropObj } = textFieldProps || {};

  // Function to fetch options with debouncing
  const fetchSearchResults = useCallback(
    debounce(async (input: string) => {
      if (fetchOptions && input.trim().length > 0) {
        setLoading(true);
        try {
          const results = await fetchOptions(input);
          setOptions(
            results?.length > 0
              ? results
              : availableTags?.length > 0
              ? availableTags
              : []
          );
        } catch (error) {
          setOptions(availableTags?.length > 0 ? availableTags : []);
        } finally {
          setLoading(false);
        }
      }
    }, 500),
    [fetchOptions, availableTags, backspacePressed]
  );

  const handleChange = (_event: any, newValue: (T | T[] | string)[]) => {
    const updatedTags = multiple
      ? newValue
          .map((option) =>
            typeof option === "string"
              ? option
              : String(defaultGetOptionId(option as T))
          )
          .filter(isValidInput) // Validate input
      : newValue
      ? freeSolo
        ? isValidInput(String(newValue))
          ? newValue
          : []
        : isValidInput(String(getOptionId(newValue as T)))
        ? [String(getOptionId(newValue as T))]
        : []
      : [];

    setSelectedTags(updatedTags);
    onChange(updatedTags);
  };

  const handleInputChange = (_event: any, value: string, reason: string) => {
    if (reason === "reset" || reason === "select-option") return;

    if (!isValidInput(value)) return;

    if (reason === "clear" || value?.trim() === "") {
      setOptions([...availableTags]);
      setBackspacePressed(false);
    } else {
      const filteredOptions = availableTags.filter((option) =>
        defaultGetOptionLabel(option)
          ?.toLowerCase()
          ?.includes(value?.toLowerCase())
      );

      setOptions(
        filteredOptions.length > 0
          ? filteredOptions
          : freeSolo
          ? ([...availableTags, value] as T[])
          : availableTags
      );
      if (fetchOptions && !backspacePressed) fetchSearchResults(value);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Backspace") {
      setBackspacePressed(true);
    } else if (event.key === " ") {
      event.preventDefault();
      setOpen(true);
    } else {
      setBackspacePressed(false);
    }
  };

  useEffect(() => {
    if (addedTags) {
      setSelectedTags(addedTags);
    }
  }, [addedTags]);

  useEffect(() => {
    setOptions(availableTags);
  }, [availableTags]);

  return (
    <Autocomplete
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      multiple={multiple}
      freeSolo={freeSolo}
      options={options}
      loading={loading}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionLabel={defaultGetOptionLabel}
      isOptionEqualToValue={(option, value) => {
        if (typeof option === "string" || typeof value === "string") {
          return option === value;
        }
        return (
          getOptionId &&
          String(getOptionId(option as T)) === String(getOptionId(value as T))
        );
      }}
      value={
        multiple
          ? selectedTags
          : freeSolo
          ? selectedTags
          : options.find(
              (option) =>
                String(getOptionId(option)) === String(selectedTags[0])
            ) || null
      }
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderTags={(value, getTagProps) =>
        multiple
          ? value.map((option, index) => (
              <Chip
                key={option}
                variant="filled"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          : null
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="filled"
          label={label}
          sx={{ ...textFieldStyle }}
          InputProps={{
            ...params.InputProps,
            sx: { py: "0 !important", pl: `${pxToRem(10)} !important` },
          }}
          {...textFieldProps}
          onKeyDown={handleKeyDown}
        />
      )}
    />
  );
};

export default TagsInput;
