import { GRID_TYPE } from "appConstants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllGridSettings } from "redux/slices/gridSettings/gridSettings.action";
import { AppDispatch, RootState } from "redux/store";

const useSavedGridSetttingsDialog = (
  gridType: GRID_TYPE,
  onSelection: (e: any) => void
) => {
  const [selectedSetting, setSelectedSetting] = useState<number>(null);
  const dispatch = useDispatch<AppDispatch>();

  const { settings, currentSetting, isLoading } = useSelector(
    (state: RootState) => state.gridSettings
  );

  useEffect(() => {
    if (settings?.length) {
      dispatch(getAllGridSettings({ gridType }));
    }
  }, []);

  useEffect(() => {
    if (currentSetting?.[gridType]) {
      setSelectedSetting(currentSetting[gridType].id);
    }
  }, [currentSetting]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSetting(e?.target?.value as never as number);
  };

  const handleSelect = () => {
    if (selectedSetting) {
      onSelection({ target: { value: selectedSetting } });
    }
  };

  const savedGridSettings = settings?.map((setting) => ({
    label: setting.viewName,
    value: setting.id,
  }));

  return {
    isLoading,
    selectedSetting,
    savedGridSettings,
    handleChange,
    handleSelect,
  };
};

export default useSavedGridSetttingsDialog;
