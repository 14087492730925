import { AlertCard } from "components";
import { htmlToReactParser } from "utils/common";
import useAlertWrapper from "layouts/AlertWrapper/useAlertWrapper";

const AppAlertWrapper = () => {
  const { open, setOpen, severity, error, successMessage } = useAlertWrapper();

  return (
    <AlertCard
      setOpenState={setOpen}
      openState={open}
      severity={severity}
      alertTitle={error.title}
      alertMessage={htmlToReactParser(error.description || successMessage)}
      sx={{ mt: 5, zIndex: "000" }}
    />
  );
};

export default AppAlertWrapper;
