import { gridSettingsSuccessMessages } from "appConstants";
import { GridSettingsState } from "./gridSettings.model";

export const saveGridSettingsFulfilled = (state: GridSettingsState, action) => {
  state.isLoading = false;
  state.isSuccess = true;
  state.successMessage = gridSettingsSuccessMessages.gridSettingsSaved;
};

export const saveGridSettingsRejected = (state: GridSettingsState, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const getAllGridSettingsFulfilled = (state, action) => {
  state.isLoading = false;
  state.settings = action.payload;
};

export const getAllGridSettingsRejected = (state, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};
