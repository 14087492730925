import { createSlice } from "@reduxjs/toolkit";
import { InterestState } from "./interest.model";
import {
  createInterest,
  getInterestById,
  getInterests,
  editInterest,
  getCustomerInterests,
} from "./interest.action";
import {
  createInterestFulfilled,
  createInterestRejected,
  getInterestByIdFulfilled,
  getInterestByIdRejected,
  getInterestsFulfilled,
  getInterestsRejected,
  editInterestFulfilled,
  editInterestRejected,
  getCustomerInterestsFulfilled,
  getCustomerInterestsRejected,
} from "./interest.reducer";
import { setReduxCommonPendingState } from "utils/common";

const initialState: InterestState = {
  isLoading: false,
  successMessage: "",
  error: { title: "", description: "" },
  isSuccess: false,
  interests: [],
  customerInterests: [],
  totalRecords: 0,
  interest: null,
};

export const interestSlice = createSlice({
  name: "interest",
  initialState: initialState,
  reducers: {
    resetSuccess: (state) => {
      state.successMessage = "";
      state.isSuccess = false;
    },
    resetError: (state) => {
      state.error.title = "";
      state.error.description = "";
    },
    reset: () => initialState,
    resetCustomerInterests: (state) => {
      state.customerInterests = [];
    },
  },
  extraReducers: (builder) => (
    builder.addCase(getInterests.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(getInterests.fulfilled, (state, action) => {
      getInterestsFulfilled(state, action);
    }),
    builder.addCase(getInterests.rejected, (state, action) => {
      getInterestsRejected(state, action);
    }),
    builder.addCase(getInterestById.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(getInterestById.fulfilled, (state, action) => {
      getInterestByIdFulfilled(state, action);
    }),
    builder.addCase(getInterestById.rejected, (state, action) => {
      getInterestByIdRejected(state, action);
    }),
    builder.addCase(createInterest.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(createInterest.fulfilled, (state, action) => {
      createInterestFulfilled(state, action);
    }),
    builder.addCase(createInterest.rejected, (state, action) => {
      createInterestRejected(state, action);
    }),
    builder.addCase(editInterest.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(editInterest.fulfilled, (state, action) => {
      editInterestFulfilled(state, action);
    }),
    builder.addCase(editInterest.rejected, (state, action) => {
      editInterestRejected(state, action);
    }),
    builder.addCase(getCustomerInterests.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(getCustomerInterests.fulfilled, (state, action) => {
      getCustomerInterestsFulfilled(state, action);
    }),
    builder.addCase(getCustomerInterests.rejected, (state, action) => {
      getCustomerInterestsRejected(state, action);
    })
  ),
});

export const { resetSuccess, resetError, reset, resetCustomerInterests } =
  interestSlice.actions;
