import { MENU_ITEMS_TYPE } from "types";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CalculateIcon from "@mui/icons-material/Calculate";
import DashboardSharpIcon from "@mui/icons-material/DashboardSharp";
import WidgetsSharpIcon from "@mui/icons-material/WidgetsSharp";
import AnalyticsRoundedIcon from "@mui/icons-material/AnalyticsRounded";
import QueryBuilderTwoToneIcon from "@mui/icons-material/QueryBuilderTwoTone";
import BuildCircleSharpIcon from "@mui/icons-material/BuildCircleSharp";
import DealerShipIcon from "components/DealershipIcon";
import SettingsIcon from "@mui/icons-material/Settings";
import { PERMISSION } from "./security";

export enum HEADER_TYPE {
  DASHBOARD = "Dashboard",
  FINANCE = "Finance",
  DEALERSHIP = "Dealership",
  DEALERSHIP_SETTINGS = "Dealership Settings",
  INVENTORY = "Inventory",
  PERSONNEL = "Personnel",
  REPORTS = "Reports",
  PARTS_SERVICES = "Parts & Services",
  TIME_CLOCK = "Time Clock",
}

export const APP_ROUTES = {
  Home: "/",
  Login: "/login",
  CreateUser: "/createuser",
  ForgotPassword: "/reset-password",
  AuthenticateCode: "/authenticate-code",
  AssignRole: "/roles-access/assign",
  SecurityPersonnel: "/security-personnel",
  CreateRole: "/roles-access/create-role",
  EditRole: "/roles-access/edit-role",
  DuplicateRole: "/roles-access/duplicate-role",
  ViewSecurityPersonnel: "/security-personnel/view-security-personnel",
  CreateSecurityPersonnel: "/security-personnel/create-security-personnel",
  EditSecurityPersonnel: "/security-personnel/edit-security-personnel",
  DuplicateSecurityPersonnel:
    "/security-personnel/duplicate-security-personnel",
  ViewRole: "/roles-access/role",
  MergeSecurityPersonnel: "/security-personnel/merge-security-personnel",
  Logout: "/logout",
  ViewRoles: "/roles-access/roles",
  NotFound: "/not-found",
  Inventory: "/inventory",
  ExampleComp: "/components",
  Reports: "/reports",
  TimeClock: "/timeclock",
  AssetRecords: "/inventory/asset-records",
  DealershipRecords: "/dealership/dealership-records",
  CreateAsset: "/inventory/create-asset",
  EditAsset: "/inventory/edit-asset",
  AccountingOperations: "/inventory/asset/AccountingOperations",
  CreateDealership: "/dealership/create-dealership",
  EditDealership: "/dealership/edit-dealership",
  CreateSubGroupDealership: "/dealership/create-subgroup-dealership",
  ViewDealership: "/dealership/view-dealership",
  DealershipSettingsGeneralInfo: "/dealership-settings/general-info",
  DealershipSettingsStatuses: "/dealership-settings/statuses",
  DealershipSettingsAccounts: "/dealership-settings/accounts",
  DealershipSettingsVendors: "/dealership-settings/vendors",
  DealershipSettingsAddOns: "/dealership-settings/add-ons",
  ViewAsset: "/inventory/view-asset",
  DuplicateAsset: "/inventory/duplicate-asset",
  ViewAssetHistory: "/inventory/view-asset-history",
  ViewAssetHistoryDetails: "/inventory/view-asset-history-details",
  TransactionListing: "/inventory/transaction-listing",
  DispatchRecords: "/inventory/dispatch-records",
  CreateDispatch: "/inventory/create-dispatch",
  EditDispatch: "/inventory/edit-dispatch",
  EditDispatchEvent: "/inventory/edit-dispatch-event",
  CancelDispatch: "/inventory/cacel-dispatch",
  ViewDispatch: "/inventory/view-dispatch",
  ViewDispatchHistory: "/inventory/view-dispatch-history",
  ViewDispatchCalendar: "/inventory/view-dispatch-calendar",
  PurchasingListing: "/inventory/purchasing",
  ViewTemplateItem: "/inventory/purchasing/viewTemplate",
  ViewPurchaseItem: "/inventory/view-purchase",
  CreatePurchaseOrder: "/inventory/create-purchase",
  EditPurchaseOrder: "/inventory/edit-purchase",
  FinanceQuotes: "/finance/quotes",
  FinanceCreateQuote: "/finance/quotes/create",
  FinanceEditQuote: "/finance/quotes/edit",
  FinanceViewQuote: "/finance/quotes/view",
  FinanceDeals: "/finance/deals",
  FinanceEditDeal: "/finance/deals/edit",
  FinanceViewDeal: "/finance/deals/view",
  FinanceDeliveries: "/finance/deliveries",
};

export const MENU_ITEMS: Array<MENU_ITEMS_TYPE> = [
  {
    index: 0,
    label: "Dashboard",
    link: APP_ROUTES.Home,
    moduleIcon: DashboardSharpIcon,
    moduleColor: "appModules.50",
    childLinks: [],
    // showItem: false,
  },
  {
    index: 1,
    label: "Dealership",
    link: APP_ROUTES.DealershipRecords,
    moduleIcon: DealerShipIcon,
    moduleColor: "grey.50",
    permission: PERMISSION.TENANT_READ,
    childLinks: [
      { label: "Dealership", link: APP_ROUTES.DealershipRecords },
      {
        label: "Sub Group",
        link: APP_ROUTES.CreateSubGroupDealership,
        mainMenuLink: APP_ROUTES.DealershipRecords,
      },
      {
        link: APP_ROUTES.CreateDealership,
        parentLink: APP_ROUTES.DealershipRecords,
      },
      {
        link: APP_ROUTES.EditDealership,
        parentLink: APP_ROUTES.DealershipRecords,
      },
      {
        link: APP_ROUTES.ViewDealership,
        parentLink: APP_ROUTES.DealershipRecords,
      },
    ],
  },
  {
    index: 2,
    label: "Dealership Settings",
    disabled: true,
    link: APP_ROUTES.DealershipSettingsGeneralInfo,
    moduleIcon: SettingsIcon,
    moduleColor: "grey.50",
    childLinks: [
      {
        label: "General Info",
        link: APP_ROUTES.DealershipSettingsGeneralInfo,
      },
      {
        label: "Stages",
        link: APP_ROUTES.DealershipSettingsStatuses,
        mainMenuLink: APP_ROUTES.DealershipSettingsGeneralInfo,
      },
      {
        label: "Accounts",
        link: APP_ROUTES.DealershipSettingsAccounts,
        mainMenuLink: APP_ROUTES.DealershipSettingsGeneralInfo,
      },
      {
        label: "Vendors",
        link: APP_ROUTES.DealershipSettingsVendors,
        mainMenuLink: APP_ROUTES.DealershipSettingsGeneralInfo,
      },
      {
        label: "Add-Ons",
        link: APP_ROUTES.DealershipSettingsAddOns,
        mainMenuLink: APP_ROUTES.DealershipSettingsGeneralInfo,
      },
    ],
  },
  {
    index: 3,
    label: "Inventory",
    link: APP_ROUTES.AssetRecords,
    moduleIcon: WidgetsSharpIcon,
    moduleColor: "appModules.200",
    moduleSubColor: "appSubModules.200",
    permission: PERMISSION.INVENTORY_ACCESS_READ,
    childLinks: [
      {
        label: "Asset Records",
        link: APP_ROUTES.AssetRecords,
        permission: PERMISSION.UNIT_READ,
      },
      { link: APP_ROUTES.CreateAsset, parentLink: APP_ROUTES.AssetRecords },
      {
        label: "Purchasing",
        link: APP_ROUTES.PurchasingListing,
        mainMenuLink: APP_ROUTES.AssetRecords,
        permission: PERMISSION.ORDERING_READ,
      },
      {
        label: "Dispatch",
        link: APP_ROUTES.DispatchRecords,
        mainMenuLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.ViewPurchaseItem,
        parentLink: APP_ROUTES.PurchasingListing,
        mainMenuLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.ViewTemplateItem,
        parentLink: APP_ROUTES.PurchasingListing,
        mainMenuLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.ViewDispatch,
        parentLink: APP_ROUTES.DispatchRecords,
        mainMenuLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.EditAsset,
        parentLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.ViewAsset,
        parentLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.DuplicateAsset,
        parentLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.ViewAssetHistory,
        parentLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.ViewAssetHistoryDetails,
        parentLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.TransactionListing,
        parentLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.CreateDispatch,
        parentLink: APP_ROUTES.DispatchRecords,
        mainMenuLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.EditDispatch,
        parentLink: APP_ROUTES.DispatchRecords,
        mainMenuLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.ViewDispatchHistory,
        parentLink: APP_ROUTES.DispatchRecords,
        mainMenuLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.ViewDispatchCalendar,
        parentLink: APP_ROUTES.DispatchRecords,
        mainMenuLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.CreatePurchaseOrder,
        parentLink: APP_ROUTES.PurchasingListing,
        mainMenuLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.EditPurchaseOrder,
        parentLink: APP_ROUTES.PurchasingListing,
        mainMenuLink: APP_ROUTES.AssetRecords,
      },
      {
        link: APP_ROUTES.AccountingOperations,
        parentLink: APP_ROUTES.AssetRecords,
      },
    ],
  },
  {
    index: 4,
    label: "Finance",
    link: APP_ROUTES.FinanceQuotes,
    moduleIcon: CalculateIcon,
    moduleColor: "appModules.300",
    permission: PERMISSION.FINANCE_ACCESS_READ,
    childLinks: [
      {
        label: "Quote",
        link: APP_ROUTES.FinanceQuotes,
        permission: PERMISSION.FINANCE_QUOTE_READ,
      },
      {
        label: "Deal",
        link: APP_ROUTES.FinanceDeals,
        mainMenuLink: APP_ROUTES.FinanceQuotes,
        permission: PERMISSION.FINANCE_DEAL_READ,
      },
      // TODO: Hidden upon client request
      // {
      //   label: "Delivery",
      //   link: APP_ROUTES.FinanceDeliveries,
      //   mainMenuLink: APP_ROUTES.FinanceQuotes,
      // },
      {
        link: APP_ROUTES.FinanceCreateQuote,
        parentLink: APP_ROUTES.FinanceQuotes,
      },
      {
        link: APP_ROUTES.FinanceEditQuote,
        parentLink: APP_ROUTES.FinanceQuotes,
      },
      {
        link: APP_ROUTES.FinanceViewQuote,
        parentLink: APP_ROUTES.FinanceQuotes,
      },
      {
        link: APP_ROUTES.FinanceEditDeal,
        parentLink: APP_ROUTES.FinanceDeals,
      },
      {
        link: APP_ROUTES.FinanceViewDeal,
        parentLink: APP_ROUTES.FinanceDeals,
      },
    ],
  },
  {
    index: 5,
    label: "Personnel",
    link: APP_ROUTES.SecurityPersonnel,
    moduleIcon: AccountBoxIcon,
    moduleColor: "appModules.400",
    permission: PERMISSION.ACCESS_READ,
    childLinks: [
      { label: "Personnel", link: APP_ROUTES.SecurityPersonnel },
      {
        label: "Roles & Access",
        link: APP_ROUTES.ViewRoles,
        mainMenuLink: APP_ROUTES.SecurityPersonnel,
      },
      {
        link: APP_ROUTES.CreateSecurityPersonnel,
        parentLink: APP_ROUTES.SecurityPersonnel,
      },
      {
        link: APP_ROUTES.EditSecurityPersonnel,
        parentLink: APP_ROUTES.SecurityPersonnel,
      },
      {
        link: APP_ROUTES.DuplicateSecurityPersonnel,
        parentLink: APP_ROUTES.SecurityPersonnel,
      },
      {
        link: APP_ROUTES.ViewSecurityPersonnel,
        parentLink: APP_ROUTES.SecurityPersonnel,
      },
      {
        link: APP_ROUTES.CreateRole,
        parentLink: APP_ROUTES.ViewRoles,
        mainMenuLink: APP_ROUTES.SecurityPersonnel,
      },
      {
        link: APP_ROUTES.EditRole,
        parentLink: APP_ROUTES.ViewRoles,
        mainMenuLink: APP_ROUTES.SecurityPersonnel,
      },
      {
        link: APP_ROUTES.DuplicateRole,
        parentLink: APP_ROUTES.ViewRoles,
        mainMenuLink: APP_ROUTES.SecurityPersonnel,
      },
      {
        link: APP_ROUTES.AssignRole,
        parentLink: APP_ROUTES.ViewRoles,
        mainMenuLink: APP_ROUTES.SecurityPersonnel,
      },
      {
        link: APP_ROUTES.ViewRole,
        parentLink: APP_ROUTES.ViewRoles,
        mainMenuLink: APP_ROUTES.SecurityPersonnel,
      },
      {
        link: APP_ROUTES.MergeSecurityPersonnel,
        parentLink: APP_ROUTES.SecurityPersonnel,
      },
    ],
  },
  {
    index: 6,
    label: "Reports",
    link: APP_ROUTES.Reports,
    moduleIcon: AnalyticsRoundedIcon,
    moduleColor: "appModules.500",
    childLinks: [
      { label: "Demo Link", link: "/" },
      { label: "Demo Link", link: "/" },
    ],
  },
  {
    index: 7,
    label: "Time Clock",
    link: APP_ROUTES.TimeClock,
    moduleIcon: QueryBuilderTwoToneIcon,
    moduleColor: "appModules.700",
    childLinks: [],
  },
  {
    index: 8,
    label: "Parts & Services",
    link: APP_ROUTES.ExampleComp,
    moduleIcon: BuildCircleSharpIcon,
    moduleColor: "appModules.600",
    childLinks: [],
  },
  {
    index: 999,
    label: "components",
    link: APP_ROUTES.ExampleComp,
    childLinks: [],
    showItem: false,
  },
];

export const pageTitleByRoute = {
  // Dashboard
  [APP_ROUTES.Home]: {
    parentTitle: HEADER_TYPE.DASHBOARD,
    pageTitle: "Dashboard",
  },

  // Authentication & Roles
  [APP_ROUTES.Login]: {
    parentTitle: HEADER_TYPE.PERSONNEL,
    pageTitle: "Login",
  },
  [APP_ROUTES.CreateUser]: {
    parentTitle: HEADER_TYPE.PERSONNEL,
    pageTitle: "Create Personnel",
  },
  [APP_ROUTES.ForgotPassword]: {
    parentTitle: HEADER_TYPE.PERSONNEL,
    pageTitle: "Reset Password",
  },
  [APP_ROUTES.AuthenticateCode]: {
    parentTitle: HEADER_TYPE.PERSONNEL,
    pageTitle: "Authenticate Code",
  },
  [APP_ROUTES.SecurityPersonnel]: {
    parentTitle: HEADER_TYPE.PERSONNEL,
    pageTitle: "Security Personnel",
  },
  [APP_ROUTES.ViewSecurityPersonnel]: {
    parentTitle: HEADER_TYPE.PERSONNEL,
    pageTitle: "View Personnel",
  },
  [APP_ROUTES.CreateSecurityPersonnel]: {
    parentTitle: HEADER_TYPE.PERSONNEL,
    pageTitle: "Create Personnel",
  },
  [APP_ROUTES.EditSecurityPersonnel]: {
    parentTitle: HEADER_TYPE.PERSONNEL,
    pageTitle: "Edit Personnel",
  },
  [APP_ROUTES.DuplicateSecurityPersonnel]: {
    parentTitle: HEADER_TYPE.PERSONNEL,
    pageTitle: "Duplicate Personnel",
  },
  [APP_ROUTES.MergeSecurityPersonnel]: {
    parentTitle: HEADER_TYPE.PERSONNEL,
    pageTitle: "Merge Personnel",
  },
  [APP_ROUTES.CreateRole]: {
    parentTitle: HEADER_TYPE.PERSONNEL,
    pageTitle: "Create Role",
  },
  [APP_ROUTES.EditRole]: {
    parentTitle: HEADER_TYPE.PERSONNEL,
    pageTitle: "Edit Role",
  },
  [APP_ROUTES.DuplicateRole]: {
    parentTitle: HEADER_TYPE.PERSONNEL,
    pageTitle: "Duplicate Role",
  },
  [APP_ROUTES.AssignRole]: {
    parentTitle: HEADER_TYPE.PERSONNEL,
    pageTitle: "Assign Role",
  },
  [APP_ROUTES.ViewRole]: {
    parentTitle: HEADER_TYPE.PERSONNEL,
    pageTitle: "View Role",
  },
  [APP_ROUTES.ViewRoles]: {
    parentTitle: HEADER_TYPE.PERSONNEL,
    pageTitle: "Roles",
  },

  // Finance
  [APP_ROUTES.FinanceQuotes]: {
    parentTitle: HEADER_TYPE.FINANCE,
    pageTitle: "Quote Listing",
  },
  [APP_ROUTES.FinanceCreateQuote]: {
    parentTitle: HEADER_TYPE.FINANCE,
    pageTitle: "Create Quote",
  },
  [APP_ROUTES.FinanceEditQuote]: {
    parentTitle: HEADER_TYPE.FINANCE,
    pageTitle: "Edit Quote",
  },
  [APP_ROUTES.FinanceViewQuote]: {
    parentTitle: HEADER_TYPE.FINANCE,
    pageTitle: "View Quote",
  },
  [APP_ROUTES.FinanceDeals]: {
    parentTitle: HEADER_TYPE.FINANCE,
    pageTitle: "Deal Listing",
  },
  [APP_ROUTES.FinanceEditDeal]: {
    parentTitle: HEADER_TYPE.FINANCE,
    pageTitle: "Edit Deal",
  },
  [APP_ROUTES.FinanceViewDeal]: {
    parentTitle: HEADER_TYPE.FINANCE,
    pageTitle: "View Deal",
  },
  [APP_ROUTES.FinanceDeliveries]: {
    parentTitle: HEADER_TYPE.FINANCE,
    pageTitle: "Deliveries",
  },

  // Inventory
  [APP_ROUTES.Inventory]: {
    parentTitle: HEADER_TYPE.INVENTORY,
    pageTitle: "Inventory Overview",
  },
  [APP_ROUTES.AssetRecords]: {
    parentTitle: HEADER_TYPE.INVENTORY,
    pageTitle: "Asset Listing",
  },
  [APP_ROUTES.CreateAsset]: {
    parentTitle: HEADER_TYPE.INVENTORY,
    pageTitle: "Create Asset",
  },
  [APP_ROUTES.EditAsset]: {
    parentTitle: HEADER_TYPE.INVENTORY,
    pageTitle: "Edit Asset",
  },
  [APP_ROUTES.ViewAsset]: {
    parentTitle: HEADER_TYPE.INVENTORY,
    pageTitle: "View Asset",
  },
  [APP_ROUTES.DuplicateAsset]: {
    parentTitle: HEADER_TYPE.INVENTORY,
    pageTitle: "Duplicate Asset",
  },
  [APP_ROUTES.ViewAssetHistory]: {
    parentTitle: HEADER_TYPE.INVENTORY,
    pageTitle: "Asset History",
  },
  [APP_ROUTES.ViewAssetHistoryDetails]: {
    parentTitle: HEADER_TYPE.INVENTORY,
    pageTitle: "Asset History Details",
  },
  [APP_ROUTES.AccountingOperations]: {
    parentTitle: HEADER_TYPE.INVENTORY,
    pageTitle: "Accounting Operations",
  },
  [APP_ROUTES.TransactionListing]: {
    parentTitle: HEADER_TYPE.INVENTORY,
    pageTitle: "Transaction Listing",
  },
  // Purchase
  [APP_ROUTES.PurchasingListing]: {
    parentTitle: HEADER_TYPE.INVENTORY,
    pageTitle: "Purchase Order Listing",
  },
  [APP_ROUTES.ViewTemplateItem]: {
    parentTitle: HEADER_TYPE.INVENTORY,
    pageTitle: "View Template Item",
  },
  [APP_ROUTES.ViewPurchaseItem]: {
    parentTitle: HEADER_TYPE.INVENTORY,
    pageTitle: "View Purchase Item",
  },
  [APP_ROUTES.CreatePurchaseOrder]: {
    parentTitle: HEADER_TYPE.INVENTORY,
    pageTitle: "Create Purchase Order",
  },
  [APP_ROUTES.EditPurchaseOrder]: {
    parentTitle: HEADER_TYPE.INVENTORY,
    pageTitle: "Edit Purchase Order",
  },

  // Dispatch
  [APP_ROUTES.DispatchRecords]: {
    parentTitle: HEADER_TYPE.INVENTORY,
    pageTitle: "Dispatch Listing",
  },
  [APP_ROUTES.ViewDispatch]: {
    parentTitle: HEADER_TYPE.INVENTORY,
    pageTitle: "View Dispatch",
  },
  [APP_ROUTES.CreateDispatch]: {
    parentTitle: HEADER_TYPE.INVENTORY,
    pageTitle: "Create Dispatch",
  },
  [APP_ROUTES.EditDispatch]: {
    parentTitle: HEADER_TYPE.INVENTORY,
    pageTitle: "Edit Dispatch",
  },
  [APP_ROUTES.ViewDispatchHistory]: {
    parentTitle: HEADER_TYPE.INVENTORY,
    pageTitle: "View Dispatch History",
  },
  [APP_ROUTES.ViewDispatchCalendar]: {
    parentTitle: HEADER_TYPE.INVENTORY,
    pageTitle: "View Dispatch Calendar",
  },
  [APP_ROUTES.EditDispatchEvent]: {
    parentTitle: HEADER_TYPE.INVENTORY,
    pageTitle: "Edit Dispatch Event",
  },
  [APP_ROUTES.CancelDispatch]: {
    parentTitle: HEADER_TYPE.INVENTORY,
    pageTitle: "Cancel Dispatch",
  },

  // Dealership
  [APP_ROUTES.DealershipRecords]: {
    parentTitle: HEADER_TYPE.DEALERSHIP,
    pageTitle: "Dealership Listing",
  },
  [APP_ROUTES.CreateDealership]: {
    parentTitle: HEADER_TYPE.DEALERSHIP,
    pageTitle: "Create Dealership",
  },
  [APP_ROUTES.EditDealership]: {
    parentTitle: HEADER_TYPE.DEALERSHIP,
    pageTitle: "Edit Dealership",
  },
  [APP_ROUTES.ViewDealership]: {
    parentTitle: HEADER_TYPE.DEALERSHIP,
    pageTitle: "View Dealership",
  },
  [APP_ROUTES.CreateSubGroupDealership]: {
    parentTitle: HEADER_TYPE.DEALERSHIP,
    pageTitle: "Create Dealership Subgroup",
  },

  // Dealership Settings
  [APP_ROUTES.DealershipSettingsGeneralInfo]: {
    parentTitle: HEADER_TYPE.DEALERSHIP_SETTINGS,
    pageTitle: "General Information",
  },
  [APP_ROUTES.DealershipSettingsStatuses]: {
    parentTitle: HEADER_TYPE.DEALERSHIP_SETTINGS,
    pageTitle: "Statuses",
  },
  [APP_ROUTES.DealershipSettingsAccounts]: {
    parentTitle: HEADER_TYPE.DEALERSHIP_SETTINGS,
    pageTitle: "Accounts",
  },
  [APP_ROUTES.DealershipSettingsVendors]: {
    parentTitle: HEADER_TYPE.DEALERSHIP_SETTINGS,
    pageTitle: "Vendors",
  },
  [APP_ROUTES.DealershipSettingsAddOns]: {
    parentTitle: HEADER_TYPE.DEALERSHIP_SETTINGS,
    pageTitle: "Add-ons",
  },

  // Reports
  [APP_ROUTES.Reports]: {
    parentTitle: HEADER_TYPE.REPORTS,
    pageTitle: "Reports Dashboard",
  },

  // Time Clock
  [APP_ROUTES.TimeClock]: {
    parentTitle: HEADER_TYPE.TIME_CLOCK,
    pageTitle: "User Time Logs",
  },

  // Example Component (If needed)
  [APP_ROUTES.ExampleComp]: {
    parentTitle: HEADER_TYPE.DASHBOARD,
    pageTitle: "Example Component",
  },

  // Not Found
  [APP_ROUTES.NotFound]: {
    parentTitle: HEADER_TYPE.DASHBOARD,
    pageTitle: "Page Not Found",
  },
};
