export enum QUOTE_FORM_SECTION_TYPE {
  BUYER_ORDER = "Buyer's Order",
  ACCESSORIES = "Accessories",
  BUYER = "Buyer / Trade",
  ADMIN = "Admin",
  FIProducts = "F&I Products",
  BREAKDOWN = "Breakdown",
}

export enum LOOKUP_TABLE_MODE {
  Customer = "customer",
  Asset = "asset",
}

export enum QUOTE_SUMMARY_SECTIONS {
  CUSTOMER_INFO = "Customer Information",
  BUYER_ORDER = "Buyer's Order",
  ACCESSORIES = "Accessories",
  BUYER = "Buyer",
  TRADE_INFO = "Trade Info",
  DISSCOUNTS = "Discounts",
  REBATES = "Rebates",
  TAXES = "Taxes",
  FEES = "Fees",
}

export enum FINANCE_MODE {
  DEAL = "deal",
  QUOTE = "quote",
}
