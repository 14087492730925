import React, { MutableRefObject } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Grid,
} from "@mui/material";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { GridCellParams, GridColDef } from "@mui/x-data-grid-premium";
import TableComponent from "components/Table";
import {
  boxShadow,
  cancelBtnStyle,
  filterButton,
  gridSettingsColumnsTableSx,
  gutterSpace,
  pxToRem,
} from "appConstants";
import {
  SwitchWrapper,
  Button,
  SearchBar,
  PerformantTextField,
  CheckboxWrapper,
} from "components";
import SettingsIcon from "@mui/icons-material/Settings";
import useColumnSettingsModal from "./useColumnSettingsModal";
import { GRID_SETTINGS_FIELDS } from "appConstants/FormFields";

type ColumnSettingsModalPropType = {
  onClose: () => void;
  gridApiRef: MutableRefObject<GridApiPremium | null>;
};

const { SETTINGS_NAME, SAVE_AS_DEFAULT } = GRID_SETTINGS_FIELDS;

const ColumnSettingsModal: React.FC<ColumnSettingsModalPropType> = ({
  onClose,
  gridApiRef,
}) => {
  const {
    saveAsButtonLabel,
    save,
    cancel,
    columns,
    searchTerm,
    isAllHidden,
    isAllVisible,
    saveAs,
    settingsNameValue,
    saveAsDefaultValue,
    isValid,
    isSaveDisabled,
    handleChange,
    handleSubmit,
    toggleSaveAsInputs,
    handleSearchChange,
    orderChangeHandler,
    handleToggleColumn,
    handleHideAll,
    handleShowAll,
  } = useColumnSettingsModal(gridApiRef);

  const gridColumns: GridColDef[] = [
    {
      field: "headerName",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: 65,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        if (!gridApiRef.current) return null;

        const visibilityModel =
          gridApiRef.current.state.columns.columnVisibilityModel;

        const isShow = visibilityModel[params?.row.field];

        return (
          <Box onClick={(e) => e.stopPropagation()}>
            <SwitchWrapper
              label={params?.value as string}
              onChange={() => handleToggleColumn(params?.row.field)}
              checked={isShow}
              formLabelProps={{
                sx: {
                  mr: 0,
                  "span.MuiTypography-root": { ml: gutterSpace },
                },
              }}
            />
          </Box>
        );
      },
    },
  ];

  const buttonsCombinedSx = { ...filterButton, width: "100%", height: "100%" };

  return (
    <Box>
      <DialogTitle sx={{ px: "1.5rem !important", pt: "1.5rem !important" }}>
        <Box sx={{ flex: 1 }}>
          <SettingsIcon sx={{ mr: pxToRem(6.28), mb: pxToRem(-3) }} />
          Manage Grid
        </Box>{" "}
        <SearchBar
          sx={{
            maxWidth: "273px",
            border: `${pxToRem(1)} solid`,
            borderColor: "greyVariantAlt.900",
            color: "greyVariantAlt.800",
          }}
          placeholder="Find Column"
          showSearchIcon
          searchParam={searchTerm}
          searchHandler={handleSearchChange}
        />
      </DialogTitle>

      <DialogContent sx={{ px: "1.5rem !important" }}>
        <TableComponent
          rows={columns}
          columns={gridColumns}
          checkboxSelection={false}
          rowReordering
          onRowOrderChange={orderChangeHandler}
          hideHeader={true}
          getRowId={(row) => row.field}
          sx={gridSettingsColumnsTableSx}
        />
      </DialogContent>

      <DialogActions
        sx={{
          boxShadow: boxShadow.elevation1,
          p: "1rem 1.5rem 1.5rem !important",
        }}
      >
        <Grid container spacing={gutterSpace} component="form">
          {saveAs && (
            <>
              <Grid item xs={7}>
                <PerformantTextField
                  fullWidth={true}
                  label="Configuration Name"
                  type="text"
                  name={SETTINGS_NAME}
                  value={settingsNameValue}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={5}>
                <CheckboxWrapper
                  label={"Save as Default"}
                  name={SAVE_AS_DEFAULT}
                  onChange={handleChange}
                  checked={saveAsDefaultValue as boolean}
                  sx={{ ml: pxToRem(11) }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={2.5}>
            <Button
              variant="outlined"
              sx={buttonsCombinedSx}
              onClick={handleHideAll}
              disabled={isAllHidden}
            >
              Hide All
            </Button>
          </Grid>
          <Grid item xs={2.5}>
            <Button
              variant="outlined"
              sx={buttonsCombinedSx}
              onClick={handleShowAll}
              disabled={isAllVisible}
            >
              Show All
            </Button>
          </Grid>
          <Grid item xs={0.5} />
          <Grid item xs={3.25}>
            <Button onClick={onClose} combinedSx={cancelBtnStyle}>
              {cancel}
            </Button>
          </Grid>
          <Grid item xs={3.25}>
            {!saveAs && (
              <Button
                disabled={isAllVisible && isSaveDisabled}
                onClick={toggleSaveAsInputs}
              >
                {saveAsButtonLabel}
              </Button>
            )}
            {saveAs && (
              <Button onClick={() => handleSubmit()} disabled={!isValid}>
                {save}
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Box>
  );
};

export default ColumnSettingsModal;
