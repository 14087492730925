import React, { useState } from "react";
import useDebounce from "./useDebounce";
import { TIMEOUT } from "appConstants";

const useHandleSearch = (fetchMethod: Function) => {
  const [searchParam, setSearchParam] = useState("");

  const searchHandlerDebounce = useDebounce((searchTerm: string) => {
    fetchMethod(searchTerm);
  }, TIMEOUT.DEBOUNCE_TIMEOUT);

  const searchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event?.target?.value;
    setSearchParam(searchTerm);
    searchHandlerDebounce(searchTerm?.toLowerCase());
  };

  return { searchParam, setSearchParam, searchHandler };
};

export default useHandleSearch;
