import { useState } from "react";
import ContactsRoundedIcon from "@mui/icons-material/ContactsRounded";
import Tooltip from "@mui/material/Tooltip";
import ContactCardInfo from "components/ContactCardInfo";
import { pxToRem } from "appConstants";
import { Asset, Customer } from "types";
import { compileFullName } from "utils/common";
import AssetInfoCard from "components/AssetInfoCard";

type TooltipCardPropType =
  | { mode: "customer"; data: Customer }
  | { mode: "asset"; data: Asset };

const TooltipCard = (props: TooltipCardPropType) => {
  const { mode, data } = props;
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleOpenTooltip = (event) => {
    event.stopPropagation(); // Prevent triggering row click
    setOpenTooltip(true);
  };

  const handleCloseTooltip = (event) => {
    event.stopPropagation(); // Prevent triggering row click
    setOpenTooltip(false);
  };

  const name = mode === "customer" ? compileFullName(data) : data?.assetName;

  return (
    <>
      <Tooltip
        open={openTooltip}
        onClose={handleCloseTooltip}
        title={
          mode === "customer" ? (
            <ContactCardInfo
              title={name}
              phoneNumber={data?.cellphone ? "+" + data?.cellphone : ""}
              email={data?.email}
              sx={{ maxWidth: pxToRem(300), margin: pxToRem(-4, -8) }}
            />
          ) : (
            <AssetInfoCard
              title={data?.assetName}
              stockNumber={data?.stockNumber}
              vinNumber={data?.vin}
              sx={{
                backgroundColor: "white",
                color: "black",
                minWidth: "350px",
                margin: pxToRem(-4, -8),
              }}
            />
          )
        }
        placement="bottom-start"
      >
        <ContactsRoundedIcon
          onMouseOver={handleOpenTooltip}
          sx={{
            width: 17,
            marginRight: 1,
            color: "greyVariant.200",
            cursor: "pointer",
          }}
        />
      </Tooltip>
      {name}
    </>
  );
};

export default TooltipCard;
