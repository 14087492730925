import { useEffect, useState } from "react";
import { Box, BoxProps, Grid, SxProps, Tab, Tabs } from "@mui/material";
import TabPanel from "./TabPanel";
import styled from "@emotion/styled";
import PageBody from "components/PageBody";
import {
  pageBottomSpacing,
  gutterSpace,
  pxToRem,
  formRightPad,
  tabMenuStyle,
} from "appConstants";
import { TabmenuItemsAndContent } from "types";
import TitleBar from "components/TitleBar";

type TabMenuPropType = {
  menuItemsAndContent: TabmenuItemsAndContent[];
  sx?: object;
  isFullPageTabs?: boolean;
  isFormRightPad?: boolean;
  tabValue?: string;
  isTabMenuV2?: boolean;
  tabsSx?: SxProps;
  tabPannelSx?: SxProps;
  tabPannelParentGridSx?: SxProps;
  isSearchBarBeforeTabs?: boolean;
  showTitleBar?: boolean;
  titleBarParentTitle?: string;
  titleBarPageTitle?: string;
  titleBarLeftPan?: React.ReactNode;
  titleBarRightPan?: React.ReactNode;
  onTabChange?: (id: string) => void;
};

const TabsWrapper = styled(Box)<BoxProps>();

const TabMenu = (props: TabMenuPropType) => {
  const {
    menuItemsAndContent,
    sx,
    isFullPageTabs = false,
    isFormRightPad = false,
    tabValue,
    isTabMenuV2 = false,
    tabsSx = {},
    tabPannelSx = {},
    tabPannelParentGridSx = {},
    isSearchBarBeforeTabs = false,
    showTitleBar = true,
    titleBarParentTitle,
    titleBarPageTitle,
    titleBarLeftPan = <></>,
    titleBarRightPan = <></>,
    onTabChange,
  } = props;

  const [value, setValue] = useState(menuItemsAndContent[0].id);

  const combinedTabsSx = { ...tabMenuStyle, ...tabsSx };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (onTabChange) {
      onTabChange(newValue);
    }
  };

  useEffect(() => {
    if (tabValue) {
      setValue(tabValue);
      if (onTabChange) {
        onTabChange(tabValue);
      }
    }
  }, [tabValue]);

  if (isTabMenuV2) {
    return (
      <>
        {!isSearchBarBeforeTabs && (
          <Grid item sx={{ flexGrow: 1 }}>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                backgroundColor: "greyVariantAlt.400",
                borderRadius: pxToRem(4),
              }}
            />
          </Grid>
        )}
        <Grid item xs="auto">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={combinedTabsSx}
          >
            {menuItemsAndContent?.map((item) => (
              <Tab key={item.id} label={item.value} value={item.id} />
            ))}
          </Tabs>
        </Grid>
        {showTitleBar && (
          <Grid item xs={12} pr={pxToRem(8)}>
            <TitleBar
              parentTitle={titleBarParentTitle}
              pageTitle={titleBarPageTitle}
              leftPan={titleBarLeftPan}
              rightPan={titleBarRightPan}
              wrapperSx={{ marginBottom: 0 }}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sx={{ ...tabPannelParentGridSx, pt: "0 !important" }}
        >
          {menuItemsAndContent?.map((item) => (
            <PageBody
              isFullPageView={isFullPageTabs}
              sx={{
                ...(item.hasGridOnly && { overflow: "hidden !important" }),
              }}
            >
              <TabPanel
                key={item.id}
                value={value}
                id={item.id}
                sx={{
                  paddingBottom: isFullPageTabs && pageBottomSpacing,
                  paddingRight: isFormRightPad && formRightPad,
                  ...tabPannelSx,
                }}
              >
                <Box my={gutterSpace}>{item.content}</Box>
              </TabPanel>
            </PageBody>
          ))}
        </Grid>
      </>
    );
  }

  return (
    <TabsWrapper sx={sx}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {menuItemsAndContent?.map((item) => (
          <Tab
            key={item.id}
            label={item.value}
            value={item.id}
            sx={{ fontSize: pxToRem(14), fontWeight: 600 }}
          />
        ))}
      </Tabs>
      {menuItemsAndContent?.map((item) => (
        <PageBody key={`page-body-${item.id}`} isFullPageView={isFullPageTabs}>
          <TabPanel
            key={`tab-panel-${item.id}`}
            value={value}
            id={item.id}
            sx={{
              paddingBottom: isFullPageTabs && pageBottomSpacing,
              paddingRight: isFormRightPad && formRightPad,
            }}
          >
            <Box my={gutterSpace}>{item.content}</Box>
          </TabPanel>
        </PageBody>
      ))}
    </TabsWrapper>
  );
};

export default TabMenu;
