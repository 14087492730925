import { MENU_ITEMS } from "appConstants";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "redux/slices/auth/auth.action";
import { AppDispatch, RootState } from "redux/store";
import { MenuItemChildLink } from "types";

const useValidatePermission = () => {
  const permissions = useSelector(
    (state: RootState) => state.auth?.userInfo?.permissions
  );

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!permissions?.length) {
      dispatch(getUserInfo());
    }
  }, []);

  const checkPermission = (permission: string) => {
    if (!permissions?.length) {
      return true;
    }
    return permissions?.includes(permission);
  };

  const getFirstAccessibleLink = (link: string) => {
    const primaryLink = MENU_ITEMS.find((item) => item.link === link);
    // INFO: If the link is not in its parent then no need to check as its permission can be applied once get into module
    if (!primaryLink) {
      return null;
    }
    // INFO: Get only those child links which are in sub navigation list
    const childLinks: MenuItemChildLink[] =
      primaryLink.childLinks?.filter((link) => link.label) || [];

    const anyChildWithoutPermission = childLinks.find(
      (link) => link.permission && !checkPermission(link.permission)
    );

    // INFO: If all the child have permission then no need to replace primary one with other module
    if (!anyChildWithoutPermission) {
      return null;
    }

    return (
      childLinks.find(
        (link) => checkPermission(link.permission) || !link.permission
      )?.link || false
    );
  };

  return { permissions, checkPermission, getFirstAccessibleLink };
};

export default useValidatePermission;
