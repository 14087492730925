import * as React from "react";
import { Autocomplete } from "@react-google-maps/api";
import { PerformantTextField } from "components"; // Import your PerformantTextField
import {
  GOOGLE_ADDRESS_RESTRICTIONS,
  GOOGLE_ADDRESS_FIELDS,
} from "appConstants";
import { TextFieldProps } from "@mui/material";

type GoogleAddressInputPropType = {
  label: string;
  name: string;
  value: string;
  error?: string;
  helperText?: string;
  isLoaded: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onLoad: (autocomplete: google.maps.places.Autocomplete) => void;
  onPlaceChanged: () => void;
} & Partial<TextFieldProps>;

const GoogleAddressInput: React.FC<GoogleAddressInputPropType> = ({
  label,
  name,
  value,
  error,
  helperText,
  isLoaded,
  onChange,
  onLoad,
  onPlaceChanged,
  ...inputProps
}) => {
  return (
    <>
      {isLoaded ? (
        <Autocomplete
          restrictions={GOOGLE_ADDRESS_RESTRICTIONS}
          fields={GOOGLE_ADDRESS_FIELDS}
          onPlaceChanged={onPlaceChanged}
          onLoad={onLoad}
        >
          <PerformantTextField
            fullWidth
            label={label}
            name={name}
            type="text"
            value={value}
            onChange={onChange}
            error={!!error} // Ensure error prop is boolean
            helperText={helperText}
            sx={{
              "& .pac-container": {
                zIndex: 2000, // Or a suitably high z-index
              },
            }}
            {...inputProps}
          />
        </Autocomplete>
      ) : (
        <PerformantTextField
          fullWidth
          label={label}
          name={name}
          type="text"
          value={value}
          onChange={onChange}
          error={!!error} // Ensure error prop is boolean
          helperText={helperText}
          {...inputProps}
        />
      )}
    </>
  );
};

export default GoogleAddressInput;
