import { Box, Grid, Typography } from "@mui/material";
import { cancelBtnStyle, gutterSpace, pxToRem } from "appConstants/styles";
import WarningIcon from "@mui/icons-material/Warning";
import Button from "components/Button";

type ConfirmationDialogPropType = {
  onClickNo: () => void;
  onClickYes: () => void;
  title?: string;
  description?: string;
  questionText?: string;
  noBtnText?: string;
  yesBtnText?: string;
};

const ConfirmationDialog = (props: ConfirmationDialogPropType) => {
  const {
    onClickNo,
    onClickYes,
    title = "Cancel Changes",
    description = "There are currently multiple updates to this record that have not been saved.",
    questionText = "",
    noBtnText = "No",
    yesBtnText = "Yes",
  } = props;

  return (
    <Box p={`${pxToRem(9)} ${pxToRem(12)} ${pxToRem(26)}`}>
      <Grid container spacing={gutterSpace}>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            sx={{ fontWeight: 600, margin: 0, color: "error.main" }}
          >
            <WarningIcon sx={{ mr: pxToRem(6.7), mb: pxToRem(-3) }} />
            {title}
          </Typography>
          <Typography
            variant="body1"
            sx={{ mt: pxToRem(5), fontSize: pxToRem(14) }}
          >
            {description}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: pxToRem(24) }}>
          <Typography variant="body1" fontWeight={500}>
            {questionText}
          </Typography>
        </Grid>
        <Grid item xs={6} mt={pxToRem(12)}>
          <Button
            variant="contained"
            combinedSx={cancelBtnStyle}
            onClick={onClickNo}
          >
            {noBtnText}
          </Button>
        </Grid>
        <Grid item xs={6} mt={pxToRem(12)}>
          <Button variant="contained" onClick={onClickYes}>
            {yesBtnText}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConfirmationDialog;
