import { Stack, SxProps, Typography } from "@mui/material";
import { pxToRem } from "appConstants";
import { Button, StyledBlinkingBadge } from "components";
import InfoIcon from "@mui/icons-material/Info";
import useActiveRecordChip from "./useActiveRecordChip";

type ActiveRecordChipPropType = {
  text?: string | React.ReactNode;
  subText?: string | React.ReactNode;
  bgColor?: string;
  bgColorLight?: string;
  textColor?: string;
  backgroundColorAnimation?: boolean;
  hideBlinkingBadge?: boolean;
  wrapperSx?: SxProps;
  textSx?: SxProps;
  onClickChange?: () => void;
};

const ActiveRecordChip: React.FC<ActiveRecordChipPropType> = (props) => {
  const {
    text: title,
    subText: subTitle,
    bgColor = "supportingColor.500",
    bgColorLight = "supportingColor.light",
    textColor,
    backgroundColorAnimation = false,
    hideBlinkingBadge = true,
    wrapperSx = {},
    textSx = {},
    onClickChange,
  } = props;

  const { personalDealership } = useActiveRecordChip();

  const text =
    title ??
    `${personalDealership?.name}${
      personalDealership?.pc ? ` - ${personalDealership?.pc}` : ""
    }`;
  // TODO: For now dealership phone no. is hidden from design as well
  // dealershipDetails?.dealershipPhones?.[0]?.phoneNumber ||

  const subText = subTitle ?? "";

  return (
    <Stack
      flex={1}
      direction={"row"}
      alignItems={"center"}
      minHeight={pxToRem(43)}
      sx={{
        p: `${pxToRem(subText ? 3 : 11.5)} ${pxToRem(17)} ${pxToRem(
          subText ? 2 : 11.5
        )}`,
        borderRadius: pxToRem(4),
        ...(!!bgColor && { backgroundColor: bgColor }),
        ...(backgroundColorAnimation && {
          animation: "colorChange 11s infinite", // 11s total duration (8800ms + 2200ms)
          "@keyframes colorChange": {
            "0%": { backgroundColor: bgColor }, // Color 1
            "80%": { backgroundColor: bgColor }, // 8800ms out of 11s
            "100%": {
              backgroundColor: bgColorLight,
            }, // Color 2 for 2200ms
          },
          transition: "background-color 0.5s ease", // Smooth transition
        }),
        ...wrapperSx,
      }}
    >
      {!hideBlinkingBadge && (
        <StyledBlinkingBadge
          badgeContent=""
          sx={{ mr: pxToRem(10), ...(subText && { marginTop: pxToRem(-16) }) }}
        />
      )}
      {subText ? (
        <Stack flex={1} color="white">
          <Typography
            variant="body2"
            fontWeight={500}
            sx={{
              ...textSx,
              ...(!!textColor && { color: textColor }),
              lineHeight: pxToRem(20),
            }}
          >
            {text}{" "}
            <InfoIcon
              sx={{
                ml: pxToRem(8),
                mb: pxToRem(-2.5),
                width: pxToRem(15),
                height: pxToRem(15),
                ...(!!textColor && { color: textColor }),
              }}
            />
          </Typography>
          <Typography
            variant="body2"
            fontWeight={500}
            letterSpacing={pxToRem(0.1)}
            sx={{
              ...(!!textColor && { color: textColor }),
              lineHeight: pxToRem(13),
            }}
          >
            {subText}
          </Typography>
        </Stack>
      ) : (
        <Typography
          fontSize={pxToRem(14)}
          letterSpacing={pxToRem(0.1)}
          fontWeight={500}
          flex={1}
          textTransform="uppercase"
          sx={{ ...textSx, ...(!!textColor && { color: textColor }) }}
        >
          {text}
        </Typography>
      )}
      {!!onClickChange && (
        <Button
          onClick={onClickChange}
          variant="text"
          sx={{
            color: textColor ?? "grey.50",
            textDecoration: "underline",
            height: pxToRem(14),
            fontSize: pxToRem(10),
            fontWeight: 400,
          }}
        >
          change
        </Button>
      )}
    </Stack>
  );
};

export default ActiveRecordChip;
