import { MutableRefObject, useEffect, useMemo, useState } from "react";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { GridEventListener } from "@mui/x-data-grid-premium";
import { MUI_GRID_COLUMNS } from "appConstants";
import useText from "utils/hooks/useText";
import { useFormikContext } from "formik";
import { GRID_SETTINGS_FIELDS } from "appConstants/FormFields";

const { SETTINGS_NAME, SAVE_AS_DEFAULT } = GRID_SETTINGS_FIELDS;

const useColumnSettingsModal = (
  gridApiRef: MutableRefObject<GridApiPremium | null>
) => {
  const [columns, setColumns] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [saveAs, setSaveAs] = useState(false);

  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const { saveAsButtonLabel, save, cancel } = useText("buttons");

  const { values, isValid, handleChange, handleSubmit } = useFormikContext();

  const visibilityModel =
    gridApiRef.current.state.columns.columnVisibilityModel;

  useEffect(() => {
    if (!gridApiRef.current) return;

    const unsubscribe = gridApiRef.current.subscribeEvent("stateChange", () => {
      setIsSaveDisabled(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    initializeColumns();
  }, [gridApiRef, searchTerm]);

  const initializeColumns = () => {
    if (gridApiRef.current) {
      const visibleColumns = gridApiRef.current
        .getAllColumns()
        ?.filter((column) => column.field !== MUI_GRID_COLUMNS.ACTION);
      setColumns(visibleColumns);

      if (!visibilityModel?.length) {
        const allColumns = gridApiRef.current
          .getAllColumns()
          .map((col) => col.field);

        const columnVisibilityModel = allColumns.reduce((acc, field) => {
          acc[field as any] = true; // Set all columns visible initially
          return acc;
        }, {});

        gridApiRef.current.setColumnVisibilityModel(columnVisibilityModel);
      }

      // INFO: Filter columns based on search term (search both headerName and field)
      const filteredColumns = visibleColumns.filter(
        (column) =>
          column.headerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          column.field.toLowerCase().includes(searchTerm.toLowerCase())
      );

      // INFO: Update columns state with filtered columns
      setColumns(filteredColumns || []);
    }
  };

  const handleToggleColumn = (field: string) => {
    // INFO: Get the latest state from the grid
    const currentState = gridApiRef.current.exportState();
    const latestVisibilityModel =
      currentState?.columns?.columnVisibilityModel || {};

    // INFO: Toggle the column visibility
    const updatedModel = {
      ...latestVisibilityModel,
      [field]: !latestVisibilityModel[field], // Toggle column
    };

    // INFO: Apply the updated visibility model
    gridApiRef.current.setColumnVisibilityModel(updatedModel);
  };

  // INFO: Handle "Show All" columns
  const handleShowAll = () => {
    const newVisibilityModel = columns.reduce((model, col) => {
      model[col.field] = true;
      return model;
    }, {});
    gridApiRef.current.setColumnVisibilityModel(newVisibilityModel);
  };

  // INFO: Handle "Hide All" columns
  const handleHideAll = () => {
    const newVisibilityModel = columns.reduce((model, col) => {
      model[col.field] = false;
      return model;
    }, {});
    gridApiRef.current.setColumnVisibilityModel(newVisibilityModel);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target?.value);
  };

  const orderChangeHandler: GridEventListener<"rowOrderChange"> = ({
    oldIndex,
    targetIndex,
    row,
  }) => {
    // INFO: Copy the current columns
    const updatedColumns = [...columns];

    // INFO: Move the column from oldIndex to targetIndex
    const [movedColumn] = updatedColumns.splice(oldIndex, 1);
    updatedColumns.splice(targetIndex, 0, movedColumn);

    // INFO: Update state with the new column order
    setColumns(updatedColumns);
    gridApiRef.current?.setColumnIndex(row?.field, targetIndex);
  };

  const toggleSaveAsInputs = () => {
    setSaveAs((prev) => !prev);
  };

  const settingsNameValue = values[SETTINGS_NAME];
  const saveAsDefaultValue = values[SAVE_AS_DEFAULT];

  const isAllVisible = Object.values(visibilityModel).every(
    (visibility) => visibility === true
  );
  const isAllHidden = Object.values(visibilityModel).every(
    (visibility) => visibility === false
  );

  return {
    saveAsButtonLabel,
    save,
    cancel,
    columns,
    searchTerm,
    isAllHidden,
    isAllVisible,
    saveAs,
    settingsNameValue,
    saveAsDefaultValue,
    isValid,
    isSaveDisabled,
    handleChange,
    handleSubmit,
    toggleSaveAsInputs,
    handleSearchChange,
    orderChangeHandler,
    handleToggleColumn,
    handleHideAll,
    handleShowAll,
  };
};

export default useColumnSettingsModal;
