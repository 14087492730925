export const QUOTE_FORM_FIELDS = {
  IS_QUOTE: "isQuote",
  DEAL_TYPE: "dealType",
  ASSET_ID: "assetId",
  STOCK_NUMBER: "stockNumber",
  ASSET_LOOKUP: "assetLookup",
  SEARCH_PARAM: "searchParam",
  CUSTOMER_LOOKUP: "customerLookup",
  SELLING_PRICE: "totalSellingPrice",
  DISCOUNT: "discount",
  TOTAL_OPTIONS: "totalOptions",
  RETAIL_AMOUNT: "totalRetailAmount",
  TOTAL_TRADE_ALLOWANCE: "totalTradeAllowance",
  TOTAL_TRADE_ACV: "tradeACV",
  TOTAL_TRADE_LIENPAYOFF: "totalTradeLienPayOff",
  MANUFACTURER_REBATE: "manufacturerRebate",
  CUSTOMER_REBATE: "customerRebate",
  TOTAL_PRODUCTS: "totalProducts",
  TAXES: "Taxes",
  FEES: "Fees",
  CASH_DOWN: "downPayment",
  DEPOSIT_AMOUNT: "depositAmount",
  TERM: "term",
  INTEREST_RATE: "interestRate",
  MONTHLY_PAYMENT: "monthlyAmount",
  DAYS_FIRST_PAYMENT: "daysToFirstPayment",
  AMOUNT_FINANCED: "amount",
  TOTAL_CASH_BALANCE: "totalCashBalance",
  TOTAL_CASH_OWED: "totalCashOwed",
  BUY_RATE: "buyRate",
  FINANCE_TRADE: "financeTrade",
  TRADE_ALLOWANCE: "amount",
  TRADE_ACV: "actualCashValue",
  FINANCE_TRADE_PAY_OFF: "financeTradePayoff",
  TOTAL_GROSS_PROFIT: "totalGrossProfit",
  WE_OWE: "weOwe",
  LOAN_FEE: "loanFee",
  COMMISSION_TOTAL: "commissionTotal",
  LIEN_HOLDER_NAME: "lienholderName",
  ATTENTION_TO: "attentionTo",
  ACCOUNT_NAME: "accountName",
  ACCOUNT_NUMBER: "accountNumber",
  PAY_OFF_AMOUNT: "amount",
  PAY_OFF_GOOD_TILL: "payOffGoodTill",
  LIEN_HOLDER_PER_DIEM: "lienholderPerDiem",
  LIEN_HOLDER_MEMO: "memo",
  LIEN_HOLDER_PHONES: "financeTradePayoffContacts",
  LIEN_HOLDER_PHONE_PREFIX: "prefix",
  LIEN_HOLDER_PHONE_NUMBER: "phone",
  LIEN_HOLDER_PHONE_EXT: "extension",
  LIEN_HOLDER_ADDRESSES: "financeTradePayoffAddresses",
  LIEN_HOLDER_ADDRESS: "address",
  LIEN_HOLDER_CITY: "city",
  LIEN_HOLDER_STATE_ID: "stateId",
  LIEN_HOLDER_POSTAL_CODE: "postalCode",
  LIEN_HOLDER_COUNTRY_ID: "countryId",
  FINANCE_EMPLOYEE: "financeEmployee",
  EMPLOYEE_ID: "employeeId",
  EMPLOYEE_NAME: "employeeName",
  JOB_TITLE_ID: "jobTitleId",
  SCENARIOS: "scenarios",
  SCENARIOS_ASSET_ID: "scenarioAssetId",
  SCENARIOS_TERM: "scenarioTerm",
  SCENARIOS_INTEREST_RATE: "scenarioInterestRate",
  SCENARIOS_CASH_DOWN: "scenarioDownPayment",
  SCENARIOS_MONTHLY_PAYMENT: "scenarioMonthlyAmount",
};

export const DEPOSIT_FORM_FIELDS = {
  DEPOSIT_DATE: "depositDate",
  PAYMENT_TYPE: "paymentType",
  DEPOSIT_AMOUNT: "depositAmount",
  PERCENTAGE: "percentage",
  COMMENT: "comment",
};

export const TRADE_ASSET_FORM_FIELDS = {
  CUSTOMER_ID: "CustomerId",
};
