import styled from "@emotion/styled";
import { Badge } from "@mui/material";
import { pxToRem, themeColorHex } from "appConstants";

const StyledBlinkingBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    backgroundColor: themeColorHex("supportingColorAlt[500]"),
    color: "white",
    animation: "blink 4s infinite ease-in-out 1s",
    height: pxToRem(6),
    minWidth: pxToRem(6),
    padding: "0",
  },
  "@keyframes blink": {
    "0%, 100%": { opacity: 1 },
    "50%": { opacity: 0 },
  },
});

export default StyledBlinkingBadge;
