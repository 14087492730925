import { useContext } from "react";
import { AppHeaderContext } from ".";

const useAppHeaderContext = () => {
  const context = useContext(AppHeaderContext);

  if (context === undefined) {
    throw new Error(
      "useAppHeaderContext must be used within a AppHeaderProvider"
    );
  }

  return context;
};

export default useAppHeaderContext;
