import { ChevronRight } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { dealershipTileStyle } from "appConstants/styles";
import { Link, LinkProps } from "react-router-dom";
import useStringInitials from "utils/hooks/useStringInitials";

type Props = {
  title: string;
  description?: string;
  location?: string;
  detailLinkProps?: LinkProps;
  handleIconClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
};

const DealerTileLabelCard = (props: Props) => {
  const { title, description, location, detailLinkProps, handleIconClick } =
    props;
  const titleInitials = useStringInitials(title);

  return (
    <Box sx={dealershipTileStyle}>
      <Box className="circle" sx={dealershipTileStyle.circle}>
        <Typography sx={dealershipTileStyle.circle.shortTitle}>
          {titleInitials}
        </Typography>
        {location && (
          <Typography sx={dealershipTileStyle.circle.locationPrefix}>
            {location}
          </Typography>
        )}
      </Box>
      <Box className="info" sx={dealershipTileStyle.title}>
        <Typography variant="h3" sx={dealershipTileStyle.title.main}>
          {title}
        </Typography>
        {description && (
          <Typography sx={dealershipTileStyle.title.description}>
            {description}
          </Typography>
        )}
      </Box>
      <Box display={"flex"} alignItems={"center"}>
        <Link
          to={"/"}
          onClick={handleIconClick}
          style={{ textDecoration: "none", color: "inherit" }}
          {...detailLinkProps}
        >
          <ChevronRight />
        </Link>
      </Box>
    </Box>
  );
};

export default DealerTileLabelCard;
