import { createSlice } from "@reduxjs/toolkit";
import { CustomerState } from "./customer.model";
import {
  associateCoBuyers,
  createBuyer,
  getBuyers,
  getCoBuyers,
  getTags,
} from "./customer.action";
import {
  createBuyerFullfilled,
  createBuyerRejected,
  getBuyersFullfilled,
  getBuyersRejected,
  getCoBuyersFullfilled,
  getCoBuyersRejected,
  getTagsRejected,
  getTagsFullfilled,
  associateCoBuyersFullfilled,
  associateCoBuyersRejected,
} from "./customer.reducer";
import { setReduxCommonPendingState } from "utils/common";

const initialState: CustomerState = {
  isLoading: false,
  successMessage: "",
  error: { title: "", description: "" },
  isSuccess: false,
  buyerTotalRecords: 0,
  coBuyerTotalRecords: 0,
  buyers: [],
  tags: [],
  createdCustomer: null,
  cobuyers: [],
};

export const customerSlice = createSlice({
  name: "customer",
  initialState: initialState,
  reducers: {
    resetSuccess: (state) => {
      state.successMessage = "";
      state.isSuccess = false;
    },
    resetError: (state) => {
      state.error.title = "";
      state.error.description = "";
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => (
    builder.addCase(getBuyers.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(getBuyers.fulfilled, (state, action) => {
      getBuyersFullfilled(state, action);
    }),
    builder.addCase(getBuyers.rejected, (state, action) => {
      getBuyersRejected(state, action);
    }),
    builder.addCase(createBuyer.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(createBuyer.fulfilled, (state, action) => {
      createBuyerFullfilled(state, action);
    }),
    builder.addCase(createBuyer.rejected, (state, action) => {
      createBuyerRejected(state, action);
    }),
    builder.addCase(getCoBuyers.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(getCoBuyers.fulfilled, (state, action) => {
      getCoBuyersFullfilled(state, action);
    }),
    builder.addCase(getCoBuyers.rejected, (state, action) => {
      getCoBuyersRejected(state, action);
    }),
    builder.addCase(getTags.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(getTags.fulfilled, (state, action) => {
      getTagsFullfilled(state, action);
    }),
    builder.addCase(getTags.rejected, (state, action) => {
      getTagsRejected(state, action);
    }),
    builder.addCase(associateCoBuyers.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(associateCoBuyers.fulfilled, (state, action) => {
      associateCoBuyersFullfilled(state, action);
    }),
    builder.addCase(associateCoBuyers.rejected, (state, action) => {
      associateCoBuyersRejected(state, action);
    })
  ),
});

export const { resetSuccess, resetError, reset } = customerSlice.actions;
