import React from "react";
import { Box, Stack, StackProps, SxProps, Typography } from "@mui/material";
import { CustomChip, ImageBox } from "components";
import { pxToRem, VehicalInfoCardStyle } from "appConstants/styles";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

interface AssetInfoCardProps extends StackProps {
  coverImg?: string;
  showCoverImg?: boolean;
  status?: string;
  title?: string;
  stockNumber?: number | string;
  vinNumber?: number | string;
  lotLocation?: string;
  customLabelChip?: React.ReactNode;
  iconButton?: React.ReactNode;
  leftPan?: React.ReactNode;
  sx?: SxProps;
  contentSx?: SxProps;
  rightPanSx?: SxProps;
}

const AssetInfoCard = (props: AssetInfoCardProps) => {
  const {
    coverImg,
    showCoverImg = false,
    status,
    title,
    stockNumber,
    vinNumber,
    lotLocation,
    customLabelChip,
    iconButton,
    leftPan,
    sx,
    contentSx,
    rightPanSx = {},
  } = props;

  return (
    <Stack
      direction={"row"}
      sx={{
        ...VehicalInfoCardStyle,
        backgroundColor: "grey.100",
        ...sx,
      }}
    >
      {leftPan && (
        <Box display={"flex"} alignItems={"center"} flexDirection={"row"}>
          {leftPan}
        </Box>
      )}
      {showCoverImg && (
        <Box>
          <ImageBox
            coverImg={coverImg}
            width={pxToRem(43)}
            height={pxToRem(43)}
            borderRadius={pxToRem(4)}
            sx={{ color: "grey.50" }}
          />
        </Box>
      )}
      <Box flex={1} sx={contentSx} minWidth={"67%"}>
        {status && (
          <Typography className="status">
            <CheckCircleRoundedIcon sx={{ mr: pxToRem(4) }} /> {status}
          </Typography>
        )}
        <Typography className="title">{title}</Typography>
        {stockNumber && <Typography>Stock#: {stockNumber}</Typography>}
        {vinNumber && <Typography>VIN#: {vinNumber}</Typography>}
        {lotLocation && (
          <CustomChip
            label={lotLocation}
            sx={{
              backgroundColor: "supportingColorAlt.A200",
              color: "grey.A400",
            }}
          />
        )}
      </Box>
      <Box
        {...(iconButton && customLabelChip ? { flex: 1 } : {})}
        display={"flex"}
        alignItems={iconButton ? "center" : "flex-end"}
        flexDirection={iconButton && !customLabelChip ? "row-reverse" : "row"}
        sx={rightPanSx}
      >
        {customLabelChip}
        {iconButton}
      </Box>
    </Stack>
  );
};

export default AssetInfoCard;
