import { MutableRefObject, useEffect, useMemo, useRef } from "react";
import { MenuObjectType } from "types";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import SaveIcon from "@mui/icons-material/Save";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import RestoreIcon from "@mui/icons-material/Restore";
import SelectWrapper from "components/SelectWrapper";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import useModal from "utils/hooks/useModal";
import { useFormik } from "formik";
import { initialValues } from "./gridSettingsFormConfig";
import { GridSettingsSchema } from "utils/validationSchemas/gridSettingsValidationSchema";
import { GRID_SETTINGS_FIELDS } from "appConstants/FormFields";
import { GRID_TYPE as GRID_TYPE_ENUM, pxToRem } from "appConstants";
import {
  getAllGridSettings,
  saveGridSetting,
} from "redux/slices/gridSettings/gridSettings.action";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import {
  setGridCurrentSetting,
  setGridDefaultSetting,
} from "redux/slices/gridSettings/gridSettings.slice";

const {
  COLUMN_SETTINGS,
  SORT_SETTINGS,
  FILTER_SETTINGS,
  GRID_TYPE,
  IS_GLOBAL,
} = GRID_SETTINGS_FIELDS;

const useGridSettingsPopover = (
  gridApiRef: MutableRefObject<GridApiPremium | null>,
  gridType: GRID_TYPE_ENUM
) => {
  const { isOpen, toggleModal } = useModal();
  const {
    isOpen: isSaveGridSettingsOpen,
    toggleModal: toggleSaveGridSettingsModal,
  } = useModal();
  const {
    isOpen: isSavedGridSettingsOpen,
    toggleModal: toggleSavedGridSettingsModal,
  } = useModal();

  const dispatch = useDispatch<AppDispatch>();

  const { settings, defaultSetting, currentSetting } = useSelector(
    (state: RootState) => state.gridSettings
  );

  const currentWidthsMemoized = useMemo(() => {
    if (!gridApiRef?.current?.state) {
      return;
    }

    return gridApiRef?.current?.getAllColumns().reduce((acc, column) => {
      acc[column.field] = column.computedWidth;
      return acc;
    }, {} as Record<string, number>);
  }, [gridApiRef?.current?.state]);

  const initialColumnState = useRef<{
    order: string[];
    widths: { [key: string]: number };
  }>({
    order: [],
    widths: {},
  });

  useEffect(() => {
    if (!gridApiRef?.current?.state) {
      return;
    }

    storeColumnsInitialState();

    if (!defaultSetting?.[gridType]) {
      storeGridDefaultSettings();
    }
  }, [gridApiRef?.current?.state]);

  const storeGridDefaultSettings = () => {
    const gridSettings = {
      [FILTER_SETTINGS]: JSON.stringify(
        gridApiRef.current.state.filter.filterModel || {}
      ),
      [SORT_SETTINGS]: JSON.stringify(
        gridApiRef.current.state.sorting?.sortModel || []
      ),
      [COLUMN_SETTINGS]: JSON.stringify({
        columnVisibilityModel:
          gridApiRef.current.state.columns.columnVisibilityModel || {},
        columnOrder: gridApiRef.current.getAllColumns().map((col) => col.field),
        columnWidths: currentWidthsMemoized,
      }),
      [GRID_TYPE]: gridType,
      [IS_GLOBAL]: false,
    };

    dispatch(
      setGridDefaultSetting({ ...defaultSetting, [gridType]: gridSettings })
    );
  };

  const storeColumnsInitialState = () => {
    if (!initialColumnState.current?.order?.length) {
      const allColumns = gridApiRef.current.getAllColumns();

      // INFO: Store column order
      initialColumnState.current.order = allColumns.map((col) => col.field);

      // INFO: Store column widths
      allColumns.forEach((col) => {
        initialColumnState.current.widths[col.field] = col.computedWidth || 100;
      });
    }
  };

  // INFO: Get the columns width only which are changed
  const getChangedColumnWidths = () => {
    if (!gridApiRef?.current?.state) return {};

    const changedWidths = Object.entries(currentWidthsMemoized).reduce(
      (acc, [field, width]) => {
        if (initialColumnState.current?.widths[field] !== width) {
          acc[field] = width;
        }
        return acc;
      },
      {} as Record<string, number>
    );

    return changedWidths;
  };

  const handleGridSettingSelection = (event) => {
    const value = event.target.value;
    if (settings.length) {
      const setting = settings.find((setting) => setting.id === Number(value));

      if (setting) {
        dispatch(
          setGridCurrentSetting({ ...currentSetting, [gridType]: setting })
        );
        if (isSavedGridSettingsOpen) {
          toggleSavedGridSettingsModal();
        }
      }
    }
  };

  // INFO: Handle export data
  const handleExport = () => {
    if (gridApiRef.current) {
      const gridState = gridApiRef.current.exportState();
      const columnConfig = gridState.columns?.orderedFields || [];
      const sortingConfig = gridState.sorting || {};
      const filterConfig = gridState.filter || {};
      const visibilityModel = gridState.columns?.columnVisibilityModel || {};
      const columnWidths = getChangedColumnWidths();

      // INFO: Prepare CSV content
      let csvContent =
        "Column, Sorting, Filter Operator, Filter Value, Visibility, Column Width\n";

      columnConfig.forEach((col) => {
        const sorting = sortingConfig.sortModel?.find((s) => s.field === col);

        const filteringItem = filterConfig.filterModel?.items?.find(
          (f) => f.field === col
        );
        const filterOperator = filteringItem ? filteringItem.operator : "None";
        const filterValue = filteringItem?.value ?? "None";

        const visibility =
          visibilityModel[col] !== undefined ? visibilityModel[col] : true; // INFO: Default to visible true if not in model

        const columnWidth = columnWidths[col] || "Default";

        csvContent += `${col}, ${
          sorting ? sorting.sort : "None"
        }, ${filterOperator}, ${filterValue}, ${visibility}, ${columnWidth}\n`;
      });

      // INFO: Download CSV file
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
      link.download = `grid-config-${gridType}-${timestamp}.csv`;
      link.click();
    }
  };

  const resetGridSettings = () => {
    if (gridApiRef?.current) {
      gridApiRef.current.setColumnVisibilityModel({});
      gridApiRef.current.setSortModel([]);
      gridApiRef.current.setFilterModel({ items: [] });

      const parsedColumnSettings = JSON.parse(
        defaultSetting?.[gridType]?.columnSettings
      );

      const columnDimensions = {};

      Object.entries(parsedColumnSettings?.columnWidths).forEach(
        ([field, width]) => {
          columnDimensions[field] = { width };
        }
      );

      gridApiRef?.current?.restoreState({
        columns: {
          orderedFields: parsedColumnSettings?.columnOrder, // INFO: Reset columns order
          dimensions: columnDimensions, // INFO: Reset column dimensions
        },
      });

      dispatch(setGridCurrentSetting({ ...currentSetting, [gridType]: null }));
    }
  };

  const menuData: MenuObjectType = {
    1: {
      component: (
        <SelectWrapper
          label="Current Grid"
          items={settings}
          itemLabelField="viewName"
          itemValueField="id"
          value={currentSetting?.[gridType]?.id}
          onChange={handleGridSettingSelection}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: pxToRem(184),
                overflowY: "auto",
              },
            },
          }}
        />
      ),
    },
    2: {
      divider: true,
    },
    3: {
      label: "Manage Grid",
      icon: <ViewColumnIcon />,
      handleClick: toggleModal,
    },
    4: {
      label: "Save Grid",
      icon: <SaveIcon />,
      handleClick: toggleSaveGridSettingsModal,
    },
    5: {
      label: "View Saved Grids",
      icon: <ListAltIcon />,
      handleClick: toggleSavedGridSettingsModal,
    },
    6: {
      divider: true,
    },
    7: {
      label: "Export Grid",
      icon: <DownloadRoundedIcon />,
      handleClick: handleExport,
    },
    8: {
      label: "Reset Grid",
      icon: <RestoreIcon />,
      handleClick: resetGridSettings,
    },
  };

  const gridSettingsForm = useFormik({
    initialValues: initialValues,
    validationSchema: GridSettingsSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      const settingsPayload = {
        ...values,
        [FILTER_SETTINGS]: JSON.stringify(
          gridApiRef.current.state.filter.filterModel || {}
        ),
        [SORT_SETTINGS]: JSON.stringify(
          gridApiRef.current.state.sorting?.sortModel || []
        ),
        [COLUMN_SETTINGS]: JSON.stringify({
          columnVisibilityModel:
            gridApiRef.current.state.columns.columnVisibilityModel || {},
          columnOrder: gridApiRef.current
            .getAllColumns()
            .map((col) => col.field),
          columnWidths: getChangedColumnWidths(),
        }),
        [GRID_TYPE]: gridType,
        [IS_GLOBAL]: false,
      };

      dispatch(saveGridSetting(settingsPayload as any)).then((res) => {
        if (res.payload) {
          dispatch(
            setGridCurrentSetting({
              ...currentSetting,
              [gridType]: res.payload,
            })
          );
          dispatch(getAllGridSettings({ gridType }));
          resetForm();

          if (isOpen) {
            toggleModal();
          } else {
            toggleSaveGridSettingsModal();
          }
        }
      });
    },
  });

  const resetForm = () => {
    gridSettingsForm.resetForm();
  };

  const showSelectionIndicator =
    currentSetting?.[gridType] && !currentSetting?.[gridType]?.saveAsDefault;

  return {
    menuData,
    isOpen,
    isSaveGridSettingsOpen,
    isSavedGridSettingsOpen,
    gridSettingsForm,
    showSelectionIndicator,
    toggleModal,
    toggleSaveGridSettingsModal,
    toggleSavedGridSettingsModal,
    handleGridSettingSelection,
  };
};

export default useGridSettingsPopover;
