import { memo } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { Autocomplete, Checkbox, Popper, TextFieldProps } from "@mui/material";
import Input from "components/Input";
import useLocationTreeview from "./useLocationTreeview";
import styled from "@emotion/styled";

export type LocationTreeViewPropType = {
  dataSource?: any;
  selectedLocationNodeId?: number;
  placeholder?: string;
  textFieldProps?: TextFieldProps;
  isPreFilled?: boolean;
  sendLocation?: (nodeId: number | string) => void;
};

const LocationTreeView = (props: LocationTreeViewPropType) => {
  const tenantName = props.dataSource?.row.tenantName || "";
  const { placeholder, textFieldProps = {} } = props;

  const {
    locationLabel,
    nodeIdFinal,
    selectedNames,
    locationsData,
    searchTerm,
    showSelectedValue,
    handleInputFocus,
    searchFirstTierChildren,
    setSearchTerm,
    handleExpandClick,
    handleNodeSelectWithEvent,
  } = useLocationTreeview(props);

  const selectedLocation =
    selectedNames?.length === 0 ? tenantName : selectedNames.join(" | ");

  const StyledPopper = styled(Popper)(({ theme }) => ({
    ".MuiPaper-root": {
      width: "100% !important", // Ensure the popper takes full width of the input
      maxWidth: "unset",
    },
  }));

  const MemoizedTreeItem = memo(({ node }: { node: any }) => (
    <TreeItem
      key={node.id}
      nodeId={node.id.toString()}
      onClick={handleExpandClick}
      label={
        <>
          <Checkbox
            checked={node?.id === nodeIdFinal}
            tabIndex={-1}
            disableRipple
            onClick={(event) => handleNodeSelectWithEvent(event, node?.id)}
          />
          {node.tenantName}
        </>
      }
    >
      {Array.isArray(node.children)
        ? node.children.map((node: any) => (
            <MemoizedTreeItem key={node.id} node={node} />
          ))
        : null}
    </TreeItem>
  ));

  return (
    <Autocomplete
      disablePortal
      id="location-search-autocomplete"
      options={locationsData}
      PopperComponent={StyledPopper}
      onBlur={() => handleInputFocus(true)}
      onFocus={() => handleInputFocus(false)}
      sx={{
        ".MuiInputBase-root": {
          height: "43px",
          padding: "0 1rem 0.25rem 0",
        },
      }}
      renderInput={(params) => (
        <Input
          {...params}
          fullWidth={true}
          label={
            !showSelectedValue && selectedLocation
              ? selectedLocation
              : placeholder ?? locationLabel
          }
          name="location"
          id="basic-button"
          onChange={(e) => setSearchTerm(e.target.value)}
          {...textFieldProps}
        />
      )}
      value={searchTerm}
      inputValue={showSelectedValue ? selectedLocation : searchTerm}
      filterOptions={(options) => searchFirstTierChildren(options, searchTerm)}
      getOptionLabel={(option) => option.tenantFullName || ""}
      renderOption={(props, option) => (
        <TreeView
          defaultCollapseIcon={<ArrowDropUpIcon />}
          defaultExpandIcon={<ArrowDropDownIcon />}
        >
          <MemoizedTreeItem key={option.id} node={option} />
        </TreeView>
      )}
    />
  );
};

export default LocationTreeView;
