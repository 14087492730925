import { Box, SxProps } from "@mui/material";
import { topIndicatorBar } from "appConstants/styles";

interface TopIndicatorType {
  backgroundColor: string;
  sx?: SxProps;
}
const TopIndicator = ({ backgroundColor, sx }: TopIndicatorType) => {
  return <Box sx={{ backgroundColor, ...topIndicatorBar, ...sx }}></Box>;
};

export default TopIndicator;
