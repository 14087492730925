import SvgIcon from "@mui/material/SvgIcon";

const DealerShipIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="25"
        height="20"
        viewBox="0 0 25 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.47787 7.99983H0.5L6.99386 12.4097L4.53103 19.9996L11.0249 15.3097L17.5293 19.9996L15.0559 12.4097L21.5498 7.99983H13.5719L11.0249 0L8.47787 7.99983ZM21.9289 20.0004L19.9713 13.9905L24.3707 11.0006H20.7501L17.5084 13.2005L19.0451 17.9204L21.9289 20.0004ZM15.4242 0L17.3398 5.99987H15.1401L14.3297 3.44992L15.4242 0Z" />
      </svg>
    </SvgIcon>
  );
};

export default DealerShipIcon;
