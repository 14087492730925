import { pageTitleByRoute } from "appConstants";
import { useLocation } from "react-router-dom";
import { LocationType } from "types";

const useTitleBar = () => {
  const location = useLocation<LocationType>();
  const currentRoute = location.pathname;

  return {
    parentTitle: pageTitleByRoute[currentRoute]?.parentTitle,
    pageTitle: pageTitleByRoute[currentRoute]?.pageTitle,
  };
};

export default useTitleBar;
