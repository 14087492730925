export const resetPasswordFulfilled = (state, action) => {
  state.isLoading = false;
  state.success = action.payload;
};

export const resetPasswordRejected = (state, action) => {
  state.isLoading = false;
  state.error.title = action.error.name;
  state.error.description = action.error.message;
};
