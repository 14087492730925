export const CUSTOMER_FORM_FIELDS = {
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  EMAIL: "email",
  PHONES: "phones",
  PHONE_NUMBER: "shortPhone",
  PHONE_PREFIX: "countryPrefix",
  PHONE_COUNTRY: "country",
  PHONE_EXTENSION: "phoneExtension",
  PHONE_TYPE: "phoneType",
  ADDRESS: "address",
  CITY: "city",
  STATE_NAME: "state",
  COUNTRY_NAME: "country",
  ZIP: "zip",
  FACEBOOK_URL: "facebookUrl",
  TWITTER_URL: "twitterUrl",
  INSTAGRAM_URL: "instagramUrl",
  LINKEDIN: "linkedIn",
  CONFIDENCE_LEVEL: "confidenceLevel",
  ROLE: "role",
  VALUE: "value",
  NOTE: "note",
  TAGS: "tags",
  ASSOCIATION_ID: "associationId",
};
