import { createSlice } from "@reduxjs/toolkit";
import { PurchasingState } from "./purchasing.model";
import {
  createPurchaseOrder,
  getAssetTemplates,
  getPurchaseOrders,
  getAssetTemplateById,
  getPurchaseOrderById,
  getAssetTemplateHistoryById,
  getPurchaseOrderHistoryById,
  editPurchaseOrder,
  getAllPurchaseOrderStatuses,
} from "./purchasing.action";
import {
  assetTemplateByIdFulfilled,
  assetTemplateByIdRejected,
  assetTemplateFulfilled,
  assetTemplateHistoryByIdFulfilled,
  assetTemplateHistoryByIdRejected,
  assetTemplateRejected,
  createPurchaseOrderFulfilled,
  createPurchaseOrderRejected,
  editPurchaseOrderFulfilled,
  editPurchaseOrderRejected,
  purchaseOrderByIdFulfilled,
  purchaseOrderByIdRejected,
  purchaseOrderHistoryByIdFulfilled,
  purchaseOrderHistoryByIdRejected,
  purchaseOrdersFulfilled,
  purchaseOrdersRejected,
  viewPurchaseOrderStatusesFulfilled,
} from "./purchasing.reducer";

const initialState: PurchasingState = {
  isLoading: false,
  error: { title: "", description: "" },
  isSuccess: false,
  assetTemplates: [],
  purchaseOrders: [],
  purchaseOrderStatuses: [],
  createPurchaseOrderIsLoading: false,
  createPurchaseOrderIsSuccess: false,
  assetTemplate: null,
  purchaseOrder: null,
  assetTemplateHistory: null,
  purchaseOrderHistory: null,
  totalRecordsPurchasing: null,
  totalRecordsTemplates: null,
  successMessage: "",
};

export const purchasingSlice = createSlice({
  name: "purchasing",
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    resetError: (state) => {
      state.error.title = "";
      state.error.description = "";
    },
    resetSuccess: (state) => {
      state.isSuccess = false;
      state.createPurchaseOrderIsSuccess = false;
      state.successMessage = "";
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
  },
  extraReducers: (builder) => (
    builder.addCase(getAssetTemplates.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.assetTemplates = [];
      state.assetTemplate = null;
      state.successMessage = "";
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getAssetTemplates.fulfilled, (state, action) => {
      assetTemplateFulfilled(state, action);
    }),
    builder.addCase(getAssetTemplates.rejected, (state, action) => {
      assetTemplateRejected(state, action);
    }),
    builder.addCase(getAssetTemplateById.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getAssetTemplateById.fulfilled, (state, action) => {
      assetTemplateByIdFulfilled(state, action);
    }),
    builder.addCase(getAssetTemplateById.rejected, (state, action) => {
      assetTemplateByIdRejected(state, action);
    }),
    builder.addCase(getPurchaseOrders.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.successMessage = "";
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getPurchaseOrders.fulfilled, (state, action) => {
      purchaseOrdersFulfilled(state, action);
    }),
    builder.addCase(getPurchaseOrders.rejected, (state, action) => {
      purchaseOrdersRejected(state, action);
    }),
    builder.addCase(createPurchaseOrder.pending, (state, action) => {
      state.createPurchaseOrderIsLoading = true;
      state.createPurchaseOrderIsSuccess = false;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(createPurchaseOrder.fulfilled, (state, action) => {
      createPurchaseOrderFulfilled(state, action);
    }),
    builder.addCase(createPurchaseOrder.rejected, (state, action) => {
      createPurchaseOrderRejected(state, action);
    }),
    builder.addCase(editPurchaseOrder.pending, (state, action) => {
      state.createPurchaseOrderIsLoading = true;
      state.createPurchaseOrderIsSuccess = false;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(editPurchaseOrder.fulfilled, (state, action) => {
      editPurchaseOrderFulfilled(state, action);
    }),
    builder.addCase(editPurchaseOrder.rejected, (state, action) => {
      editPurchaseOrderRejected(state, action);
    }),
    builder.addCase(getPurchaseOrderById.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getPurchaseOrderById.fulfilled, (state, action) => {
      purchaseOrderByIdFulfilled(state, action);
    }),
    builder.addCase(getPurchaseOrderById.rejected, (state, action) => {
      purchaseOrderByIdRejected(state, action);
    }),
    builder.addCase(getAssetTemplateHistoryById.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getAssetTemplateHistoryById.fulfilled, (state, action) => {
      assetTemplateHistoryByIdFulfilled(state, action);
    }),
    builder.addCase(getAssetTemplateHistoryById.rejected, (state, action) => {
      assetTemplateHistoryByIdRejected(state, action);
    }),
    builder.addCase(getPurchaseOrderHistoryById.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getPurchaseOrderHistoryById.fulfilled, (state, action) => {
      purchaseOrderHistoryByIdFulfilled(state, action);
    }),
    builder.addCase(getPurchaseOrderHistoryById.rejected, (state, action) => {
      purchaseOrderHistoryByIdRejected(state, action);
    }),
    builder.addCase(getAllPurchaseOrderStatuses.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.successMessage = "";
    }),
    builder.addCase(getAllPurchaseOrderStatuses.fulfilled, (state, action) => {
      viewPurchaseOrderStatusesFulfilled(state, action);
    }),
    builder.addCase(getAllPurchaseOrderStatuses.rejected, (state, action) => {
      purchaseOrdersRejected(state, action);
    })
  ),
});

export const { reset, resetError, resetSuccess, setSuccessMessage } =
  purchasingSlice.actions;
