export const assetsFulfilled = (state, action) => {
  state.isLoading = false;
  state.assets = action.payload?.records;
  state.isAssetListLoading = false;
  state.totalRecords = action.payload?.totalRecords;
};

export const assetsRejected = (state, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const viewAssetFulfilled = (state, action) => {
  state.isLoading = false;
  state.asset = action.payload;
};

export const viewAssetRejected = (state, action) => {
  const { name, message } = action.error;
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};

export const uploadAssetBulkImportFileFulfilled = (state, action) => {
  state.isLoading = false;
  state.isImportSuccess = true;
};

export const uploadAssetBulkImportFileRejected = (state, action) => {
  const { name, message } = action.error;
  state.isImportSuccess = false;
  state.error.title = name;
  state.error.description = message;
  state.isLoading = false;
};

export const createAssetFulfilled = (state, action) => {
  state.isLoading = false;
  state.isSuccess = true;
  state.isAssetTemplate = action.payload.isTemplate;
  state.successMessage = action.payload.message;
};

export const editAssetFulfilled = (state, action) => {
  state.isLoading = false;
  state.isSuccess = true;
  state.isAssetTemplate = action.payload.isTemplate;
  state.successMessage = action.payload.message;
};

export const creatTransactionFulfilled = (state, action) => {
  state.isLoading = false;
  state.isSuccess = true;
};

export const AssetRejected = (state, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};
export const TransactionRejected = (state, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const infoAndPricingOptionsFulfilled = (state, action) => {
  const [floorLayouts, subFloorPlans] = action.payload;
  state.isLoading = false;
  state.floorLayouts = floorLayouts;
  state.subFloorPlans = subFloorPlans;
};

export const viewTitleStatusesFulfilled = (state, action) => {
  state.isLoading = false;
  state.titleStatuses = action.payload;
};

export const viewCylindersFulfilled = (state, action) => {
  state.isLoading = false;
  state.cylinders = action.payload;
};
export const viewTransmissionsFulfilled = (state, action) => {
  state.isLoading = false;
  state.transmissions = action.payload;
};
export const viewFuelFulfilled = (state, action) => {
  state.isLoading = false;
  state.fuels = action.payload;
};
export const viewSellingPriceCategoryFulfilled = (state, action) => {
  state.isLoading = false;
  state.sellingPriceCategory = action.payload;
};
export const viewFloorPlanCompanyFulfilled = (state, action) => {
  state.isLoading = false;
  state.floorPlanCompany = action.payload;
};
export const viewAccessoriesFulfilled = (state, action) => {
  state.isLoading = false;
  state.accessories = action.payload;
};
export const viewAllWarrantyStatusFulfilled = (state, action) => {
  state.isLoading = false;
  state.warrantyStatus = action.payload;
};

export const viewAllYearsByTitleStatusFulfilled = (state, action) => {
  state.isLoading = false;
  state.unitYears = action.payload;
};

export const viewAllAssetTypesByYearFulfilled = (state, action) => {
  state.isLoading = false;
  state.assetTypes = action.payload;
};

export const viewAllManufacturersByAssetTypeIdFulfilled = (state, action) => {
  state.isLoading = false;
  state.manufacturers = action.payload;
};

export const viewAllManufacturersFulfilled = (state, action) => {
  state.isLoading = false;
  state.manufacturers = action.payload;
};

export const viewAllDivisionsByManufacturerIdFulfilled = (state, action) => {
  state.isLoading = false;
  state.divisions = action.payload;
};

export const viewAllBrandsByDivisionIdFulfilled = (state, action) => {
  state.isLoading = false;
  state.unitBrands = action.payload;
};

export const viewAllSeriesByBrandIdFulfilled = (state, action) => {
  state.isLoading = false;
  state.series = action.payload;
};

export const viewAllModelsBySeriesIdFulfilled = (state, action) => {
  state.isLoading = false;
  state.unitModels = action.payload;
};

export const viewAllSubseriesBySeriesIdFulfilled = (state, action) => {
  state.isLoading = false;
  state.subSeries = action.payload;
};

export const viewAllModelsBySubseriesIdFulfilled = (state, action) => {
  state.isLoading = false;
  state.unitModels = action.payload;
};

export const viewAllModelsByBrandIdFulfilled = (state, action) => {
  state.isLoading = false;
  state.unitModels = action.payload;
};

export const viewAllUnitStatusesFulfilled = (state, action) => {
  state.isLoading = false;
  state.unitStatuses = action.payload;
};

export const viewAllUnitTypesFulfilled = (state, action) => {
  state.isLoading = false;
  state.unitTypes = action.payload;
};

export const viewAllBodyStylesFulfilled = (state, action) => {
  state.isLoading = false;
  state.bodyStyles = action.payload;
};

export const viewAllProductGeneralManagersFulfilled = (state, action) => {
  state.isLoading = false;
  state.productGeneralManagers = action.payload;
};

export const viewAllDivisionsFulfilled = (state, action) => {
  state.isLoading = false;
  state.allDivisions = action.payload;
};

export const viewAllFloorPlanStatusesFulfilled = (state, action) => {
  state.isLoading = false;
  state.floorPlanStatuses = action.payload;
};

export const viewAllDivisionAllocationsFulfilled = (state, action) => {
  state.isLoading = false;
  state.divisionAllocations = action.payload;
};

export const viewAllProfitCentersFulfilled = (state, action) => {
  state.isLoading = false;
  state.profitCenters = action.payload;
};

export const viewAllLotsByDealershipIdFulfilled = (state, action) => {
  state.isLoading = false;
  state.dealershipLots = action.payload;
};

export const viewAllLotLocationByLotIdFulfilled = (state, action) => {
  state.isLoading = false;
  state.lotLocations = action.payload;
};

export const viewAllTransportersFulfilled = (state, action) => {
  state.isLoading = false;
  state.transporters = action.payload;
};

export const viewAllUnlinkedItemsFulfilled = (state, action) => {
  const [
    bodyStyles,
    profitCenters,
    titleStatuses,
    unitStatuses,
    unitTypes,
    assetTypes,
    manufacturers,
  ] = action.payload;
  state.bodyStyles = bodyStyles;
  state.profitCenters = profitCenters;
  state.titleStatuses = titleStatuses;
  state.unitStatuses = unitStatuses;
  state.unitTypes = unitTypes;
  state.assetTypes = assetTypes;
  state.manufacturers = manufacturers;
  state.isLoading = false;
};

export const getAllTransactionsFulfilled = (state, action) => {
  state.isLoading = false;
  state.transactions = action.payload || [];
};

export const getAllTransactionsRejected = (state, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const getAssetHistoryDetailsFulfilled = (state, action) => {
  state.isLoading = false;
  state.assetHistoryDetails = action.payload.records;
};

export const getAssetHistoryDetailsRejected = (state, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};
export const getGLAccountsFulfilled = (state, action) => {
  state.isLoading = false;
  state.GLAccounts = action.payload;
};

export const getGLAccountsRejected = (state, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const getAssetHistoryFulfilled = (state, action) => {
  state.isLoading = false;
  state.assetHistory = action.payload;
};

export const getAssetHistoryRejected = (state, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const getTransactionsByRefFulfilled = (state, action) => {
  state.isLoading = false;
  state.transactionsByRef = action.payload;
};

export const getAllTransactionsByRefRejected = (state, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const updateTransactionPostingFulfilled = (state, action) => {
  state.isLoading = false;
  state.createEditIsSuccess = true;
  //TODO: Need to update this with response success message, Once BE provide it in one format.
  state.editSuccessMessage = "Transaction Posting Update Successfully";
};

export const updateTransactionPostingRejected = (state, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.createEditIsSuccess = false;
  state.error.title = name;
  state.error.description = message;
};

export const assetByStockNumberFulfilled = (state, action) => {
  state.isLoading = false;
  state.assetByStockNumber = action.payload;
};

export const assetByStockNumberRejected = (state, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};

export const createDuplicateAssetFulfilled = (state, action) => {
  state.isLoading = false;
  state.isSuccess = true;
  state.successMessage = action.payload;
};

export const suggestedAssetsFulfilled = (state, action) => {
  state.isLoading = false;
  state.suggestedAssets = action.payload;
};

export const suggestedAssetsRejected = (state, action) => {
  const { name, message } = action.error;
  state.isLoading = false;
  state.error.title = name;
  state.error.description = message;
};
