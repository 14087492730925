import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useJsApiLoader } from "@react-google-maps/api";
import {
  GOOGLE_ADDRESS_LIBRARIES,
  GOOGLE_ADDRESS_TYPE,
} from "appConstants/index";
import { AppDispatch, RootState } from "redux/store";
import { resetStates } from "redux/slices/personnel/personnel.slice"; // Adjust path as needed
import { getStatesByCountry } from "redux/slices/personnel/personnel.action";
import { State } from "types";

type UseGooglePlacesAPIProps = {
  setFieldValue: (field: string, value: any) => void;
  addressFields: {
    streetAddress: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
};

const useGooglePlacesAPI = ({
  setFieldValue,
  addressFields,
}: UseGooglePlacesAPIProps) => {
  const { streetAddress, city, state, postalCode, country } = addressFields;

  const [selectedState, setSelectedState] = useState<string | null>(null);

  const dispatch = useDispatch<AppDispatch>();
  const { countries, states } = useSelector(
    (state: RootState) => state.personnel
  );

  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
    libraries: GOOGLE_ADDRESS_LIBRARIES,
  });

  const handlePlaceChanged = useCallback(() => {
    // INFO: autocompleteRefs is for multiple entries of address
    if (autocompleteRef?.current) {
      const place = autocompleteRef.current?.getPlace();

      if (place && place.address_components) {
        const addressComponents = place.address_components;
        let address = "";
        let countryId = null;

        addressComponents.forEach((component) => {
          const types = component.types;

          if (types.includes(GOOGLE_ADDRESS_TYPE.STREET_NUMBER)) {
            address = component.long_name;
          } else if (types.includes(GOOGLE_ADDRESS_TYPE.ROUTE)) {
            address += ` ${component.long_name}`;
          } else if (types.includes(GOOGLE_ADDRESS_TYPE.POSTAL_CODE)) {
            setFieldValue(postalCode, component.long_name);
          } else if (types.includes(GOOGLE_ADDRESS_TYPE.LOCALITY)) {
            setFieldValue(city, component.long_name);
          } else if (types.includes(GOOGLE_ADDRESS_TYPE.COUNTRY)) {
            dispatch(resetStates());
            countryId = countries.find(
              (c) => c.shortName === component.short_name
            )?.id;

            if (countryId !== undefined) {
              setFieldValue(country, countryId);
              dispatch(getStatesByCountry({ id: countryId }));
            }
          } else if (
            types.includes(GOOGLE_ADDRESS_TYPE.ADMINISTRATIVE_AREA_LEVEL_1)
          ) {
            setSelectedState(component.short_name);
          }
        });

        setFieldValue(streetAddress, address);
      }
    }
  }, [
    dispatch,
    countries,
    setFieldValue,
    streetAddress,
    city,
    postalCode,
    country,
  ]);

  useEffect(() => {
    if (selectedState) {
      const stateId = states.find(
        (item: State) => item.shortName === selectedState
      )?.id;

      if (stateId !== undefined) {
        setFieldValue(state, stateId);
      }
    }
  }, [states, selectedState]);

  return { isLoaded, autocompleteRef, handlePlaceChanged };
};

export default useGooglePlacesAPI;
