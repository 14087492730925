import React, { lazy, Suspense } from "react";
import { RotateRight } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";
import { themeColorHex, hexToRGBA, statusFlagStyle } from "appConstants/styles";
import { CommonInventoryStatusType } from "types";
import { inventoryStatus } from "appConstants";

type Props = {
  flagStatus?: number;
  showTooltip?: true;
  iconWrapper?: React.FC;
  sx?: object;
} & CommonInventoryStatusType;

const StatusFlag = (props: Props) => {
  const {
    flagStatus,
    label = inventoryStatus[flagStatus]?.label,
    iconWrapper: LazyIcon = inventoryStatus[flagStatus]?.icon,
    color = themeColorHex(`${inventoryStatus[flagStatus]?.color}`) ||
      "grey[50]",
    bgColor = themeColorHex(`${inventoryStatus[flagStatus]?.bgColor}`) || color,
    bgOpacity = inventoryStatus[flagStatus]?.bgOpacity || 0.3,
    sx,
  } = props;

  const extendedStyles = {
    backgroundColor: hexToRGBA(`${bgColor}`, bgOpacity),
    borderColor: color,
    color: color,
  };

  return (
    <Tooltip title={label}>
      <Box sx={{ ...statusFlagStyle, ...extendedStyles, ...sx }}>
        <Suspense fallback={<RotateRight />}>
          {LazyIcon ? <LazyIcon /> : null}
        </Suspense>
      </Box>
    </Tooltip>
  );
};

export default StatusFlag;
