import { useSelector } from "react-redux";
import { RootState } from "redux/store";

const useActiveRecordChip = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);

  return { personalDealership: userInfo?.personalDealership };
};

export default useActiveRecordChip;
