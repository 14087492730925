import { interestSuccessMessages } from "appConstants";
import { InterestState } from "./interest.model";

export const getInterestsFulfilled = (state: InterestState, action) => {
  state.isLoading = false;
  state.interest = null;
  state.interests = action.payload.records;
  state.totalRecords = action.payload?.totalRecords;
};

export const getInterestsRejected = (state: InterestState, action) => {
  state.isLoading = false;
  state.error.title = action.error.name;
  state.error.description = action.error.message;
};

export const getInterestByIdFulfilled = (state: InterestState, action) => {
  state.isLoading = false;
  state.interest = action.payload;
};

export const getInterestByIdRejected = (state: InterestState, action) => {
  state.isLoading = false;
  state.error.title = action.error.name;
  state.error.description = action.error.message;
};

export const createInterestFulfilled = (state: InterestState, action) => {
  state.isLoading = false;
  state.isSuccess = true;
  state.successMessage = interestSuccessMessages.interestCreated;
};

export const createInterestRejected = (state: InterestState, action) => {
  state.isLoading = false;
  state.error.title = action.error.name;
  state.error.description = action.error.message;
};

export const editInterestFulfilled = (state: InterestState, action) => {
  state.isLoading = false;
  state.isSuccess = true;
  state.successMessage = interestSuccessMessages.interestUpdated;
};

export const editInterestRejected = (state: InterestState, action) => {
  state.isLoading = false;
  state.error.title = action.error.name;
  state.error.description = action.error.message;
};

export const getCustomerInterestsFulfilled = (state: InterestState, action) => {
  state.isLoading = false;
  state.customerInterests = action.payload;
};

export const getCustomerInterestsRejected = (state: InterestState, action) => {
  state.isLoading = false;
  state.error.title = action.error.name;
  state.error.description = action.error.message;
};
