import { Grid } from "@mui/material";
import { gutterSpace } from "appConstants/styles";

type NoDataMessagePropType = {
  messageContent?: string;
  messageSubject?: string;
};

const NoDataMessage = ({
  messageContent,
  messageSubject,
}: NoDataMessagePropType) => {
  return (
    <Grid container spacing={gutterSpace}>
      <Grid
        display="flex"
        alignItems="center"
        justifyContent="center"
        xs={12}
        item
        sx={{ height: "100vh" }}
      >
        {messageContent ??
          `Oops! Looks like the ${
            messageSubject ?? "data"
          } you are trying to view does not exist!`}
      </Grid>
    </Grid>
  );
};

export default NoDataMessage;
