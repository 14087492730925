import React from "react";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem, TreeItemProps } from "@mui/x-tree-view/TreeItem";
import { TreeNode } from "types/inventory";
import {
  AddBoxOutlined,
  IndeterminateCheckBoxOutlined,
} from "@mui/icons-material";
import { dealershipTreeStyle } from "appConstants/styles";
import DealerTileLabelCard from "./tileLabelCard";

// Adding extra type for TreeItemProps
type StyledTreeItemProps = TreeItemProps & {
  id?: string;
  title?: string;
  description?: string;
  location?: string;
  getDetailLink?: (params: TreeNode) => string;
  handleIconClick: (params: TreeNode) => void;
};

type DealershipTreePropType = {
  data: TreeNode[];
  getDetailLink?: (params: TreeNode) => string;
  handleIconClick?: (params: TreeNode) => void;
};

const StyledTreeItem = React.forwardRef(function StyledTreeItem(
  props: StyledTreeItemProps,
  ref: React.Ref<HTMLLIElement>
) {
  const {
    title,
    description,
    location,
    children,
    getDetailLink,
    handleIconClick,
    ...other
  } = props;

  const HandleIconClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation(); // Prevent event from bubbling up and expanding/collapsing the node
    if (handleIconClick) {
      handleIconClick({
        nodeId: props.nodeId!,
        title: props.title!,
        description: props.description!,
        location: props.location!,
      });
    }
  };

  return (
    <TreeItem
      label={
        <DealerTileLabelCard
          title={title}
          description={description}
          location={location}
          {...(!!getDetailLink && {
            detailLinkProps: {
              to: getDetailLink({
                nodeId: props.nodeId!,
                title: props.title!,
                description: props.description!,
                location: props.location!,
              }),
            },
          })}
          handleIconClick={(event) => HandleIconClick(event)}
        />
      }
      {...other}
      ref={ref}
    >
      {children}
    </TreeItem>
  );
});
const DealershipTree = ({
  data,
  getDetailLink,
  handleIconClick,
}: DealershipTreePropType) => {
  const renderTreeItems = (items: TreeNode[]) => {
    return items.map((item) => (
      <StyledTreeItem
        key={item.nodeId}
        getDetailLink={getDetailLink}
        handleIconClick={handleIconClick}
        {...item}
      >
        {item.children && renderTreeItems(item.children)}
      </StyledTreeItem>
    ));
  };
  return (
    <TreeView
      defaultExpanded={["2"]}
      defaultCollapseIcon={<IndeterminateCheckBoxOutlined />}
      defaultExpandIcon={<AddBoxOutlined />}
      sx={dealershipTreeStyle}
    >
      {renderTreeItems(data)}
    </TreeView>
  );
};
export default DealershipTree;
