import { createSlice } from "@reduxjs/toolkit";
import { GridSettingsState } from "./gridSettings.model";
import { getAllGridSettings, saveGridSetting } from "./gridSettings.action";
import {
  getAllGridSettingsFulfilled,
  getAllGridSettingsRejected,
  saveGridSettingsFulfilled,
  saveGridSettingsRejected,
} from "./gridSettings.reducer";
import { setReduxCommonPendingState } from "utils/common";

const initialState: GridSettingsState = {
  isLoading: false,
  successMessage: "",
  error: { title: "", description: "" },
  isSuccess: false,
  currentSetting: null,
  defaultSetting: null,
  settings: [],
};

export const gridSettingsSlice = createSlice({
  name: "gridSettings",
  initialState: initialState,
  reducers: {
    setGridDefaultSetting: (state, action) => {
      state.defaultSetting = action.payload;
    },
    setGridCurrentSetting: (state, action) => {
      state.currentSetting = action.payload;
    },
    resetSuccess: (state) => {
      state.successMessage = "";
      state.isSuccess = false;
    },
    resetError: (state) => {
      state.error.title = "";
      state.error.description = "";
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => (
    builder.addCase(saveGridSetting.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(saveGridSetting.fulfilled, (state, action) => {
      saveGridSettingsFulfilled(state, action);
    }),
    builder.addCase(saveGridSetting.rejected, (state, action) => {
      saveGridSettingsRejected(state, action);
    }),
    builder.addCase(getAllGridSettings.pending, (state) => {
      setReduxCommonPendingState(state);
      state.settings = [];
    }),
    builder.addCase(getAllGridSettings.fulfilled, (state, action) => {
      getAllGridSettingsFulfilled(state, action);
    }),
    builder.addCase(getAllGridSettings.rejected, (state, action) => {
      getAllGridSettingsRejected(state, action);
    })
  ),
});

export const {
  setGridDefaultSetting,
  setGridCurrentSetting,
  resetSuccess,
  resetError,
  reset,
} = gridSettingsSlice.actions;
