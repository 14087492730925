import React from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  FormControl,
  RadioGroupProps,
  FormControlLabelProps,
  Box,
  Typography,
} from "@mui/material";

type RadioOption = {
  label: string | number;
  value: string | number;
};

type RadioGroupWrapperPropType = {
  label?: string;
  name: string;
  value: string | number;
  options: RadioOption[];
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  row?: boolean; // Optional: Display radio buttons in a row
  disabled?: boolean;
  radioGroupProps?: RadioGroupProps;
  formControlLabelProps?: Partial<FormControlLabelProps>;
};

const RadioGroupWrapper: React.FC<RadioGroupWrapperPropType> = ({
  label,
  name,
  value,
  options,
  onChange,
  row = false,
  disabled = false,
  radioGroupProps,
  formControlLabelProps,
}) => {
  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      sx={{ width: "100%" }}
    >
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <RadioGroup
        name={name}
        value={value}
        onChange={onChange}
        row={row}
        {...radioGroupProps}
      >
        {options.map((option) => (
          <Box
            key={option.value}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: "56px",
              borderBottom: "1px solid",
              borderColor: "greyVariantAlt.A100",
              padding: "8px 0",
            }}
          >
            <Typography sx={{ flex: 1, color: "greyVariantAlt.700" }}>
              {option.label}
            </Typography>

            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              {value === option.value && (
                <Typography
                  sx={{ fontSize: "12px", color: "greyVariantAlt.800" }}
                >
                  Current Selection
                </Typography>
              )}

              <FormControlLabel
                value={option.value}
                control={<Radio />}
                label=""
                sx={{ margin: 0 }}
                {...formControlLabelProps}
              />
            </Box>
          </Box>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioGroupWrapper;
