import { Box, Fab, SxProps } from "@mui/material";

import { extendedViewRowStyle } from "appConstants/styles";
import { FC } from "react";

type ExtendedViewRowPropType = {
  onButtonClick: () => void;
  sx?: SxProps;
  overlaySx?: SxProps;
  buttonSx?: SxProps;
  count?: number;
};

const ExtendedViewRow: FC<ExtendedViewRowPropType> = (props) => {
  const { count, sx, overlaySx, buttonSx, onButtonClick, children } = props;
  const defaultCount = 3;

  const combinedOverlayStyle = {
    ...extendedViewRowStyle.overlayBox,
    ...overlaySx,
  };

  const combinedFabButtonStyle = {
    ...extendedViewRowStyle.fabButton,
    ...buttonSx,
  };

  return (
    <Box sx={{ position: "relative", ...sx }}>
      {children}
      {count > defaultCount && (
        <Box sx={combinedOverlayStyle}>
          <Fab
            variant="extended"
            size="large"
            sx={combinedFabButtonStyle}
            onClick={onButtonClick}
          >
            +{count - defaultCount}
          </Fab>
        </Box>
      )}
    </Box>
  );
};

export default ExtendedViewRow;
