import { useHistory } from "react-router-dom";
import { LOCAL_STORAGE_KEYS } from "appConstants/index";
import { AppDispatch } from "redux/store";
import { useDispatch } from "react-redux";
import { loginAndGetRefreshToken, logout } from "redux/slices/auth/auth.action";
import { APP_ROUTES } from "appConstants/routes";

const useLogout = () => {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
  const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
  const handleLogout = () => {
    localStorage.clear();
    dispatch(logout());
    if (!refreshToken) {
      history.push(APP_ROUTES.Login);
    } else {
      dispatch(loginAndGetRefreshToken({ token, refreshToken }));
    }
  };

  return { handleLogout };
};

export default useLogout;
