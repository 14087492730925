import { createAsyncThunk } from "@reduxjs/toolkit";
import { CUSTOMER_INTERESTS_URL, INTEREST_URL } from "utils/urls";
import { getRequest, postRequest, putRequest } from "utils/axiosClient";
import { ERROR_MESSAGES, JSON_HEADER, STATUS_CODE } from "appConstants";
import AppError from "utils/AppError";
import { GetInterestByIDPayload, GetInterestsPayload } from "types/interest";

const typePrefix = {
  GET: "getInterest",
  CREATE: "createInterest",
  EDIT: "editInterest",
};

export const getInterests = createAsyncThunk(
  INTEREST_URL,
  async (payload: GetInterestsPayload) => {
    try {
      const response = await getRequest<any>(INTEREST_URL, {
        params: payload,
      });

      return {
        records: response.data?.records,
        totalRecords: response.data?.totalRecords,
      };
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getInterests,
        error.response.data?.message
      );
    }
  }
);

export const getInterestById = createAsyncThunk(
  typePrefix.GET,
  async (payload: GetInterestByIDPayload) => {
    try {
      const response = await getRequest<any>(INTEREST_URL + "/" + payload.id);
      return response.data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getInterests,
        error.response.data?.message
      );
    }
  }
);

export const createInterest = createAsyncThunk(
  typePrefix.CREATE,
  async (payload: any) => {
    try {
      const response = await postRequest<any>(
        INTEREST_URL,
        payload,
        JSON_HEADER
      );

      if (response.status === STATUS_CODE.CREATED_SUCCESS) {
        return response?.data;
      }
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.createInterest,
        error.response.data?.message
      );
    }
  }
);

export const editInterest = createAsyncThunk(
  typePrefix.EDIT,
  async (payload: any) => {
    try {
      const response = await putRequest<any>(
        INTEREST_URL,
        payload,
        JSON_HEADER
      );

      if (response.status === STATUS_CODE.UPDATE_SUCCESS) return;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.createInterest,
        error.response.data?.message
      );
    }
  }
);

export const getCustomerInterests = createAsyncThunk(
  CUSTOMER_INTERESTS_URL,
  async (payload: GetInterestByIDPayload) => {
    try {
      const response = await getRequest<any>(
        CUSTOMER_INTERESTS_URL + "/" + payload.id
      );
      return response.data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getInterests,
        error.response.data?.message
      );
    }
  }
);
