import { quoteSuccessMessages } from "appConstants";
import { FinanceState } from "./finance.model";

export const getQuotesFulfilled = (state: FinanceState, action) => {
  state.isLoading = false;
  state.quote = null;
  state.quotes = action.payload.records;
  state.totalRecords = action.payload?.totalRecords;
};

export const getQuotesRejected = (state: FinanceState, action) => {
  state.isLoading = false;
  state.error.title = action.error.name;
  state.error.description = action.error.message;
};

export const getQuoteByIdFulfilled = (state: FinanceState, action) => {
  state.isLoading = false;
  state.quote = action.payload;
};

export const getQuoteByIdRejected = (state: FinanceState, action) => {
  state.isLoading = false;
  state.error.title = action.error.name;
  state.error.description = action.error.message;
};

export const createQuoteFulfilled = (state: FinanceState, action) => {
  state.isLoading = false;
  state.isSuccess = true;
  state.successMessage = quoteSuccessMessages.quoteCreated;
};

export const createQuoteRejected = (state: FinanceState, action) => {
  state.isLoading = false;
  state.error.title = action.error.name;
  state.error.description = action.error.message;
};

export const editQuoteFulfilled = (state: FinanceState, action) => {
  state.isLoading = false;
  state.isSuccess = true;
  state.successMessage = action.payload;
};

export const editQuoteRejected = (state: FinanceState, action) => {
  state.isLoading = false;
  state.error.title = action.error.name;
  state.error.description = action.error.message;
};

export const voidQuoteFulfilled = (state: FinanceState, action) => {
  state.isLoading = false;
  state.voidSuccess = true;
  state.successMessage = action.payload.message;
};

export const voidQuoteRejected = (state: FinanceState, action) => {
  state.isLoading = false;
  state.error.title = action.error.name;
  state.error.description = action.error.message;
};

export const getFinanceStatusesFullfilled = (state: FinanceState, action) => {
  state.isLoading = false;
  state.financeStatuses = action.payload;
};

export const getFinanceStatusesRejected = (state: FinanceState, action) => {
  state.isLoading = false;
  state.error.title = action.error.name;
  state.error.description = action.error.message;
};

export const getDealTypesFullfilled = (state: FinanceState, action) => {
  state.isLoading = false;
  state.dealTypes = action.payload;
};

export const getDealTypesRejected = (state: FinanceState, action) => {
  state.isLoading = false;
  state.error.title = action.error.name;
  state.error.description = action.error.message;
};

export const getPaymentTypesFullfilled = (state: FinanceState, action) => {
  state.paymentTypes = action.payload;
};

export const getPaymentTypesRejected = (state: FinanceState, action) => {
  state.error.title = action.error.name;
  state.error.description = action.error.message;
};

export const takeDepositFulfilled = (state: FinanceState, action) => {
  state.isLoading = false;
  state.depositSuccessMessage = quoteSuccessMessages.takeDeposit;
};

export const takeDepositRejected = (state: FinanceState, action) => {
  state.isLoading = false;
  state.error.title = action.error.name;
  state.error.description = action.error.message;
};

export const convertToDealFulfilled = (state: FinanceState, action) => {
  state.isLoading = false;
  state.convertToDealSuccessMessage = quoteSuccessMessages.convertToDeal;
};

export const convertToDealRejected = (state: FinanceState, action) => {
  state.isLoading = false;
  state.error.title = action.error.name;
  state.error.description = action.error.message;
};

export const getFeeTypesFulfilled = (state: FinanceState, action) => {
  state.isLoading = false;
  state.feeTypes = action.payload;
};

export const getFeeTypesRejected = (state: FinanceState, action) => {
  state.isLoading = false;
  state.error.title = action.error.name;
  state.error.description = action.error.message;
};

export const getTaxTypesFulfilled = (state: FinanceState, action) => {
  state.isLoading = false;
  state.taxTypes = action.payload;
};

export const getTaxTypesRejected = (state: FinanceState, action) => {
  state.isLoading = false;
  state.error.title = action.error.name;
  state.error.description = action.error.message;
};

export const getCreditTypesFulfilled = (state: FinanceState, action) => {
  state.isLoading = false;
  state.creditTypes = action.payload;
};

export const getCreditTypesRejected = (state: FinanceState, action) => {
  state.isLoading = false;
  state.error.title = action.error.name;
  state.error.description = action.error.message;
};

export const getQuoteHistoryByIdFulfilled = (state: FinanceState, action) => {
  state.isHistoryLoading = false;
  state.quoteHistory = action.payload;
};

export const getQuoteHistoryByIdRejected = (state: FinanceState, action) => {
  state.isHistoryLoading = false;
  state.error.title = action.error.name;
  state.error.description = action.error.message;
};

export const printQuoteSheetFulfilled = (state: FinanceState, action) => {
  state.isPrintLoading = false;
};

export const printQuoteSheetRejected = (state: FinanceState, action) => {
  state.isPrintLoading = false;
  state.error.title = action.error.name;
  state.error.description = action.error.message;
};
