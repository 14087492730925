import { ReactNode } from "react";
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  MenuItemProps,
  Select,
  SelectProps,
  SxProps,
  Typography,
} from "@mui/material";
import {
  checkboxCommonStyle,
  inlineTableSelectStyle,
  pxToRem,
  textFieldStyle,
} from "appConstants";
import useSelectWrapper from "./useSelectWrapper";

type ItemsType = {
  label: string | number | ReactNode;
  value: string | number;
};

export type SelectWrapperPropType = {
  label?: string;
  items: any[] | ItemsType[];
  itemLabelField?: string; // INFO: this is used to make it more dynamic so that __
  itemValueField?: string; // __ we can pass any data array and only need to tell the vale and label fields or keys;
  variant?: "filled" | "outlined" | "standard";
  menuItemSx?: SxProps;
  isCheckboxStyle?: boolean;
  inTableRow?: boolean;
  validationError?: string;
  formControlSx?: SxProps;
  menuItemProps?: MenuItemProps;
  onValueMapToLabel?: (name: string, label: string | number) => void;
} & SelectProps;

const SelectWrapper = (props: SelectWrapperPropType) => {
  const {
    label,
    items,
    itemLabelField = "label",
    itemValueField = "value",
    variant,
    menuItemSx,
    isCheckboxStyle = false,
    value,
    multiple = false,
    sx: selectSx,
    formControlSx = {},
    menuItemProps = {},
    inTableRow,
    validationError,
    onValueMapToLabel,
    ...selectProps
  } = props;

  const { isSimpleStringArray } = useSelectWrapper(props);

  const renderItems = (item: any) => {
    const itemLabel = isSimpleStringArray ? item : item[itemLabelField];
    const itemValue = isSimpleStringArray ? item : item[itemValueField];

    return (
      <MenuItem
        key={itemValue}
        value={itemValue}
        sx={menuItemSx}
        disabled={value === itemValue}
        {...menuItemProps}
      >
        {isCheckboxStyle ? (
          <>
            <Checkbox
              checked={(value as any[])?.includes(itemValue)}
              sx={checkboxCommonStyle}
            />
            <ListItemText
              primary={itemLabel}
              sx={{
                "span.MuiTypography-root": { fontSize: pxToRem(14) },
              }}
            />
          </>
        ) : (
          <Typography variant="body1">{itemLabel}</Typography>
        )}
      </MenuItem>
    );
  };

  return (
    <FormControl
      fullWidth
      variant={variant ?? "filled"}
      sx={{
        ...textFieldStyle,
        ...formControlSx,
        ...(!label && {
          "p.MuiTypography-root.MuiTypography-body1": {
            mt: pxToRem(-5),
          },
        }),
      }}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        value={value}
        {...(multiple && {
          renderValue: (selected) => (selected as string[]).join(", "),
        })}
        multiple={multiple}
        sx={{ ...selectSx, ...(inTableRow && inlineTableSelectStyle) }}
        // displayEmpty
        error={Boolean(validationError)}
        {...selectProps}
      >
        {items?.length ? (
          items.map((item: any) => renderItems(item))
        ) : (
          <MenuItem value="" disabled>
            No Options Available
          </MenuItem>
        )}
      </Select>
      {validationError && (
        <FormHelperText error>{validationError}</FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectWrapper;
