import TableComponent, { TablePropType } from "components/Table";
import useDynamicGrid from "./useDynamicGrid";
import { GridColDef } from "@mui/x-data-grid-premium";
import { DynamicGridColumnsForModification } from "appConstants/index";

export type DynamicGridPropType = TablePropType & {
  actionColumn?: Partial<GridColDef>;
  renderCustomCell?: (
    cellName: DynamicGridColumnsForModification,
    columnsData: GridColDef[]
  ) => void;
};

const DynamicGrid = (props: DynamicGridPropType) => {
  const { tableColumns } = useDynamicGrid(props);

  return <TableComponent {...props} columns={tableColumns} />;
};

export default DynamicGrid;
