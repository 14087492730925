import { LOCAL_STORAGE_KEYS } from "appConstants";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LocationType } from "types";
import { getQueryParam } from "utils/common";

const useValidationToken = () => {
  const location = useLocation<LocationType>();
  const history = useHistory();

  const tokenFromQueryParams = getQueryParam(location.search, "token");

  const tokenFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);

  useEffect(() => {
    if (tokenFromQueryParams) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, tokenFromQueryParams);
      removeQueryParam(LOCAL_STORAGE_KEYS.TOKEN);
    }
  }, [tokenFromQueryParams]);

  const removeQueryParam = (paramToRemove: string) => {
    const params = new URLSearchParams(location.search);
    params.delete(paramToRemove);
    const newUrl = `${location.pathname}${
      params?.toString() ? `?${params}` : ""
    }`;
    history.replace(newUrl);
  };

  return {
    tokenExist: tokenFromQueryParams || tokenFromLocalStorage,
  };
};

export default useValidationToken;
