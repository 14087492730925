import { Box, Stack, SxProps, Typography } from "@mui/material";
import { pageHeading, pxToRem } from "appConstants/styles";

type Props = {
  pageTitle?;
  subHeading?;
  children?: React.ReactNode;
  titleFontSize?: string;
  sx?: SxProps;
};

const PageHeading = (props: Props) => {
  const { pageTitle, titleFontSize, subHeading, children, sx } = props;
  return (
    <Stack sx={{ ...pageHeading, ...sx }}>
      {pageTitle && (
        <Box display="flex" flexDirection="column">
          <Typography
            component="h2"
            sx={{
              ...pageHeading.headingStyle,
              ...(subHeading && { pb: pxToRem(4) }),
              fontSize: titleFontSize,
            }}
          >
            {pageTitle}
          </Typography>
          {!!subHeading && (
            <Typography
              component="h6"
              pb={pxToRem(10)}
              lineHeight={pxToRem(24)}
              fontWeight={500}
            >
              {subHeading}
            </Typography>
          )}
        </Box>
      )}
      {children && <Box>{children}</Box>}
    </Stack>
  );
};

export default PageHeading;
