import { useLocation } from "react-router-dom";
import { RouteConfig } from "types";

const useAppRoutes = (routes: RouteConfig[]) => {
  const location = useLocation();

  const showHeaderPaths = routes
    .filter((route) => route.isProtected)
    .map((route) => route.path);

  const showHeader = showHeaderPaths?.includes(location?.pathname);

  return { showHeader };
};

export default useAppRoutes;
