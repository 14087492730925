import { createSlice } from "@reduxjs/toolkit";
import { PersonnelState } from "./personnel.model";
import {
  getAllPersonnels,
  getPersonnel,
  getLocations,
  mergePersonnels,
  uploadPersonnelBulkImportFile,
  getCountries,
  getStatesByCountry,
  getCounties,
  createPersonnel,
  editPersonnel,
  getDepartments,
  getJobTitles,
  getManagers,
  notifyUserEmail,
  getRegionsByCountry,
  getStatesByRegion,
  getCurrentUser,
  getPorters,
  getEmployees,
} from "./personnel.action";
import {
  personnelFulfilled,
  personnelRejected,
  viewPersonnelFulfilled,
  viewPersonnelrejected,
  locationrejected,
  locationsFulfilled,
  mergePersonnelsFulfilled,
  uploadPersonnelBulkImportFileFulfilled,
  uploadPersonnelBulkImportFileRejected,
  countriesFulfilled,
  countriesRejected,
  statesFulfilled,
  statesRejected,
  countiesFulfilled,
  countiesRejected,
  createPersonnelFulfilled,
  createPersonnelRejected,
  editPersonnelFulfilled,
  editPersonnelRejected,
  departmentsFulfilled,
  departmentsRejected,
  jobTitlesFulfilled,
  jobTitlesRejected,
  managersFulfilled,
  managersRejected,
  notifyUserEmailFulfilled,
  notifyUserEmailRejected,
  regionsFulfilled,
  regionsRejected,
  currentUserFulfilled,
  currentUserRejected,
  portersFulfilled,
  portersRejected,
  employeesFulfilled,
  employeesRejected,
} from "./personnel.reducer";

const initialState: PersonnelState = {
  isLoading: false,
  error: { title: "", description: "" },
  personnels: [],
  employees: [],
  porters: [],
  personnel: null,
  currentUser: null,
  locations: [],
  successMessage: "",
  isImportSuccess: "",
  countries: [],
  regions: [],
  states: [],
  counties: [],
  departments: [],
  jobTitles: [],
  managers: [],
  createEditIsLoading: false,
  createEditIsSuccess: false,
  createEditError: "",
  selectedPersonnels: [],
  notifyEmailSuccess: false,
  notifyEmailError: "",
  totalRecords: null,
};

export const personnelSlice = createSlice({
  name: "personnel",
  initialState: initialState,
  reducers: {
    resetNotifyEmailValues: (state) => {
      state.notifyEmailError = null;
      state.notifyEmailSuccess = false;
    },
    resetCreateEditValues: (state) => {
      state.createEditError = null;
      state.createEditIsSuccess = false;
      state.createEditIsLoading = false;
    },
    reset: (state) => {
      // Reset the properties you want to reset to their initial values
      return {
        ...initialState,
        locations: state.locations, // Keep the locations intact
      };
    },
    setSelectedPersonnels: (state, action) => {
      state.selectedPersonnels = action.payload;
    },
    resetError: (state) => {
      state.error.title = "";
      state.error.description = "";
    },
    resetSuccess: (state) => {
      state.successMessage = "";
      state.isImportSuccess = "";
    },
    resetPersonnel: (state) => {
      state.personnel = null;
      state.notifyEmailSuccess = false;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    resetRegions: (state) => {
      state.regions = initialState.regions;
    },
    resetStates: (state) => {
      state.states = initialState.states;
    },
    resetCounties: (state) => {
      state.counties = initialState.counties;
    },
  },
  extraReducers: (builder) => (
    builder.addCase(getAllPersonnels.pending, (state, action) => {
      state.isLoading = true;
      state.personnels = [];
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getAllPersonnels.fulfilled, (state, action) => {
      personnelFulfilled(state, action);
    }),
    builder.addCase(getAllPersonnels.rejected, (state, action) => {
      personnelRejected(state, action);
    }),
    builder.addCase(getPorters.fulfilled, (state, action) => {
      portersFulfilled(state, action);
    }),
    builder.addCase(getPorters.rejected, (state, action) => {
      portersRejected(state, action);
    }),
    builder.addCase(getPersonnel.pending, (state, action) => {
      state.isLoading = true;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getPersonnel.fulfilled, (state, action) => {
      viewPersonnelFulfilled(state, action);
    }),
    builder.addCase(getPersonnel.rejected, (state, action) => {
      viewPersonnelrejected(state, action);
    }),
    builder.addCase(getCurrentUser.pending, (state, action) => {
      state.isLoading = true;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      currentUserFulfilled(state, action);
    }),
    builder.addCase(getCurrentUser.rejected, (state, action) => {
      currentUserRejected(state, action);
    }),
    builder.addCase(getLocations.pending, (state, action) => {
      state.isLoading = true;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getLocations.fulfilled, (state, action) => {
      locationsFulfilled(state, action);
    }),
    builder.addCase(getLocations.rejected, (state, action) => {
      locationrejected(state, action);
    }),
    builder.addCase(mergePersonnels.pending, (state, action) => {
      state.isLoading = true;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(mergePersonnels.fulfilled, (state, action) => {
      mergePersonnelsFulfilled(state, action);
    }),
    builder.addCase(mergePersonnels.rejected, (state, action) => {
      personnelRejected(state, action);
    }),
    builder.addCase(uploadPersonnelBulkImportFile.pending, (state, action) => {
      state.isLoading = true;
      state.isImportSuccess = "";
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(
      uploadPersonnelBulkImportFile.fulfilled,
      (state, action) => {
        uploadPersonnelBulkImportFileFulfilled(state, action);
      }
    ),
    builder.addCase(uploadPersonnelBulkImportFile.rejected, (state, action) => {
      uploadPersonnelBulkImportFileRejected(state, action);
    }),
    builder.addCase(getCountries.pending, (state, action) => {
      state.isLoading = true;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getCountries.fulfilled, (state, action) => {
      countriesFulfilled(state, action);
    }),
    builder.addCase(getCountries.rejected, (state, action) => {
      countriesRejected(state, action);
    }),
    builder.addCase(getRegionsByCountry.pending, (state, action) => {
      state.isLoading = true;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getRegionsByCountry.fulfilled, (state, action) => {
      regionsFulfilled(state, action);
    }),
    builder.addCase(getRegionsByCountry.rejected, (state, action) => {
      regionsRejected(state, action);
    }),
    builder.addCase(getStatesByCountry.pending, (state, action) => {
      state.isLoading = true;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getStatesByCountry.fulfilled, (state, action) => {
      statesFulfilled(state, action);
    }),
    builder.addCase(getStatesByCountry.rejected, (state, action) => {
      statesRejected(state, action);
    }),
    builder.addCase(getStatesByRegion.pending, (state, action) => {
      state.isLoading = true;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getStatesByRegion.fulfilled, (state, action) => {
      statesFulfilled(state, action);
    }),
    builder.addCase(getStatesByRegion.rejected, (state, action) => {
      statesRejected(state, action);
    }),
    builder.addCase(getCounties.pending, (state, action) => {
      state.isLoading = true;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getCounties.fulfilled, (state, action) => {
      countiesFulfilled(state, action);
    }),
    builder.addCase(getCounties.rejected, (state, action) => {
      countiesRejected(state, action);
    }),
    builder.addCase(createPersonnel.pending, (state, action) => {
      state.createEditIsLoading = true;
      state.createEditIsSuccess = false;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(createPersonnel.fulfilled, (state, action) => {
      createPersonnelFulfilled(state, action);
    }),
    builder.addCase(createPersonnel.rejected, (state, action) => {
      createPersonnelRejected(state, action);
    }),
    builder.addCase(editPersonnel.pending, (state, action) => {
      state.createEditIsLoading = true;
      state.createEditIsSuccess = false;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(editPersonnel.fulfilled, (state, action) => {
      editPersonnelFulfilled(state, action);
    }),
    builder.addCase(editPersonnel.rejected, (state, action) => {
      editPersonnelRejected(state, action);
    }),
    builder.addCase(getDepartments.pending, (state, action) => {
      state.isLoading = true;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getDepartments.fulfilled, (state, action) => {
      departmentsFulfilled(state, action);
    }),
    builder.addCase(getDepartments.rejected, (state, action) => {
      departmentsRejected(state, action);
    }),
    builder.addCase(getJobTitles.pending, (state, action) => {
      state.isLoading = true;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getJobTitles.fulfilled, (state, action) => {
      jobTitlesFulfilled(state, action);
    }),
    builder.addCase(getJobTitles.rejected, (state, action) => {
      jobTitlesRejected(state, action);
    }),
    builder.addCase(getManagers.pending, (state, action) => {
      state.isLoading = true;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getManagers.fulfilled, (state, action) => {
      managersFulfilled(state, action);
    }),
    builder.addCase(getManagers.rejected, (state, action) => {
      managersRejected(state, action);
    }),
    builder.addCase(notifyUserEmail.pending, (state, action) => {
      state.isLoading = true;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(notifyUserEmail.fulfilled, (state, action) => {
      notifyUserEmailFulfilled(state, action);
    }),
    builder.addCase(notifyUserEmail.rejected, (state, action) => {
      notifyUserEmailRejected(state, action);
    }),
    builder.addCase(getEmployees.pending, (state, action) => {
      state.isLoading = true;
      state.error.title = "";
      state.error.description = "";
    }),
    builder.addCase(getEmployees.fulfilled, (state, action) => {
      employeesFulfilled(state, action);
    }),
    builder.addCase(getEmployees.rejected, (state, action) => {
      employeesRejected(state, action);
    })
  ),
});

export const {
  reset,
  resetError,
  resetSuccess,
  resetNotifyEmailValues,
  resetCreateEditValues,
  resetPersonnel,
  setSelectedPersonnels,
  setError,
  resetRegions,
  resetCounties,
  resetStates,
} = personnelSlice.actions;
