import { Grid, Stack, SxProps, Typography } from "@mui/material";
import { gutterSpace, pxToRem } from "appConstants";
import useTitleBar from "./useTitleBar";

type TitleBarPropType = {
  parentTitle?: string;
  pageTitle?: string;
  leftPan?: React.ReactNode;
  rightPan?: React.ReactNode;
  wrapperSx?: SxProps;
  paddingRight?: boolean;
  noMarginBottom?: boolean;
};

const TitleBar: React.FC<TitleBarPropType> = (props) => {
  const {
    parentTitle: parentTitleProp,
    pageTitle: pageTitleProp,
    leftPan,
    rightPan,
    wrapperSx = {},
    paddingRight = false,
    noMarginBottom = false,
  } = props;

  const { parentTitle, pageTitle } = useTitleBar();

  return (
    <Grid
      container
      spacing={gutterSpace}
      mb={noMarginBottom ? 0 : gutterSpace}
      sx={{ ...wrapperSx, ...(paddingRight && { pr: gutterSpace }) }}
    >
      <Grid item xs={2}>
        <Stack
          direction="column"
          sx={{
            borderRadius: pxToRem(4),
            backgroundColor: "grey.A400",
            padding: `${pxToRem(10)} ${pxToRem(12)} ${pxToRem(12)}`,
          }}
        >
          <Typography
            variant="body1"
            letterSpacing="0.15px"
            fontWeight={500}
            lineHeight={pxToRem(24)}
          >
            {parentTitleProp ?? parentTitle}
          </Typography>
          <Typography
            variant="body2"
            letterSpacing="0.25px"
            lineHeight={pxToRem(14)}
          >
            {pageTitleProp ?? pageTitle}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={10}>
        <Stack
          direction={"row"}
          spacing={pxToRem(9)}
          sx={{
            borderRadius: pxToRem(4),
            backgroundColor: "grey.A400",
            padding: `${pxToRem(12)} ${gutterSpace}`,
            height: "100%",
          }}
        >
          <Stack direction="row" flex={1} spacing={pxToRem(9)}>
            {leftPan}
          </Stack>
          <Stack direction="row" spacing={pxToRem(9)}>
            {rightPan}
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default TitleBar;
