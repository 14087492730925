import { HEADER_TYPE, MENU_ITEMS, PERMISSION } from "appConstants";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getCurrentUser } from "redux/slices/personnel/personnel.action";
import { AppDispatch, RootState } from "redux/store";
import { base64ToUrl } from "utils/common";
import useLogout from "utils/hooks/useLogout";
import useValidatePermission from "utils/hooks/useValidatePermission";
import useAppHeaderContext from "./appHeaderContext/useAppHeaderContext";
import useModal from "utils/hooks/useModal";
import { getUserInfo } from "redux/slices/auth/auth.action";

// App Module Color Mapping - [start]
const colorMapping: Record<HEADER_TYPE, string> = {
  [HEADER_TYPE.DASHBOARD]: "appModules.50",
  [HEADER_TYPE.FINANCE]: "appModules.300",
  [HEADER_TYPE.DEALERSHIP]: "appModules.100",
  [HEADER_TYPE.DEALERSHIP_SETTINGS]: "appModules.100",
  [HEADER_TYPE.INVENTORY]: "appModules.200",
  [HEADER_TYPE.PERSONNEL]: "appModules.400",
  [HEADER_TYPE.REPORTS]: "appModules.500",
  [HEADER_TYPE.PARTS_SERVICES]: "appModules.600",
  [HEADER_TYPE.TIME_CLOCK]: "appModules.700",
};

const colorSubMapping: Record<HEADER_TYPE, string> = {
  [HEADER_TYPE.DASHBOARD]: "appSubModules.50",
  [HEADER_TYPE.FINANCE]: "appSubModules.300",
  [HEADER_TYPE.DEALERSHIP]: "appSubModules.100",
  [HEADER_TYPE.DEALERSHIP_SETTINGS]: "appSubModules.100",
  [HEADER_TYPE.INVENTORY]: "appSubModules.200",
  [HEADER_TYPE.PERSONNEL]: "appSubModules.400",
  [HEADER_TYPE.REPORTS]: "appSubModules.500",
  [HEADER_TYPE.PARTS_SERVICES]: "appSubModules.600",
  [HEADER_TYPE.TIME_CLOCK]: "appSubModules.700",
};
// [end]

const useAppHeader = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const headerMenuOpen = Boolean(anchorEl);

  const location = useLocation();
  const currentPath = location.pathname;

  const dispatch = useDispatch<AppDispatch>();

  const currentUser = useSelector(
    (state: RootState) => state.personnel?.currentUser
  );
  const userInfo = useSelector((state: RootState) => state.auth?.userInfo);

  const { handleLogout } = useLogout();

  const { checkPermission } = useValidatePermission();

  const { isOpen, toggleModal } = useModal();

  const { appModuleName, leftContent, rightContent, cornerContent } =
    useAppHeaderContext();
  // Function to return the appropriate color based on the appModuleName - [start]
  // Generalized function to return the appropriate color based on the appModuleName and mapping
  const getModuleColor = (
    appModuleName: string | undefined,
    mapping: Record<string, string>,
    defaultColor: string = "appModules.100"
  ) => {
    return appModuleName ? mapping[appModuleName] || defaultColor : undefined;
  };

  const moduleColor = getModuleColor(appModuleName, colorMapping);
  const moduleSubColor = getModuleColor(appModuleName, colorSubMapping);
  // [end]

  //varaibles

  useEffect(() => {
    if (!currentUser) {
      dispatch(getCurrentUser());
    }

    if (!userInfo) {
      dispatch(getUserInfo());
    }
  }, []);

  //menu open - [start]
  const handleHeaderMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  // [end]

  //menu close - [start]
  const handleHeaderMenuClose = () => {
    setAnchorEl(null);
  };
  // [end]

  // Determine the active menu item
  const activeMenuItem = useMemo(
    () =>
      MENU_ITEMS.find(
        (item) =>
          item?.link.startsWith(currentPath) ||
          !!item.childLinks.find(
            (
              childItem // INFO: Checking wether child link parent matched the main menu item link and current path is same as child item link
            ) =>
              (childItem.parentLink === item?.link ||
                childItem.mainMenuLink === item?.link) &&
              currentPath === childItem.link
          )
      ),
    [appModuleName, location]
  );

  // Submenu links for the matched app module
  const NavSubLinks = useMemo(() => {
    // Find the menu for the current appModuleName
    const matchedMenu = MENU_ITEMS.find((menu) => menu.label === appModuleName);

    if (!matchedMenu || !matchedMenu.childLinks) return [];

    // Filter valid child links (must have both label and link)
    return matchedMenu.childLinks
      .filter((link) => link.label && link.link)
      .map((link) => ({
        ...link,
        isActive:
          currentPath === link.link ||
          !!matchedMenu.childLinks.find(
            (item) => item.parentLink === link.link && currentPath === item.link
          ),
      }));
  }, [appModuleName, location]);

  const profileImage = base64ToUrl(currentUser?.profilePicture);

  return {
    activeMenuItem,
    moduleColor,
    moduleSubColor,
    headerMenuOpen,
    anchorEl,
    leftContent,
    rightContent,
    cornerContent,
    NavSubLinks,
    currentUser,
    profileImage,
    currentDealership: userInfo?.personalDealership,
    crmBaseURL: process.env.REACT_APP_CRM_BASE_URL,
    isOpen,
    toggleModal,
    checkPermission,
    handleHeaderMenuClick,
    handleHeaderMenuClose,
    handleLogout,
  };
};

export default useAppHeader;
