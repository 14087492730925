import { SxProps } from "@mui/material";
import MButton, { ButtonProps } from "@mui/material/Button";
import { containedButtonBlue } from "appConstants/styles";
import { Link, LinkProps } from "react-router-dom";

type ButtonPropType = {
  combinedSx?: SxProps; // INFO: combinedSx is added  here to adjust code which is adding sx to MButton two times
  linkProps?: LinkProps;
} & Partial<ButtonProps>;

const Button = (props: ButtonPropType) => {
  const { combinedSx, linkProps, color, children, ...otherProps } = props;

  const buttonSx = {
    // INFO: First sx is added here
    ...containedButtonBlue,
    ...((!color || color === "primary") &&
      containedButtonBlue["&.MuiButtonBase-root"].primary),
    ...combinedSx, // INFO: If want to add some new properties along with already add sx (overwrite)
  };

  return (
    <MButton
      variant="contained"
      sx={buttonSx}
      {...(linkProps?.to
        ? {
            component: Link,
            to: linkProps.to,
            ...(linkProps as any),
          }
        : {})} // INFO: If button need to become a link
      {...otherProps} // INFO: If want to add sx overriding the first one otherProps has it
    >
      {children}
    </MButton>
  );
};

export default Button;
