import { Box, LinearProgress } from "@mui/material";
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridRowParams,
  MuiEvent,
  GridCallbackDetails,
  GridRowSelectionModel,
  GridFilterModel,
  GridColDef,
  GridPinnedColumns,
  GridSortModel,
  GridSortItem,
  GridRow,
  GridRowProps,
  GridCellParams,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { dataGridWrapper } from "appConstants/styles";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import {
  ReactNode,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import useText from "utils/hooks/useText";
import useUpdateDynamicHeight from "utils/hooks/useUpdateDynamicHeight";
import { Link } from "react-router-dom";
import { GRID_TYPE, MUI_GRID_COLUMNS } from "appConstants";
import GridSettingsPopover from "components/GridSettingsPopover";
import { getAllGridSettings } from "redux/slices/gridSettings/gridSettings.action";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";

export type TablePropType = {
  rows: any;
  columns?: GridColDef[];
  checkboxSelection?: boolean;
  apiRef?: React.MutableRefObject<GridApiPremium>;
  filterModel?: GridFilterModel;
  loading?: boolean;
  sx?: object;
  selectionModelIds?: Array<number>;
  children?: React.ReactNode;
  page?: number;
  totalRecords?: number;
  pinnedColumns?: GridPinnedColumns;
  rowHeight?: number;
  hideHeader?: boolean;
  footer?: React.ReactNode;
  sortModel?: GridSortModel;
  gridType?: GRID_TYPE; // INFO: Pupose of this is to save grid settings to backend
  columnsLoadedRefCurrent?: boolean | string | number; // INFO: This is used to re-render the colums when there is a change in the columns
  contextMenuItems?: (row: any) => ReactNode;
  handleRowClick?: (
    params: GridRowParams,
    event: MuiEvent,
    callback: GridCallbackDetails
  ) => void;
  handleCheckboxClick?: (
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) => void;
  getRowId?: (row: any) => number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  getRowClassName?: (params: GridRowParams) => string;
  handleSortModelChange?: (
    model: GridSortModel,
    details: GridCallbackDetails
  ) => void;
  handleFilterModelChange?: (
    model: GridFilterModel,
    details: GridCallbackDetails
  ) => void;
  getRowLink?: (params: GridRowProps) => string;
} & Partial<DataGridPremiumProps>;

export default function TableComponent(props: TablePropType) {
  const {
    rows = [],
    columns,
    checkboxSelection = true,
    apiRef,
    loading,
    sx,
    selectionModelIds,
    children,
    page = 0,
    totalRecords,
    pinnedColumns = {},
    rowHeight = 62,
    hideHeader = false,
    footer,
    sortModel,
    gridType,
    columnsLoadedRefCurrent,
    getRowId = (row) => row.id,
    setPage,
    handleRowClick,
    contextMenuItems,
    handleCheckboxClick,
    getRowClassName,
    handleSortModelChange,
    handleFilterModelChange,
    getRowLink,
    ...prop
  } = props;

  const [dataRows, setDataRows] = useState([]);
  const fetchedPages = useMemo(() => new Set(), []);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const prevTotalRecordsRef = useRef<number | undefined>(undefined);
  const prevSortModelRef = useRef<GridSortItem | undefined>(undefined);
  const { totalRecordsLabel } = useText("commonLabels");
  const gridApiRef = useGridApiRef();
  const { dynamicHeight, dynamicHeightRef } = useUpdateDynamicHeight(0);

  const columnsLoadedRef = useRef(false);

  const dispatch = useDispatch<AppDispatch>();

  const { currentSetting } = useSelector(
    (state: RootState) => state.gridSettings
  );

  const memoizedColumns = useMemo(() => {
    return columns;
  }, [columnsLoadedRef?.current, columnsLoadedRefCurrent]);

  const actionColumnIndex = columns?.findIndex(
    (item) => item.field === MUI_GRID_COLUMNS.ACTION
  );

  if (actionColumnIndex > -1 && gridType) {
    columns[actionColumnIndex].renderHeader = () => (
      <GridSettingsPopover gridApiRef={gridApiRef} gridType={gridType} />
    );
  }

  useEffect(() => {
    if (!columnsLoadedRef?.current && columns?.length) {
      columnsLoadedRef.current = true;
    }
  }, [columns]);

  useEffect(() => {
    if (gridType) {
      dispatch(getAllGridSettings({ gridType }));
    }
  }, [gridType]);

  useEffect(() => {
    const shouldUpdateDataRows =
      prevTotalRecordsRef.current !== totalRecords ||
      dataRows.length > totalRecords ||
      page === 0;

    if (shouldUpdateDataRows) {
      setDataRows(rows);
    }

    // Update previous total records
    prevTotalRecordsRef.current = totalRecords;
  }, [columns, totalRecords, props.columns]);

  useEffect(() => {
    handleCurrentSettingsExtraction();
  }, [currentSetting, columnsLoadedRefCurrent]);

  const handleCurrentSettingsExtraction = () => {
    if (currentSetting?.[gridType]) {
      applyGridSettings(
        currentSetting[gridType].columnSettings,
        currentSetting[gridType].filterSettings,
        currentSetting[gridType].sortSettings
      );
    }
  };

  const applyGridSettings = useCallback(
    (
      columnSettingsJson: string, // INFO: Column visibility & order settings in JSON string
      filterSettingsJson: string, // INFO: Filter settings in JSON string
      sortSettingsJson: string // INFO: Sort settings in JSON string
    ) => {
      if (gridApiRef?.current) {
        // INFO: Parse the JSON strings
        const parsedColumnSettings = JSON.parse(columnSettingsJson);
        const parsedFilterSettings = JSON.parse(filterSettingsJson);
        const parsedSortSettings = JSON.parse(sortSettingsJson);

        let columnsOrder = [];
        let columnDimensions = {};

        // INFO: Apply column visibility model
        gridApiRef.current.setColumnVisibilityModel(
          parsedColumnSettings.columnVisibilityModel
        );

        // INFO: Reorder columns
        const columnOrder = parsedColumnSettings.columnOrder;
        const columns = gridApiRef.current.getAllColumns();

        columnOrder.forEach((colField) => {
          const column = columns.find((col) => col.field === colField);
          if (column) {
            columnsOrder.push(colField);
          }
        });

        // INFO: Apply column widths
        const columnWidths = parsedColumnSettings.columnWidths;
        if (columnWidths) {
          Object.entries(columnWidths).forEach(([field, width]) => {
            columnDimensions[field] = { width };
          });
        }

        // INFO: Apply filters
        gridApiRef.current.setFilterModel(parsedFilterSettings);

        // INFO: Apply sorting
        gridApiRef.current.setSortModel(parsedSortSettings);

        gridApiRef.current.restoreState({
          columns: {
            orderedFields: columnsOrder,
            dimensions: columnDimensions,
          },
        });
      }
    },
    [currentSetting]
  );

  const computeDataRows = useCallback(() => {
    if (!fetchedPages.has(page) || !dataRows.length) {
      fetchedPages.add(page);
      if (page === 0) {
        setDataRows(rows);
      } else {
        if (rows.length) {
          setDataRows((prevDataRows) => {
            const newRows = [...prevDataRows, ...rows];
            const uniqueRows = Array.from(new Set(newRows.map(getRowId))).map(
              (id) => newRows.find((row) => getRowId(row) === id)
            );
            return uniqueRows;
          });
        }
      }
    }
  }, [rows, page, getRowId, dataRows.length, fetchedPages]);

  useEffect(() => {
    if (rows?.length) {
      computeDataRows();
    }
  }, [rows]);

  useEffect(() => {
    fetchedPages.clear();
  }, [sortModel]);

  const handleRowsScrollEnd = useCallback(() => {
    if (dataRows.length < totalRecords && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [dataRows.length, totalRecords, loading, setPage]);

  const memoizedDataRows = useMemo(() => dataRows, [dataRows]);

  useEffect(() => {
    const handleScroll = (event: Event) => {
      if (loading) {
        event.preventDefault();
      }
    };

    const virtualScroller = containerRef.current.querySelector(
      ".MuiDataGrid-virtualScroller"
    );

    if (virtualScroller) {
      virtualScroller.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (virtualScroller) {
        virtualScroller.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading]);

  useEffect(() => {
    prevSortModelRef.current = sortModel?.[0];
  }, [rows]);

  useEffect(() => {
    if (gridApiRef.current && apiRef) {
      apiRef.current = gridApiRef.current;
    }
  }, [gridApiRef.current]);

  const handleCellClick = (params: GridCellParams, event: React.MouseEvent) => {
    if (
      params.field === MUI_GRID_COLUMNS.CHECKBOXES ||
      params.field === MUI_GRID_COLUMNS.ACTION ||
      params.field === MUI_GRID_COLUMNS.RADIO_BUTTON
    ) {
      // INFO: Prevent the row navigation when the checkbox is clicked
      event.stopPropagation();
    }
  };

  const renderRowLink = (rowParams: GridRowProps) => {
    return (
      <Link
        to={getRowLink({ ...rowParams, id: rowParams?.rowId as string })}
        style={{
          textDecoration: "none",
          color: "inherit",
        }}
      >
        <GridRow {...rowParams} />
      </Link>
    );
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        ...dataGridWrapper,
        ...sx,
      }}
    >
      {totalRecords ? (
        <>
          <DataGridPremium
            ref={dynamicHeightRef}
            disableRowSelectionOnClick
            rows={memoizedDataRows}
            columns={memoizedColumns}
            checkboxSelection={checkboxSelection}
            onRowDoubleClick={handleRowClick}
            onRowSelectionModelChange={handleCheckboxClick}
            apiRef={gridApiRef}
            loading={loading}
            getRowId={getRowId}
            onRowClick={(params, event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
            slots={{
              loadingOverlay: LinearProgress,
              ...(!!getRowLink && { row: renderRowLink }),
            }}
            rowSelectionModel={selectionModelIds}
            pagination={true}
            paginationMode="server"
            autoPageSize={false}
            rowCount={totalRecords}
            onRowsScrollEnd={handleRowsScrollEnd}
            slotProps={{
              pagination: {
                labelDisplayedRows: ({ count }) =>
                  `${totalRecordsLabel}: ${count}`,
                rowsPerPageOptions: [],
              },
            }}
            onCellClick={handleCellClick}
            pinnedColumns={pinnedColumns}
            rowHeight={rowHeight}
            getRowClassName={getRowClassName}
            sx={{
              ...(hideHeader && dataGridWrapper.hideHeader),
              ...(!checkboxSelection &&
                dataGridWrapper.cellStyleBaseOnColIndex().paddingLeft),
              maxHeight: dynamicHeight,
            }}
            sortingMode="server"
            sortingOrder={["desc", "asc"]}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            onFilterModelChange={handleFilterModelChange}
            {...prop}
          />
          {children}
        </>
      ) : (
        <>
          <DataGridPremium
            ref={dynamicHeightRef}
            disableRowSelectionOnClick
            rows={rows}
            columns={memoizedColumns}
            hideFooter
            checkboxSelection={checkboxSelection}
            onRowDoubleClick={handleRowClick}
            onRowSelectionModelChange={handleCheckboxClick}
            apiRef={apiRef}
            rowHeight={rowHeight}
            loading={loading}
            getRowId={getRowId}
            onRowClick={(params, event) => {
              event.stopPropagation();
              event.preventDefault();
            }}
            onCellClick={handleCellClick}
            slots={{
              loadingOverlay: LinearProgress,
              ...(!!getRowLink && { row: renderRowLink }),
            }}
            rowSelectionModel={selectionModelIds}
            sx={{
              ...(hideHeader && dataGridWrapper.hideHeader),
              ...(!checkboxSelection &&
                dataGridWrapper.cellStyleBaseOnColIndex().paddingLeft),
              maxHeight: dynamicHeight,
            }}
            {...prop}
          />
          {children}
        </>
      )}
      {footer}
    </Box>
  );
}
