import { Box, Card, Stack, SxProps, Typography } from "@mui/material";
import { gutterSpace, summaryCardStyle } from "appConstants/styles";
import ExpandAllButton from "containers/security/roles/ExpandAllButton";

interface SummaryCardProps {
  title: string | React.ReactNode;
  showExpand?: boolean;
  expandAll?: boolean;
  onExpandClick?: () => void;
  sx?: SxProps;
}

const SummaryCard: React.FC<SummaryCardProps> = (props) => {
  const { title, children, showExpand, expandAll, onExpandClick, sx } = props;

  return (
    <Card sx={summaryCardStyle}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={gutterSpace}
        sx={sx}
      >
        <Typography variant="h6" sx={summaryCardStyle.mainHeading}>
          {title}
        </Typography>
        {showExpand && (
          <ExpandAllButton
            expanded={expandAll}
            onClick={onExpandClick}
            btnSx={{ px: 0 }}
          />
        )}
      </Stack>
      <Box sx={summaryCardStyle.contentWrapper}>{children}</Box>
    </Card>
  );
};

export default SummaryCard;
