import { HEADER_TYPE } from "appConstants";
import { createContext, useState } from "react";

type AppHeaderContextType = {
  appModuleName: HEADER_TYPE;
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
  cornerContent: React.ReactNode;
  setAppModuleName: (value: HEADER_TYPE) => void;
  setLeftContent: (value: React.ReactNode) => void;
  setRightContent: (value: React.ReactNode) => void;
  setCornerContent: (value: React.ReactNode) => void;
};

export const AppHeaderContext = createContext<AppHeaderContextType | undefined>(
  undefined
);

const AppHeaderProvider = ({ children }) => {
  const [appModuleName, setAppModuleName] = useState<HEADER_TYPE>(null);
  const [leftContent, setLeftContent] = useState<React.ReactNode>(null);
  const [rightContent, setRightContent] = useState<React.ReactNode>(null);
  const [cornerContent, setCornerContent] = useState<React.ReactNode>(null);

  return (
    <AppHeaderContext.Provider
      value={{
        appModuleName,
        leftContent,
        rightContent,
        cornerContent,
        setAppModuleName,
        setLeftContent,
        setRightContent,
        setCornerContent,
      }}
    >
      {children}
    </AppHeaderContext.Provider>
  );
};

export default AppHeaderProvider;
