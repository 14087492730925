import { APP_ROUTES } from "appConstants/routes";
import { Redirect, Route, RouteProps } from "react-router-dom";
import AppShell from "layouts/AppShell";
import useValidatePermission from "utils/hooks/useValidatePermission";
import { PERMISSION } from "appConstants/security";
import { useValidationToken } from "utils/hooks";

type ProtectedRoutePropType = {
  key: string;
  path: string;
  exact: boolean;
  permission: PERMISSION;
  component: React.FC;
};

const ProtectedRoute = (props: ProtectedRoutePropType) => {
  const { component: Component, permission, ...rest } = props;

  const { tokenExist } = useValidationToken();

  const { checkPermission, getFirstAccessibleLink } = useValidatePermission();

  const renderAuthenticatedRoute = (routeProps: RouteProps) => {
    const firstAccessibleLink = getFirstAccessibleLink(
      routeProps?.location?.pathname
    );

    if (
      permission &&
      !checkPermission(permission) &&
      firstAccessibleLink === false
    ) {
      return <Redirect to={APP_ROUTES.Home} />;
    }

    if (firstAccessibleLink) {
      return <Redirect to={firstAccessibleLink} />;
    }

    return (
      <AppShell>
        <Component {...routeProps} />
      </AppShell>
    );
  };

  return (
    // INFO: Show the component only when the user is logged in
    // Otherwise, redirect the user to /login page
    <Route
      {...rest}
      render={(routeProps) =>
        tokenExist ? (
          renderAuthenticatedRoute(routeProps)
        ) : (
          <Redirect to={APP_ROUTES.Login} />
        )
      }
    />
  );
};

export default ProtectedRoute;
