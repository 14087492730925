import {
  AlertCard,
  Button,
  DialogBox,
  IconDropdown,
  ImageBox,
  Loader,
} from "components";
import { gutterSpace, linkButtonGrey50, pxToRem } from "appConstants/styles";
import { Grid, DialogContent, Typography, Stack, Box } from "@mui/material";
import { QrCodeScannerTwoTone, Camera, CancelSharp } from "@mui/icons-material";
import {
  DATE_TIME_FORMAT,
  ERROR_MESSAGES,
  inventorySuccessMessages,
} from "appConstants/index";
import useScanToUpdate from "./useScanToUpdate";
import qrCodeImage from "images/qrcode.png";
import dayjs from "dayjs";
import useUpdateDynamicHeight from "utils/hooks/useUpdateDynamicHeight";

const ScanToUpdate = () => {
  const {
    isOpen,
    isLoading,
    statusLoading,
    scanLabel,
    toggleModal,
    scanToUpdateBtn,
    showQRImage,
    startQRCode,
    stopQRCode,
    scannedText,
    asset,
    dispatchDetails,
    menuData,
    statusSuccessMessage,
    scanInProgress,
  } = useScanToUpdate();

  const { dynamicHeight, dynamicHeightRef } = useUpdateDynamicHeight(415);

  return (
    <>
      <Button
        startIcon={<QrCodeScannerTwoTone />}
        variant="text"
        sx={linkButtonGrey50}
        onClick={toggleModal}
      >
        {scanToUpdateBtn}
      </Button>
      {isOpen && (
        <DialogBox
          fullScreen
          open={isOpen}
          onClose={toggleModal}
          size={"xl"}
          sx={{
            padding: 0,

            ".MuiPaper-root": {
              padding: "0 !important",
            },
          }}
        >
          <DialogContent sx={{ padding: "0 !important" }}>
            <Stack justifyContent={"space-between"}>
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ height: dynamicHeight, minHeight: pxToRem(400) }}
                ref={dynamicHeightRef}
              >
                {showQRImage && (
                  <ImageBox
                    coverImg={qrCodeImage}
                    ariaLabel="QR Code"
                    width="100%"
                    maxWidth={pxToRem(400)}
                    height={pxToRem(290)}
                  />
                )}
                <Box
                  id="reader"
                  sx={{ width: "100%", maxWidth: pxToRem(400) }}
                ></Box>
              </Stack>
              {isLoading || statusLoading ? (
                <Loader />
              ) : (
                <Grid item pb={5} mt={3}>
                  {statusSuccessMessage === "" && asset && !scanInProgress && (
                    <Box mx={"auto"}>
                      <Stack pb={2}>
                        <Typography
                          variant="h5"
                          fontSize={pxToRem(22)}
                          fontWeight={"bold"}
                          lineHeight={pxToRem(28)}
                          mb={gutterSpace}
                        >
                          {asset?.assetName}
                        </Typography>
                      </Stack>
                      <Stack sx={{ fontWeight: 600 }}>
                        <Typography>Stock#: {asset?.stockNumber}</Typography>
                        <Typography>Year: {asset?.year}</Typography>
                        <Typography>
                          Make:
                          {
                            asset?.unitModel?.unitSeries?.unitBrand
                              ?.unitBrandName
                          }
                        </Typography>
                        <Typography pb={2} mb={gutterSpace}>
                          Model: {asset?.unitModel?.unitModelName}
                        </Typography>

                        <Typography>
                          FP Entity: {asset?.priceDetails?.floorPlanCompany}
                        </Typography>
                        <Typography>
                          Creation Date:
                          {dayjs(asset?.createdOn).format(
                            DATE_TIME_FORMAT.date.D_MMM_YYYY_HMA_FORMAT
                          )}
                        </Typography>
                        {dispatchDetails && (
                          <Typography pb={2}>
                            Dispatch Status:
                            {dispatchDetails?.dispatchStatusName}
                          </Typography>
                        )}
                      </Stack>
                      <IconDropdown menuData={menuData} />
                      {statusSuccessMessage === "" && asset && scannedText && (
                        <Typography pb={2}>
                          Choose Options to Continue
                        </Typography>
                      )}
                    </Box>
                  )}

                  {!scanInProgress && scannedText && (
                    <AlertCard
                      severity={
                        statusSuccessMessage !== ""
                          ? "success"
                          : asset
                          ? "success"
                          : "error"
                      }
                      alertMessage={
                        statusSuccessMessage !== ""
                          ? statusSuccessMessage
                          : asset
                          ? inventorySuccessMessages.scanSuccess
                          : ERROR_MESSAGES.scanFailed
                      }
                      openState={true}
                      isFloating={false}
                    />
                  )}

                  <Typography pb={5}>
                    <Button
                      aria-label="Scan QR Code"
                      fullWidth
                      sx={{
                        borderRadius: 0,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: pxToRem(30, 0),
                        boxShadow: "unset",
                      }}
                      onClick={startQRCode}
                      disabled={scanInProgress}
                    >
                      <Camera sx={{ fontSize: pxToRem(74) }} />

                      <Typography fontSize={pxToRem(22)}>
                        {scanLabel}
                      </Typography>
                    </Button>
                  </Typography>

                  <Box textAlign={"center"}>
                    <Typography fontWeight={600} letterSpacing={pxToRem(0.15)}>
                      {!scanInProgress &&
                        (showQRImage
                          ? `Tap Scan to Begin`
                          : `Tap Scan New to
                        Begin`)}
                      {scanInProgress && `Please Wait...`}
                    </Typography>
                  </Box>
                </Grid>
              )}
              <Button
                color="error"
                onClick={stopQRCode}
                sx={{
                  gap: gutterSpace,
                  padding: pxToRem(16),
                  margin: `0 auto ${pxToRem(60)}`,
                }}
              >
                <CancelSharp /> Close Camera
              </Button>
            </Stack>
          </DialogContent>
        </DialogBox>
      )}
    </>
  );
};
export default ScanToUpdate;
