import React from "react";
import {
  Button,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  SxProps,
} from "@mui/material";
import { filterButton, pxToRem } from "appConstants/styles";

type ButtonRadioGroupPropType = {
  labels: { label: string; icon: React.ReactNode }[];
  selectedValues: string[];
  handleChange: (value: string) => void;
  sx?: SxProps;
};

const ButtonRadioGroup: React.FC<ButtonRadioGroupPropType> = ({
  labels,
  selectedValues,
  sx,
  handleChange,
}) => {
  return (
    <RadioGroup
      row
      sx={{ px: pxToRem(12), "label:last-child": { mr: 0 }, ...sx }}
    >
      {labels.map((item, index) => (
        <FormControlLabel
          key={item.label}
          control={
            <Checkbox
              checked={selectedValues.includes(item.label)}
              onClick={() => handleChange(item.label)}
              sx={{ display: "none" }} // Hide the checkbox input
            />
          }
          label={
            <Button
              startIcon={item.icon}
              variant="text"
              onClick={() => handleChange(item.label)}
              sx={{
                ...filterButton,
                ...(selectedValues.includes(item.label)
                  ? filterButton["&:focus"]
                  : {}),
                ...(index < labels?.length - 1 && { mr: pxToRem(6) }),
              }}
            >
              {item.label}
            </Button>
          }
        />
      ))}
    </RadioGroup>
  );
};

export default ButtonRadioGroup;
