import { createSlice } from "@reduxjs/toolkit";
import { FinanceState } from "./finance.model";
import {
  createQuote,
  getDealTypes,
  getQuoteById,
  getQuotes,
  getFinanceStatuses,
  takeDeposit,
  editQuote,
  getPaymentTypes,
  voidQuote,
  getFeeTypes,
  getTaxTypes,
  convertToDeal,
  getQuoteHistoryById,
  printQuoteSheet,
  getCreditTypes,
} from "./finance.action";
import {
  createQuoteFulfilled,
  createQuoteRejected,
  getDealTypesFullfilled,
  getDealTypesRejected,
  getQuoteByIdFulfilled,
  getQuoteByIdRejected,
  getQuotesFulfilled,
  getQuotesRejected,
  getFinanceStatusesFullfilled,
  getFinanceStatusesRejected,
  takeDepositFulfilled,
  takeDepositRejected,
  editQuoteFulfilled,
  editQuoteRejected,
  getPaymentTypesFullfilled,
  getPaymentTypesRejected,
  voidQuoteFulfilled,
  voidQuoteRejected,
  getFeeTypesFulfilled,
  getFeeTypesRejected,
  getTaxTypesFulfilled,
  getTaxTypesRejected,
  convertToDealFulfilled,
  convertToDealRejected,
  getQuoteHistoryByIdFulfilled,
  getQuoteHistoryByIdRejected,
  printQuoteSheetFulfilled,
  printQuoteSheetRejected,
  getCreditTypesFulfilled,
  getCreditTypesRejected,
} from "./finance.reducer";
import { setReduxCommonPendingState } from "utils/common";

const initialState: FinanceState = {
  isLoading: false,
  isHistoryLoading: false,
  isPrintLoading: false,
  successMessage: "",
  depositSuccessMessage: "",
  convertToDealSuccessMessage: "",
  error: { title: "", description: "" },
  isSuccess: false,
  voidSuccess: false,
  quotes: [],
  totalRecords: 0,
  quote: null,
  quoteHistory: null,
  buyers: [],
  financeStatuses: [],
  dealTypes: [],
  paymentTypes: [],
  feeTypes: [],
  taxTypes: [],
  creditTypes: [],
};

export const financeSlice = createSlice({
  name: "finance",
  initialState: initialState,
  reducers: {
    resetSuccess: (state) => {
      state.isSuccess = false;
      state.successMessage = "";
      state.depositSuccessMessage = "";
      state.convertToDealSuccessMessage = "";
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    resetError: (state) => {
      state.error.title = "";
      state.error.description = "";
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => (
    builder.addCase(getQuotes.pending, (state) => {
      setReduxCommonPendingState(state);
      state.creditTypes = [];
    }),
    builder.addCase(getQuotes.fulfilled, (state, action) => {
      getQuotesFulfilled(state, action);
    }),
    builder.addCase(getQuotes.rejected, (state, action) => {
      getQuotesRejected(state, action);
    }),
    builder.addCase(getQuoteById.pending, (state) => {
      setReduxCommonPendingState(state);
      state.creditTypes = [];
    }),
    builder.addCase(getQuoteById.fulfilled, (state, action) => {
      getQuoteByIdFulfilled(state, action);
    }),
    builder.addCase(getQuoteById.rejected, (state, action) => {
      getQuoteByIdRejected(state, action);
    }),
    builder.addCase(createQuote.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(createQuote.fulfilled, (state, action) => {
      createQuoteFulfilled(state, action);
    }),
    builder.addCase(createQuote.rejected, (state, action) => {
      createQuoteRejected(state, action);
    }),
    builder.addCase(editQuote.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(editQuote.fulfilled, (state, action) => {
      editQuoteFulfilled(state, action);
    }),
    builder.addCase(editQuote.rejected, (state, action) => {
      editQuoteRejected(state, action);
    }),
    builder.addCase(voidQuote.pending, (state) => {
      setReduxCommonPendingState(state);
      state.isLoading = false;
    }),
    builder.addCase(voidQuote.fulfilled, (state, action) => {
      voidQuoteFulfilled(state, action);
    }),
    builder.addCase(voidQuote.rejected, (state, action) => {
      voidQuoteRejected(state, action);
    }),
    builder.addCase(getFinanceStatuses.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(getFinanceStatuses.fulfilled, (state, action) => {
      getFinanceStatusesFullfilled(state, action);
    }),
    builder.addCase(getFinanceStatuses.rejected, (state, action) => {
      getFinanceStatusesRejected(state, action);
    }),
    builder.addCase(getDealTypes.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(getDealTypes.fulfilled, (state, action) => {
      getDealTypesFullfilled(state, action);
    }),
    builder.addCase(getDealTypes.rejected, (state, action) => {
      getDealTypesRejected(state, action);
    }),
    builder.addCase(getPaymentTypes.pending, (state) => {
      setReduxCommonPendingState(state);
      state.isLoading = false;
    }),
    builder.addCase(getPaymentTypes.fulfilled, (state, action) => {
      getPaymentTypesFullfilled(state, action);
    }),
    builder.addCase(getPaymentTypes.rejected, (state, action) => {
      getPaymentTypesRejected(state, action);
    }),
    builder.addCase(takeDeposit.pending, (state) => {
      setReduxCommonPendingState(state);
      state.isLoading = false;
    }),
    builder.addCase(takeDeposit.fulfilled, (state, action) => {
      takeDepositFulfilled(state, action);
    }),
    builder.addCase(takeDeposit.rejected, (state, action) => {
      takeDepositRejected(state, action);
    }),
    builder.addCase(convertToDeal.pending, (state) => {
      setReduxCommonPendingState(state);
      state.isLoading = false;
    }),
    builder.addCase(convertToDeal.fulfilled, (state, action) => {
      convertToDealFulfilled(state, action);
    }),
    builder.addCase(convertToDeal.rejected, (state, action) => {
      convertToDealRejected(state, action);
    }),
    builder.addCase(getFeeTypes.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(getFeeTypes.fulfilled, (state, action) => {
      getFeeTypesFulfilled(state, action);
    }),
    builder.addCase(getFeeTypes.rejected, (state, action) => {
      getFeeTypesRejected(state, action);
    }),
    builder.addCase(getTaxTypes.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(getTaxTypes.fulfilled, (state, action) => {
      getTaxTypesFulfilled(state, action);
    }),
    builder.addCase(getTaxTypes.rejected, (state, action) => {
      getTaxTypesRejected(state, action);
    }),
    builder.addCase(getCreditTypes.pending, (state) => {
      setReduxCommonPendingState(state);
    }),
    builder.addCase(getCreditTypes.fulfilled, (state, action) => {
      getCreditTypesFulfilled(state, action);
    }),
    builder.addCase(getCreditTypes.rejected, (state, action) => {
      getCreditTypesRejected(state, action);
    }),
    builder.addCase(getQuoteHistoryById.pending, (state) => {
      state.isHistoryLoading = true;
    }),
    builder.addCase(getQuoteHistoryById.fulfilled, (state, action) => {
      getQuoteHistoryByIdFulfilled(state, action);
    }),
    builder.addCase(getQuoteHistoryById.rejected, (state, action) => {
      getQuoteHistoryByIdRejected(state, action);
    }),
    builder.addCase(printQuoteSheet.pending, (state) => {
      state.isPrintLoading = true;
    }),
    builder.addCase(printQuoteSheet.fulfilled, (state, action) => {
      printQuoteSheetFulfilled(state, action);
    }),
    builder.addCase(printQuoteSheet.rejected, (state, action) => {
      printQuoteSheetRejected(state, action);
    })
  ),
});

export const { resetSuccess, setError, resetError, reset } =
  financeSlice.actions;
