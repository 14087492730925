import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GET_DEAL_TYPES_URL,
  GET_PAYMENT_TYPES_URL,
  QUOTE_URL,
  DEAL_URL,
  GET_QUOTES_URL,
  GET_DEALS_URL,
  GET_QUOTE_STATUSES_URL,
  VOID_QUOTE_URL,
  VOID_DEAL_URL,
  TAKE_DEPOSIT_URL,
  GET_FEES_URL,
  GET_TAXES_URL,
  CONVERT_TO_DEAL_URL,
  GET_QUOTE_HISTORY_URL,
  GET_QUOTE_SHEET_URL,
  GET_CREDIT_TYPES_URL,
} from "utils/urls";
import { getRequest, postRequest, putRequest } from "utils/axiosClient";
import {
  API_RESPONSE_TYPE,
  ERROR_MESSAGES,
  FINANCE_MODE,
  JSON_HEADER,
  quoteSuccessMessages,
  STATUS_CODE,
} from "appConstants";
import AppError from "utils/AppError";
import {
  ConvertToDeal,
  GetCreditTypesPayload,
  GetFeesPayload,
  GetQuoteByIDPayload,
  GetQuoteHistoryPayload,
  GetQuotesPayload,
  GetTaxesPayload,
  TakeDeposit,
  VoidQuote,
} from "types/finance";

const typePrefix = {
  GET_ALL: "getAllQuotes",
  GET: "getQuote",
  VOID: "voidQuote",
  CREATE: "createQuote",
  EDIT: "editQuote",
};

export const getQuotes = createAsyncThunk(
  typePrefix.GET_ALL,
  async (payload: GetQuotesPayload) => {
    try {
      const { mode, ...requestPayload } = payload;
      const url = mode === FINANCE_MODE.QUOTE ? GET_QUOTES_URL : GET_DEALS_URL;

      const response = await getRequest<any>(url, {
        params: requestPayload,
      });

      return {
        records: response.data?.records,
        totalRecords: response.data?.totalRecords,
      };
    } catch (error) {
      throw new AppError(
        payload.mode === FINANCE_MODE.QUOTE
          ? ERROR_MESSAGES.getQuotes
          : ERROR_MESSAGES.getDeals,
        error.response?.data?.title
      );
    }
  }
);

export const getQuoteById = createAsyncThunk(
  typePrefix.GET,
  async (payload: GetQuoteByIDPayload) => {
    try {
      const { id, mode } = payload;
      const url = mode === FINANCE_MODE.QUOTE ? QUOTE_URL : DEAL_URL;
      const response = await getRequest<any>(url + "/" + id, {
        metadata: { expectedType: API_RESPONSE_TYPE.OBJECT },
      });
      return response.data;
    } catch (error) {
      throw new AppError(
        payload.mode === FINANCE_MODE.QUOTE
          ? ERROR_MESSAGES.getQuote
          : ERROR_MESSAGES.getDeal,
        error.response?.data?.title
      );
    }
  }
);

export const createQuote = createAsyncThunk(
  typePrefix.CREATE,
  async (payload: any) => {
    try {
      const response = await postRequest<any>(QUOTE_URL, payload, JSON_HEADER);

      if (response.status === STATUS_CODE.CREATED_SUCCESS) {
        return response?.data;
      }
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.createQuote,
        error.response?.data?.title
      );
    }
  }
);

export const editQuote = createAsyncThunk(
  typePrefix.EDIT,
  async (payload: any) => {
    try {
      const { mode, ...requestPayload } = payload;
      const url = mode === FINANCE_MODE.QUOTE ? QUOTE_URL : DEAL_URL;
      const response = await putRequest<any>(url, requestPayload, JSON_HEADER);

      if (response.status === STATUS_CODE.UPDATE_SUCCESS) {
        return mode === FINANCE_MODE.QUOTE
          ? quoteSuccessMessages.quoteUpdated
          : quoteSuccessMessages.dealUpdated;
      }
    } catch (error) {
      throw new AppError(
        payload.mode === FINANCE_MODE.QUOTE
          ? ERROR_MESSAGES.editQuote
          : ERROR_MESSAGES.editDeal,
        error.response?.data?.title
      );
    }
  }
);

export const voidQuote = createAsyncThunk<any, VoidQuote>(
  typePrefix.VOID,
  async (payload: VoidQuote) => {
    try {
      const { mode, ...requestPayload } = payload;
      const url = mode === FINANCE_MODE.QUOTE ? VOID_QUOTE_URL : VOID_DEAL_URL;
      const response = await putRequest<any>(url, requestPayload, JSON_HEADER);

      if (response.status === STATUS_CODE.UPDATE_SUCCESS) {
        return {
          status: STATUS_CODE.UPDATE_SUCCESS,
          message:
            mode === FINANCE_MODE.QUOTE
              ? quoteSuccessMessages.quoteVoided
              : quoteSuccessMessages.dealVoided,
        };
      }
    } catch (error) {
      throw new AppError(
        payload.mode === FINANCE_MODE.QUOTE
          ? ERROR_MESSAGES.voidQuote
          : ERROR_MESSAGES.voidDeal,
        error.response?.data?.title
      );
    }
  }
);

export const getFinanceStatuses = createAsyncThunk(
  GET_QUOTE_STATUSES_URL,
  async () => {
    try {
      const response = await getRequest<any>(GET_QUOTE_STATUSES_URL);
      const statuses = response.data?.filter((status) => status.isActive);
      return statuses;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getFinanceStatuses,
        error.response?.data?.title
      );
    }
  }
);

export const getDealTypes = createAsyncThunk(GET_DEAL_TYPES_URL, async () => {
  try {
    const response = await getRequest<any>(GET_DEAL_TYPES_URL);
    const dealTypes = response.data?.filter((dealType) => dealType.isActive);
    return dealTypes;
  } catch (error) {
    throw new AppError(
      ERROR_MESSAGES.getDealTypes,
      error.response?.data?.title
    );
  }
});

export const getPaymentTypes = createAsyncThunk(
  GET_PAYMENT_TYPES_URL,
  async () => {
    try {
      const response = await getRequest<any>(GET_PAYMENT_TYPES_URL);
      const paymentTypes = response.data?.filter(
        (paymentType) => paymentType.isActive
      );
      return paymentTypes;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getPaymentTypes,
        error.response?.data?.title
      );
    }
  }
);

export const takeDeposit = createAsyncThunk<any, TakeDeposit>(
  TAKE_DEPOSIT_URL,
  async (payload: TakeDeposit) => {
    try {
      const response = await putRequest<any>(
        TAKE_DEPOSIT_URL,
        payload,
        JSON_HEADER
      );

      if (response.status === STATUS_CODE.UPDATE_SUCCESS) return response;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.takeDeposit,
        error.response?.data?.message
      );
    }
  }
);

export const convertToDeal = createAsyncThunk<any, ConvertToDeal>(
  CONVERT_TO_DEAL_URL,
  async (payload: ConvertToDeal) => {
    try {
      const response = await putRequest<any>(
        `${CONVERT_TO_DEAL_URL}`,
        {},
        { params: payload }
      );

      if (response.status === STATUS_CODE.UPDATE_SUCCESS) return response;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.convertToDeal,
        error.response?.data?.message
      );
    }
  }
);

export const getFeeTypes = createAsyncThunk(
  GET_FEES_URL,
  async (payload: GetFeesPayload) => {
    try {
      const response = await getRequest<any>(GET_FEES_URL, {
        params: payload,
      });

      return response.data;
    } catch (error) {
      throw new AppError(ERROR_MESSAGES.getFees, error.response?.data?.title);
    }
  }
);

export const getTaxTypes = createAsyncThunk(
  GET_TAXES_URL,
  async (payload: GetTaxesPayload) => {
    try {
      const response = await getRequest<any>(GET_TAXES_URL, {
        params: payload,
      });
      return response.data;
    } catch (error) {
      throw new AppError(ERROR_MESSAGES.getTaxes, error.response?.data?.title);
    }
  }
);

export const getCreditTypes = createAsyncThunk(
  GET_CREDIT_TYPES_URL,
  async (payload: GetCreditTypesPayload) => {
    try {
      const response = await getRequest<any>(GET_CREDIT_TYPES_URL, {
        params: payload,
      });
      return response.data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getCreditTypes,
        error.response?.data?.title
      );
    }
  }
);

export const getQuoteHistoryById = createAsyncThunk(
  GET_QUOTE_HISTORY_URL,
  async (payload: GetQuoteHistoryPayload) => {
    try {
      const response = await getRequest<any>(
        GET_QUOTE_HISTORY_URL + "/" + payload.id,
        {
          metadata: { expectedType: API_RESPONSE_TYPE.OBJECT },
        }
      );
      return response.data;
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.getAssetHistoryError,
        error.response?.data?.title
      );
    }
  }
);

export const printQuoteSheet = createAsyncThunk(
  GET_QUOTE_SHEET_URL,
  async (payload: any) => {
    try {
      const response = await postRequest<any>(
        GET_QUOTE_SHEET_URL,
        payload,
        JSON_HEADER
      );

      if (response.status === STATUS_CODE.SUCCESS) {
        return response?.data;
      }
    } catch (error) {
      throw new AppError(
        ERROR_MESSAGES.printQuoteSheet,
        error.response?.data?.title
      );
    }
  }
);
